import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { groupFieldsFragment } from '../../fragments/group'

const query = gql`
    query Group($params: GroupInput!) {
        group(params: $params) {
            ...groupFields
        }
    }
    ${groupFieldsFragment}
`

const useGetGroup = ({ params, ...options }) => {
  return useQuery(query, {
    variables: { params },
    ...options,
  })
}

export default useGetGroup
