import { CRM_NAME } from 'src/utils/constants/crm'
import { LeftOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import Tooltip from '../../../../../Tooltip/Tooltip'

const Wrapper = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const IdWrapper = styled.span`
  margin: 0 6px;
`

const Success = ({ id, onChange }) => {
  const handleChange = () => {
    onChange('crmId', id)
  }
  const successText = `${CRM_NAME} ID associated to the user email. Click to add to the current user`
  return (
    <>
      <Wrapper onClick={handleChange}>
        <LeftOutlined/>
        <IdWrapper>{id}</IdWrapper>
      </Wrapper>
      <Tooltip text={successText}/>
    </>
  )
}

export default Success
