import { DateRenderer, ActionsRenderer, PreviewRenderer } from './renderers'

export const columns = [
  {
    render: PreviewRenderer,
    key: 'streamingUrl',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    render: DateRenderer,
  },
  {
    title: 'Actions',
    align: 'right',
    render: ActionsRenderer,
  },
]
