import React from 'react'
import { isEmpty, orderBy } from 'lodash'
import { List, Card, Table } from 'antd';

const columns = [
  {
    title: 'Year',
    dataIndex: 'year'
  },
  {
    title: 'Month',
    dataIndex: 'month'
  },
  {
    title: 'Performance',
    dataIndex: 'value'
  },
];

const AdditionalPerformanceInput = ({fund}) => {
  const { additionalPerformance, performance } = fund
  if(isEmpty(additionalPerformance)) return null

  const additionalPerformanceData = Object.keys(additionalPerformance)
    .map(key => ({title: key, value: additionalPerformance[key]}))

  const tableData = orderBy(performance, ['year', 'month'], ['desc', 'desc'])
  return (
    <>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={additionalPerformanceData}
        renderItem={({title, value}) => (
          <List.Item>
            <Card title={title}>{value}</Card>
          </List.Item>
        )}
      />
      <Card>
        <Table
          rowKey={({year, month}) => `${year}-${month}`}
          pagination={{pageSize: 5}}
          dataSource={tableData}
          columns={columns} />
      </Card>
    </>
  )
}

export default AdditionalPerformanceInput
