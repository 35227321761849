import gql from 'graphql-tag'
import useQuery from '../useQuery'

export const userStatsQuery = gql`
    query UserStats($params: UserDailyStatsInput!) {
        userDailyStats(params: $params) {
            date
            logins
            signups
        }
        totalUserCount
        activeUsersCount
        activeTodayUsersCount
    }
`

const useGetUserStats = ({ params, ...options } = {}) => {
  return useQuery(userStatsQuery, {
    variables: { params },
    ...options,
  })
}

export default useGetUserStats
