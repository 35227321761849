import React, { useState, useEffect } from 'react'
import 'react-image-crop/lib/ReactCrop.scss'
import { Upload } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useFileUpload from 'src/apollo/mutation/useFileUpload'
import { getBase64 } from '../../../utils/helpers/upload'
import EmptyUpload from '../Dashboard/Insights/InsightsEdit/EmptyUpload'
import { getImageSource } from '../../../utils/helpers/image'
import LoadingOverlay from '../ui/LoadingOverlay'
import ImageCrop from './ImageCrop'

const StyledImage = styled.img`
  ${({ avatar }) => avatar && `
    border-radius: 50%
    width: 55px;
    height: 55px;
  `}
`

const ImageUpload = ({ value, onChange, crop, aspect, avatar }) => {
  const [src, setSrc] = useState(null)
  const [file, setFile] = useState(null)
  const [upload, { loading }] = useFileUpload({
    file,
    onCompleted: ({ uploadFile }) => onChange(uploadFile),
  })

  const handleSetSrc = async fileObj => {
    const file = await getBase64(fileObj)
    const imageType = file.substring('data:image/'.length,file.indexOf(';base64'))

    // cropping not supported for gifs
    if (crop && imageType !== 'gif') {
      setSrc(file)
    } else {
      setFile(file)
    }
  }

  useEffect(() => {
    if (file) {
      upload({ variables: { file } })
      setSrc(null)
      setFile(null)
    }
  }, [file])

  return (
    <LoadingOverlay loading={loading} transparent={false}>
      <ImageCrop src={src} onChange={setFile} aspect={aspect} onCancel={() => setSrc(null)}/>
      <Upload.Dragger
        disabled={loading}
        listType='picture'
        beforeUpload={handleSetSrc}
        className="avatar-uploader"
        showUploadList={false}>
        {value
          ? <StyledImage avatar={avatar} width={200} height='auto' src={getImageSource(value)} alt="avatar"/>
          : <EmptyUpload/>}
      </Upload.Dragger>
    </LoadingOverlay>
  )
}

ImageUpload.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  crop: PropTypes.bool,
  aspect: PropTypes.number,
  avatar: PropTypes.bool,
}

ImageUpload.defaultProps = {
  onChange: () => null,
  crop: false,
  aspect: 1,
  avatar: false,
}

export default ImageUpload
