import React from 'react'
import ResponsiveTagList from '../../ResponsiveTagList'

const FundListContent = ({data, loading}) => {
  if(loading) {
    return null
  }

  if(!data?.length) {
    return 'This user does not have access to any funds'
  }

  return <ResponsiveTagList data={data} keySelector="uuid" textSelector="name" />
}

export default FundListContent