import { CRM_NAME } from 'src/utils/constants/crm'
import Text from 'antd/lib/typography/Text'
import React from 'react'

const NotFound = () => {
  const notFoundText = `ID not found in ${CRM_NAME}`
  return <Text>{notFoundText}</Text>
}

export default NotFound
