import React from 'react'
import { omit, get } from 'lodash'
import { Input, Select, Form, Card, Col } from 'antd'
import PersonQuery from '../../../../../apollo/query/people/Person'
import FormItem from '../../../ui/FormItem'
import PageHeader from '../../../ui/PageHeader'
import ImageUpload from '../../../ImageUpload/ImageUpload'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import ChangesNotification from '../../ChangesNotification/ChangesNotification'
import { publishPeopleMutation } from '../../../../../apollo/mutation/people/PublishPeople'
import { PERSON_STATUS_ARCHIVED, PERSON_STATUS_PUBLISHED } from '../../../../../constants/constants'
import DepartmentSelect from './DepartmentSelect'
import Actions from './Actions'

const PersonEdit = ({ uuid }) => {
  const [fields, setFields] = React.useState({ uuid, isPublic: false })
  const [errors, setErrors] = React.useState({})
  const { firstName, lastName, title, shortBio, bio, isPublic, department, image, authorImage, watermark, isTeamMember } = fields

  const setField = React.useCallback((name, value) => {
    setFields({ ...fields, [name]: value })
    setErrors({ ...errors, [name]: false })
  }, [fields, errors])

  const handleInputChange = React.useCallback(({ target: { name, value } }) => {
    setField(name, value)
  })

  const handleImageUpload = React.useCallback((image) => {
    setField('image', image)
  })

  const handleAuthorImageUpload = React.useCallback((image) => {
    setField('authorImage', image)
  })

  const populateFieldsFromObject = ({ person } = {}) => {
    if (person) {
      setFields(omit(person, ['__typename', 'original', 'isVersion']))
    }
  }

  const handleVisibilityChange = React.useCallback(value => {
    setField('isPublic', value === 1)
  })

  const handleTypeChange = React.useCallback(value => {
    setFields({ ...fields, isTeamMember: value === 1, isPublic: false })
    setErrors({ ...errors, bio: false, department: false })
  })

  const isCreate = uuid === 'create'

  return (
    <Col xl={24} xxl={18}>
      <PersonQuery
        version
        uuid={uuid}
        skip={isCreate}
        status={`${PERSON_STATUS_PUBLISHED},${PERSON_STATUS_ARCHIVED}`}
        notifyOnNetworkStatusChange
        onCompleted={populateFieldsFromObject}>
        {({ data, loading }) => {
          const person = get(data, 'person', {})
          return (
            <LoadingOverlay loading={loading}>
              <PageHeader
                to='/portal/admin/team'
                title={isCreate ? 'Create Person' : 'Edit Person'}/>
              <Actions onError={setErrors} fields={fields}/>
              <ChangesNotification
                mutation={publishPeopleMutation}
                item={person}
                navigateTo='/portal/admin/team'/>
              <Card>
                <Form labelCol={{ span: 3 }} wrapperCol={{ span: 17 }}>

                  <FormItem label='First Name' error={errors.firstName}>
                    <Input name="firstName" disabled={loading} value={firstName} onChange={handleInputChange}/>
                  </FormItem>

                  <FormItem label='Last Name' error={errors.lastName}>
                    <Input name="lastName" disabled={loading} value={lastName} onChange={handleInputChange}/>
                  </FormItem>

                  <FormItem label='Title' error={errors.title}>
                    <Input name="title" disabled={loading} value={title} onChange={handleInputChange}/>
                  </FormItem>

                  <FormItem label='Watermark' error={errors.watermark}>
                    <Input name="watermark" disabled={loading} value={watermark} onChange={handleInputChange}/>
                  </FormItem>

                  <FormItem label='Image' error={errors.image}>
                    <ImageUpload value={image} onChange={handleImageUpload}/>
                  </FormItem>

                  <FormItem label='Author Image' error={errors.authorImage}>
                    <ImageUpload value={authorImage} onChange={handleAuthorImageUpload} crop avatar/>
                  </FormItem>

                  <FormItem label='Type'>
                    <Select value={isTeamMember ? 1 : 0} onChange={handleTypeChange}>
                      <Select.Option key={true} value={1}> Team member and author </Select.Option>
                      <Select.Option key={false} value={0}> Author </Select.Option>
                    </Select>
                  </FormItem>

                  {!!isTeamMember && (
                    <FormItem label='Visibility'>
                      <Select value={isPublic ? 1 : 0} onChange={handleVisibilityChange}>
                        <Select.Option key={true} value={1}> Visible on public website </Select.Option>
                        <Select.Option key={false} value={0}> Not visible on public website </Select.Option>
                      </Select>
                    </FormItem>
                  )}

                  <FormItem label='Department' error={errors.department}>
                    <DepartmentSelect value={department} onChange={value => setField('department', value)}/>
                  </FormItem>

                  <FormItem label='Short Bio' error={errors.shortBio}>
                    <Input.TextArea rows={4} name="shortBio" disabled={loading} value={shortBio}
                                    onChange={handleInputChange}/>
                  </FormItem>

                  <FormItem label='Bio' error={errors.bio}>
                    <Input.TextArea rows={4} name="bio" disabled={loading} value={bio} onChange={handleInputChange}/>
                  </FormItem>

                </Form>
                <Actions onError={setErrors} fields={fields}/>
              </Card>
            </LoadingOverlay>
          )
        }}
      </PersonQuery>
    </Col>
  )
}

export default PersonEdit
