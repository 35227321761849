import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { userFieldsFragment } from '../../fragments/user'

const query = gql`
    query User($params: UserInput!) {
        user(params: $params) {
            ...userFields
            funds {
                uuid
                name
            }
        }
    }
    ${userFieldsFragment}
`

const useGetUser = ({ params, ...options }) => {
  return useQuery(query, {
    variables: { params },
    ...options,
  })
}

export default useGetUser
