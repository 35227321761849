import * as React from 'react'
import useGetFunds from 'src/apollo/query/funds/useGetFunds'
import Row from '../../Row/Row'
import FundRow from '../Funds/FundRow/FundRow'
import Loader from '../Loader/Loader'
import Error from '../Error/Error'
import LoadingOverlay from '../../Admin/ui/LoadingOverlay'
import useGtag, { EVENT_ACTIONS, EVENT_CATEGORIES, EVENT_LABELS } from '../../../hooks/useGtag'
import { dataRow, data as dataStyle } from './Dashboard.module.scss'

const fundNameAscendingComparator = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1
  }
  return 0
}

export default () => {
  const { sendEvent } = useGtag()
  const { data, loading, error } = useGetFunds({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      sendEvent({
        category: EVENT_CATEGORIES.funds,
        action: EVENT_ACTIONS.viewFundList,
        label: `${EVENT_LABELS.viewFundList}`,
      })
    },
  })
  const funds = data?.funds?.results || []

  if (error) {
    return <Error />
  }

  if (loading && !funds.length) {
    return <Loader />
  }

  if (!funds.length) {
    return (
      <Row inlineRow className={dataRow}>
        <div className={dataStyle}>There are no funds attached to your account, please contact us</div>
      </Row>
    )
  }

  const sortedFunds = [...funds].sort(fundNameAscendingComparator)

  return (
    <LoadingOverlay loading={loading} spinnerType="portal">
      {sortedFunds.map(fund => (
        <FundRow key={fund.name} totalCount={funds?.length || 0} {...fund} />
      ))}
      <Row inlineRow className={dataRow}>
        <div className={dataStyle}>The most recent month’s data may be estimated by Emso.</div>
      </Row>
    </LoadingOverlay>
  )
}
