import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import Query from '../Query'
import { userFieldsFragment } from '../../fragments/user'

export const query = gql`
    query Users($params: UsersInput!) {
        users(params: $params) {
            users {
                ...userFields
            }
            length
            limit
            start
            total
        }
    }
    ${userFieldsFragment}
`

const UsersListQuery = ({ children, filter, page, perPage, order, disabled,...props }) => {
  const params = {
    filter,
    page,
    perPage,
    order,
    disabled: disabled || false
  }
  return (
    <Query query={query} variables={{ params }} {...props}>
      {data => {
        return children(data)
      }}
    </Query>
  )
}

UsersListQuery.propTypes = {
  children: PropTypes.func.isRequired,
  filter: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
}

UsersListQuery.defaultProps = {
  filter: '',
  page: 0,
  perPage: 10,
}

export default UsersListQuery
