import React, { useCallback, useState, useEffect } from 'react'
import { Card, Input } from 'antd'
import styled from 'styled-components'
import useGetVideos from '../../../../apollo/query/media/useGetVideos'
import { columns } from './columns'
import Table from '../../Table/Table'

const Search = styled(Input.Search)`
  margin-right: 20px;

  .ant-input-affix-wrapper {
    height: 36px;
  }

  .ant-input-search-button {
    bottom: 1px;
  }
`
const PAGE_SIZE = 10
const START_PAGE = 1
const VideosList = () => {
  const [page, setPage] = useState(START_PAGE)
  const [limit, setLimit] = useState(PAGE_SIZE)
  const [search, setSearch] = useState('')
  const { data, loading } = useGetVideos({
    variables: {
      params: {
        page,
        limit,
        order: [{ order: 'createdAt', orderDirection: 'DESC' }],
        filter: {
          search,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setPage(START_PAGE)
  }, [limit, search])
  const handleSearch = search => {
    setPage(0)
    setSearch(search)
  }
  const handleChange = useCallback(page => {
    setPage(page)
  })

  const videos = data?.videos?.results || []
  const total = data?.videos?.total

  return (
    <Card extra={<Search onSearch={handleSearch} placeholder="Search..." allowClear />}>
      <Table
        loading={loading}
        rowKey="uuid"
        scroll={{ x: 400 }}
        pagination={{
          current: page,
          total,
          pageSize: limit,
          onChange: handleChange,
          onPageSizeChange: pageSize => {
            setLimit(pageSize)
          },
        }}
        showHeader={false}
        columns={columns}
        dataSource={videos}
      />
    </Card>
  )
}

export default VideosList
