import * as React from 'react'
import ChartistGraph from 'react-chartist'
import { transformGraphData, getChartOptions, createdChart } from './chartHelpers.js'
import { graph, yAxis, xAxis as xAxisStyle} from './Chart.module.scss'
import './Chart.scss'
import { calculateResponsiveTicks } from './chartHelpers'

export default React.memo(({ performance, xAxis = '' }) => {
  const graphData = transformGraphData(performance)
  const chartOptions = getChartOptions(graphData.lowX, graphData.lowY, graphData.ticksX, graphData.ticksY)
  const chartData = {
    series: [graphData.data],
  }
  const length = graphData?.ticksX?.length || 0
  const responsiveTicks = Array(length).fill('')
  responsiveTicks[0] = graphData.ticksX[0]
  responsiveTicks[length - 1] = graphData.ticksX[length - 1]

  const responsiveOptions = [
    [
      'screen and (max-width: 1024px)',
      {
        axisX: {
          ticks: calculateResponsiveTicks(graphData.ticksX),
        },
      },
    ],
  ]

  return (
    <div className={`${graph} graph-container`}>
      <ChartistGraph
        listener={{
          created: createdChart,
        }}
        data={chartData}
        options={chartOptions}
        responsiveOptions={responsiveOptions}
        type={'Line'}
      />
      <div className={yAxis}>NAV</div>
      <div className={xAxisStyle}>{xAxis}</div>
    </div>
  )
})
