import * as React from 'react'
import dayjs from 'dayjs'
import { Alert, Row, Col } from 'antd'
import { get as getValue } from 'lodash'
import { trimPath } from 'src/utils/helpers/trimPath'
import useGetDocuments from 'src/apollo/query/documents/useGetDocuments'
import DocumentTable from '../../../Login/Documents/DocumentTable'
import Empty from '../Empty'
import LoadingOverlay from '../../ui/LoadingOverlay'
import { getGroupIdsAsArray } from '../Groups/helpers'
import PreviewFile from '../../../Login/Documents/PreviewFile'
import LogViewDrawer from '../../LogViewer/LogViewDrawer'
import { DOCUMENTS_SUBJECT_TYPE } from '../../LogViewer/constants'
import PageHeader from '../../ui/PageHeader'
import ActionButtons from '../../ActionButtons/ActionButtons'
import Permissions from './Permissions'
import Breadcrumbs from './Breadcrumbs'
import useFilesVisibility from './useFilesVisibility'
import VisibilityConfirm from './VisibilityConfirm'

export default () => {
  const [fileName, setFileName] = React.useState('')
  const [path, setPath] = React.useState('')
  const [permissionsOpen, setPermissionsOpen] = React.useState(false)

  const { data, loading, error, refetch: refetchDocuments } = useGetDocuments({
    params: { path, overridePermissions: true },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const documents = data?.documents

  const handleChange = () => {
    refetchDocuments()
  }

  async function openFolder(path, directory) {
    setPath(`${path}/${directory}`)
  }

  const noRecords = !loading && !error && (!documents || (!documents.directories.length && !documents.files.length))

  const existingGroupIds = getGroupIdsAsArray(getValue(documents, 'meta'))
  const allowDownloadFile = !(documents?.meta?.readOnly || documents?.meta?.pathReadOnly)

  const {
    save: saveFilesVisibility,
    loading: filesUpdateLoading,
    filesToUpdate,
    isFileVisible,
    handleFileVisibilityChange,
    allFilesChecked,
    toggleAllFiles,
    setConfirmOpen,
    confirmOpen,
  } = useFilesVisibility({ path, files: documents?.files?.map(item => ({...item, path})), onUpdateCompleted: refetchDocuments })

  const actions = [
    {
      onClick: () => setPermissionsOpen(true),
      loading: loading,
      children: 'Permissions',
    },
    {
      onClick: () => setConfirmOpen(true),
      children: `Update visibility`,
      disabled: !filesToUpdate.length,
    },
    {
      children: <LogViewDrawer subjectId={trimPath(path)} subjectType={DOCUMENTS_SUBJECT_TYPE}><span>Activity Log</span></LogViewDrawer>,
    },
  ]

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Documents'/>
      <ActionButtons actions={actions}/>
      <Permissions
        open={permissionsOpen}
        toggle={setPermissionsOpen}
        path={path}
        readOnly={documents?.meta?.readOnly}
        pathReadOnly={documents?.meta?.pathReadOnly}
        existingGroupIds={existingGroupIds}
        onChange={handleChange}
        loading={loading}/>
      <VisibilityConfirm
        toggle={setConfirmOpen}
        open={confirmOpen}
        onSave={saveFilesVisibility}
        loading={filesUpdateLoading}
        changes={filesToUpdate}/>
      <Row>
        <Col sm={24}>
          <Breadcrumbs path={path} setPath={setPath}/>

          <PreviewFile
            path={path}
            fileName={fileName}
            open={!!fileName}
            closePopup={() => setFileName('')}
            allowDownload={allowDownloadFile}/>
          {error && (
            <Alert
              message="Something went wrong. Please refresh the page and try again"
              type="error"
            />
          )}
          <LoadingOverlay loading={loading}>
            {documents ? (
              <>
                <DocumentTable
                  showVisibility={!!documents.files.length}
                  onToggleAll={toggleAllFiles}
                  allFilesChecked={allFilesChecked}
                  data={[
                    ...documents.directories.map(directory => ({
                      name: directory.name,
                      type: 'folder',
                      date: dayjs(new Date(directory.meta.lastModified)).format('YYYY-MM-DD'),
                      onClick: () => openFolder(path, directory.name),
                    })),
                    ...documents.files.map(file => ({
                      name: file.name,
                      date: dayjs(new Date(file.meta.lastModified)).format('YYYY-MM-DD'),
                      type: 'file',
                      marked: !file.meta.visible,
                      download: !documents?.meta?.readOnly || !documents?.meta?.pathReadOnly,
                      path,
                      visible: isFileVisible(file.name),
                      onClick: () => {
                        setFileName(file.name)
                      },
                      onEdit: handleFileVisibilityChange,
                    })),
                  ]}
                />
                {noRecords && <Empty/>}
              </>
            ) : <span/>}
          </LoadingOverlay>
        </Col>
      </Row>
    </Col>
  )
}
