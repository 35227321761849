import React from 'react'
import { get } from 'lodash'
import PeopleQuery from 'src/apollo/query/people/PeopleList'
import { PlusOutlined } from '@ant-design/icons'
import { Card, Button, notification, Radio, Col } from 'antd'
import { navigate } from 'gatsby-link'
import PublishPeople from 'src/apollo/mutation/people/PublishPeople'
import PageHeader from '../../../ui/PageHeader'
import { getAllWithUnpublishedChanges } from '../../../../../utils/helpers/insight'
import ChangesSummary from '../../ChangesSummary/ChangesSummary'
import PeopleActions from '../../ChangesSummary/renderers/PeopleActions'
import {
  INSIGHT_STATUS_ARCHIVED,
  PERSON_STATUS_ARCHIVED,
  PERSON_STATUS_PUBLISHED
} from '../../../../../constants/constants'
import PeopleTitle from '../../ChangesSummary/renderers/PeopleTitle'
import ActionButtons from '../../../ActionButtons/ActionButtons'
import OrderedPeopleList from './OrderedPeopleList'

const PeopleList = () => {
  const [filterStatus, setFilterStatus] = React.useState(PERSON_STATUS_PUBLISHED)

  const handleFilterStatusChange = React.useCallback(({ target: { value } }) => {
    setFilterStatus(value)
  })
  const actions = [
    {
      children: 'Create Person',
      onClick: () => navigate(`/portal/admin/people/edit/create`),
      icon: <PlusOutlined />,
    },
  ]
  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Team' />
      <ActionButtons actions={actions} />
      <PeopleQuery
        status={`${PERSON_STATUS_PUBLISHED},${PERSON_STATUS_ARCHIVED}`}
        version
        fetchPolicy='cache-and-network'>
        {({ data, loading: listLoading }) => {
          const people = get(data, 'people.results', [])

          const toBeUpdated = getAllWithUnpublishedChanges(people)
          const peopleWithChanges = people.map(person => {
            return toBeUpdated.find(({ uuid }) => uuid === person.uuid) || person
          })

          const tableData = filterStatus === PERSON_STATUS_ARCHIVED
            ? peopleWithChanges.filter(insight => insight.status === INSIGHT_STATUS_ARCHIVED)
            : peopleWithChanges.filter(insight => insight.status !== INSIGHT_STATUS_ARCHIVED)

          return (
            <>
              <PublishPeople
                uuids={toBeUpdated.map(({ uuid }) => uuid)}
                onCompleted={() => {
                  notification.success({ message: 'Changes published successfully' })
                }} refetchQueries={['PeopleList']} awaitRefetchQueries>
                {(publish, { loading: publishLoading }) => {
                  const loading = listLoading || publishLoading
                  return (
                    <ChangesSummary
                      loading={loading}
                      data={toBeUpdated}
                      onPublish={publish}
                      actionsRenderer={PeopleActions}
                      titleRenderer={PeopleTitle}
                      previewLink="/team"/>
                  )
                }}
              </PublishPeople>
              <Card title="All People" extra={
                <Radio.Group value={filterStatus} onChange={handleFilterStatusChange} size="small">
                  <Radio.Button id="PeopleList-Radio-filter-published"
                    value={PERSON_STATUS_PUBLISHED}>Active</Radio.Button>
                  <Radio.Button id="PeopleList-Radio-filter-archived"
                    value={PERSON_STATUS_ARCHIVED}>Archived</Radio.Button>
                </Radio.Group>
              }>
                <OrderedPeopleList people={tableData} loading={listLoading} filter={filterStatus}/>
              </Card>
            </>
          )
        }}
      </PeopleQuery>
    </Col>
  )
}

export default PeopleList
