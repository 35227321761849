// extracted by mini-css-extract-plugin
export var bottomRow = "emso-PreviewFile-module__bottomRow";
export var button = "emso-PreviewFile-module__button";
export var cancel = "emso-PreviewFile-module__cancel";
export var close = "emso-PreviewFile-module__close";
export var col = "emso-PreviewFile-module__col";
export var content = "emso-PreviewFile-module__content";
export var contentLarge = "emso-PreviewFile-module__contentLarge";
export var error = "emso-PreviewFile-module__error";
export var fileModal = "emso-PreviewFile-module__fileModal";
export var header = "emso-PreviewFile-module__header";
export var hr = "emso-PreviewFile-module__hr";
export var modal = "emso-PreviewFile-module__modal";
export var modalLarge = "emso-PreviewFile-module__modalLarge";
export var overlay = "emso-PreviewFile-module__overlay";
export var page = "emso-PreviewFile-module__page";
export var pdf = "emso-PreviewFile-module__pdf";
export var pdfDocument = "emso-PreviewFile-module__pdfDocument";