import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { navigate } from 'gatsby'
import auther from '../../helpers/auth'
import AdminLayout from '../../layouts/AdminLayout'

const AdminRoute = ({ component: Component, location, ...rest }) => {
  const auth = auther()
  if (!auth.isAdmin()) {
    if (typeof window !== 'undefined') {
      navigate('/portal')
    }
    return null
  }
  return (
    <AdminLayout>
      <Component {...rest} />
    </AdminLayout>
  )
}

export default AdminRoute
