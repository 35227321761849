import React, { useState, useEffect } from 'react'
import { Tree, Collapse, Button } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useGetDocuments from 'src/apollo/query/documents/useGetDocuments'
import { getDirectoryTree, sortBySelectedDirectories } from '../helpers'

const { Panel } = Collapse

const { DirectoryTree } = Tree

const StyledPanel = styled(Panel)`
  background: white;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 18px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDirectoryTree = styled(DirectoryTree)`
  .ant-tree-checkbox {
    vertical-align: top;
  }
`

const FoldersInput = ({ folders, onChange, uuid }) => {
  const existingFolders = folders || []
  const [path, setPath] = useState('')
  const [documents, setDocuments] = useState([])
  const [rawDocuments, setRawDocuments] = useState(undefined)
  const [showAll, setShowAll] = useState(false)
  const [count, setCount] = useState(undefined)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const { refetch, loading } = useGetDocuments({
    params: { path, overridePermissions: true },
    onCompleted: ({ documents: response }) => {
      const directories = response.directories.map(({ name }) => ({
        title: name,
        key: `${path}/${name}`,
        selectable: !path,
        checkable: !path,
      }))
      const files = response.files.map(({ name }) => ({
        title: name,
        key: `${path}/${name}`,
        isLeaf: true,
        selectable: false,
        checkable: false,
      }))
      const all = [...directories, ...files]
      if (!path) {
        setRawDocuments(all)
      } else {
        const newState = getDirectoryTree(documents, path, all)
        setDocuments(newState)
      }
    },
  })

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys)
  }

  const onCheck = checkedKeys => {
    if (checkedKeys.length > existingFolders.length) {
      setShowAll(true)
    }
    onChange(checkedKeys)
  }

  const onSelect = selectedKeys => {
    setSelectedKeys(selectedKeys)
  }

  const onLoadData = ({ key, children }) => {
    setPath(key)
    return new Promise(resolve => {
      if (children) {
        resolve()
      }
      refetch().finally(resolve)
    })
  }

  useEffect(() => {
    if (folders !== undefined && rawDocuments!==undefined) {
      if (!count) {
        setCount(folders?.length || 0)
      }
      if (!documents.length) {
        const documents = sortBySelectedDirectories(rawDocuments, folders)
        setDocuments(documents)
      }
    }
    if (!uuid && folders === undefined) {
      setDocuments(rawDocuments)
    }
  }, [uuid, folders, rawDocuments])

  const shouldShowAll = !existingFolders.length || showAll
  return (
    <Collapse defaultActiveKey={['1']}>
      <StyledPanel header={`Folders (${existingFolders.length})`} key="1">
        <StyledDirectoryTree
          selectable={false}
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={false}
          onCheck={onCheck}
          checkedKeys={documents?.length ? existingFolders : []}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          loadData={onLoadData}
          treeData={shouldShowAll ? documents : documents.slice(0, count)}
        />
        {!shouldShowAll && !loading && (
          <ButtonWrapper>
            <Button type="link" onClick={() => setShowAll(true)}> Show all folders </Button>
          </ButtonWrapper>
        )}
      </StyledPanel>
    </Collapse>
  )
}
FoldersInput.propTypes = {
  folders: PropTypes.array,
  onChange: PropTypes.func,
}

FoldersInput.defaultProps = {
  onChange: () => null,
}
export default FoldersInput
