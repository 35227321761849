import * as React from 'react'
import { Helmet } from 'react-helmet'
import useGetFund from 'src/apollo/query/funds/useGetFund'
import Layout from '../../../../layouts/Layout'
import Hero from '../../../Hero/Hero'
import Error from '../../Error/Error'
import Loader from '../../Loader/Loader'
import LoadingOverlay from '../../../Admin/ui/LoadingOverlay'
import Fund from './Fund'
import { portal, portalPage } from './FundPage.module.scss'
import useGtag, { EVENT_ACTIONS, EVENT_CATEGORIES, EVENT_LABELS } from '../../../../hooks/useGtag'
import PortalMenu from '../../../PortalMenu/PortalMenu'

export default ({ fundPath }) => {
  const { sendEvent } = useGtag()
  const { data, loading, error } = useGetFund({
    params: {
      path: fundPath,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      sendEvent({
        category: EVENT_CATEGORIES.funds,
        action: EVENT_ACTIONS.viewFund,
        label: `${EVENT_LABELS.viewFund} ${data?.fund?.name}`,
      })
    },
  })
  const fund = data?.fund

  return (
    <Layout getInTouchVisible={false} lightBlueFooter className={portalPage} portal>
      <Helmet>
        <title>Fund - Emso</title>
      </Helmet>
      <Hero className={portal} admin />
      <PortalMenu />
      {loading && !fund && <Loader />}
      {error && <Error />}
      {fund && (
        <LoadingOverlay loading={loading}>
          <Fund {...fund} />
        </LoadingOverlay>
      )}
    </Layout>
  )
}
