import React from 'react'
import { PageHeader as Header } from 'antd'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const StyledPageHeader = styled(Header)`
  background: transparent;
  padding-left: 0;
`

const PageHeader = ({ to, ...props }) => {
  const handleBack = () => (
    to ? navigate(to) : null
  )

  return (
    <>
      <StyledPageHeader backIcon={to ? undefined : false} onBack={handleBack} {...props}/>
    </>
  )
}

export default PageHeader
