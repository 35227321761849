import gql from 'graphql-tag'
import { groupFieldsFragment } from './group'

export const documentFieldsFragment = gql`
    fragment documentFields on Document {
        name
        meta {
            visible
            groups
            lastModified
            root
        }
        groups {
            ...groupFields
        }
    }
    ${groupFieldsFragment}
`
