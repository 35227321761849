import React from 'react'
import { navigate } from 'gatsby-link'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PublishPeople from 'src/apollo/mutation/people/PublishPeople'
import { getRoute } from 'src/utils/routes'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const SpacedButton = styled(Button)`
  margin-right: 15px;
`

const PeopleActions = ({ uuid }) => {
  return (
    <ButtonWrapper>
      <SpacedButton
        id="PeopleActions-edit" onClick={() => navigate(getRoute('admin.people.edit', { uuid }))}
        icon={<EditOutlined />}> Edit </SpacedButton>
      <PublishPeople uuids={[uuid]}>
        {(publish, { loading }) => (
          <Button
            id="PeopleActions-publish"
            loading={loading}
            type='primary'
            onClick={publish}
            icon={<SaveOutlined />}> Publish </Button>
        )}
      </PublishPeople>
    </ButtonWrapper>
  )
}

PeopleActions.propTypes = {
  uuid: PropTypes.string.isRequired,
}

export default PeopleActions
