// extracted by mini-css-extract-plugin
export var arrow = "emso-Member-module__arrow";
export var arrowContainer = "emso-Member-module__arrowContainer";
export var arrowNext = "emso-Member-module__arrowNext";
export var arrowPrev = "emso-Member-module__arrowPrev";
export var banner = "emso-Member-module__banner";
export var bannerContent = "emso-Member-module__bannerContent";
export var blueBar = "emso-Member-module__blueBar";
export var button = "emso-Member-module__button";
export var center = "emso-Member-module__center";
export var colorGrey = "#a7a9a2";
export var container = "emso-Member-module__container";
export var content = "emso-Member-module__content";
export var footerName = "emso-Member-module__footerName";
export var hasImage = "emso-Member-module__hasImage";
export var location = "emso-Member-module__location";
export var name = "emso-Member-module__name";
export var next = "emso-Member-module__next";
export var photo = "emso-Member-module__photo";
export var photoCred = "emso-Member-module__photoCred";
export var prev = "emso-Member-module__prev";
export var text = "emso-Member-module__text";
export var ticker = "emso-Member-module__ticker";
export var tickerTitle = "emso-Member-module__tickerTitle";
export var title = "emso-Member-module__title";