import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const deleteFundMutation = gql`
    mutation DeleteFund($params: DeleteInput!) {
        deleteFund(params: $params)
    }
`

const useDeleteFund = ({ params, ...options } = {}) => {
  return useMutation(deleteFundMutation, {
    variables: { params }, ...options,
  })
}

export default useDeleteFund
