import * as React from 'react'
import classnames from 'classnames'

import Row from '../../../Row/Row'
import Button from '../../../Button/Button'
import Popup from './Popup'
import * as styles from './DocumentHeader.module.scss'

// TODO: Add search / filter functionality to backend
const DocumentHeader = ({ breadcrumbs, showFilter = false }) => {
  const [showDateModal, setShowDateModal] = React.useState(false)
  const lastCrumb = breadcrumbs.length - 1
  return (
    <div className={styles.container}>
      <Row inlineRow className={styles.row}>
        <ul className={styles.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <li
              key={breadcrumb.value}
              onClick={breadcrumb.onClick}
              className={classnames(styles.li, { [styles.active]: index === lastCrumb })}
            >
              {breadcrumb.value}
              {index !== lastCrumb && <span className={styles.arrow}>›</span>}
            </li>
          ))}
        </ul>
        {showFilter && (
          <>
            <Button type="portal" classNames={styles.button} onClick={() => setShowDateModal(true)}>
              Filter by Date
            </Button>
            <Popup open={showDateModal} closePopup={() => setShowDateModal(false)} />
          </>
        )}
      </Row>
      <hr className={styles.hr} />
    </div>
  )
}

export default DocumentHeader
