import React from 'react'
import styled from 'styled-components'
import {Typography} from 'antd'

const {Text} = Typography

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Path = styled(Text)`
  font-size: smaller;
  line-height: initial;
`

const FileName = styled(Text)``

const DescriptionRenderer = ({ properties, subjectType, description, ...rest }) => {
  if(subjectType === 'File') {
    const {fileName, path} = JSON.parse(properties)
    return (
      <Wrapper>
        <FileName>{fileName}</FileName>
        <Path disabled>{path}</Path>
      </Wrapper>
    )
  }
  return description
}
export default DescriptionRenderer
