import React from 'react'
import { InboxOutlined } from '@ant-design/icons';

const EmptyUpload = () => (
  <>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag an image to this area to upload</p>
  </>
)

export default EmptyUpload
