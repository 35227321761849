import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ createdAt }) => {
  const showDate = dayjs(new Date(createdAt)).format('YYYY-MM-DD')
  return (
    <Wrapper>{showDate}</Wrapper>
  )
}
export default DateRenderer
