import * as React from 'react'
import { Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Row from '../../Row/Row'
import Col from '../../Col/Col'
import { table, heading, dateCol, visibleCol } from './DocumentTable.module.scss'
import Item from './Item/Item'

const ORDER_ASC = 'asc'
const ORDER_DESC = 'desc'

const SortIcon = styled(DownOutlined)`
  margin-left: 5px;
`

const COLUMNS_NAMES = {
  NAME: 'name',
  DATE: 'date',
}

export default ({
  data = [],
  showVisibility,
  onToggleAll,
  allFilesChecked,
  sortByData = false,
  currentDirectory,
  checkboxDisabled = false,
  showCheckBoxForDirectories = false,
}) => {
  const [order, setOrder] = React.useState(ORDER_ASC)
  const [sortedCol, setSortedCol] = React.useState(sortByData ? COLUMNS_NAMES.DATE : COLUMNS_NAMES.NAME)
  const isAsc = order === ORDER_ASC

  React.useEffect(() => {
    if (sortByData) {
      setSortedCol(COLUMNS_NAMES.DATE)
    } else {
      setSortedCol(COLUMNS_NAMES.NAME)
    }
  }, [sortByData, currentDirectory])

  const items = data.sort((a, b) => {
    let sort = a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    if (sortedCol === COLUMNS_NAMES.DATE) {
      sort = b.date.toUpperCase().localeCompare(a.date.toUpperCase())
      return isAsc ? sort : -sort
    }
    return isAsc ? sort : -sort
  })

  const toggleNameSort = () => {
    setSortedCol(COLUMNS_NAMES.NAME)
    setOrder(isAsc ? ORDER_DESC : ORDER_ASC)
  }

  const toggleDateSort = () => {
    setSortedCol(COLUMNS_NAMES.DATE)
    setOrder(isAsc ? ORDER_DESC : ORDER_ASC)
  }
  return (
    <Row className={table}>
      <Row inlineRow className={heading}>
        <Col large={7}>
          <span onClick={toggleNameSort}>
            <span>File Name</span>
            {sortedCol === COLUMNS_NAMES.NAME && <SortIcon rotate={isAsc ? 0 : 180} />}
          </span>
        </Col>
        <Col large={3} className={dateCol}>
          <span onClick={toggleDateSort}>
            <span>Date</span>
            {sortedCol === COLUMNS_NAMES.DATE && <SortIcon rotate={isAsc ? 0 : 180} />}
          </span>
        </Col>
        {showVisibility && items.length > 0 && (
          <div className={visibleCol}>
            <Checkbox style={{marginTop: '-3px', top: 'unset'}} disabled={checkboxDisabled} checked={allFilesChecked} onChange={onToggleAll} />
          </div>
        )}
      </Row>
      {items.map(item => (
        <Item
          key={item.id || item.name}
          onClick={item.onClick}
          onEdit={item.onEdit}
          name={item.name}
          nameOverride={item.nameOverride}
          date={item.date}
          id={item.id}
          type={item.type}
          visible={item.visible}
          marked={item.marked}
          path={item.path}
          showVisibility={showVisibility}
          checkboxDisabled={checkboxDisabled}
          showCheckBoxForDirectories={showCheckBoxForDirectories}
        />
      ))}
    </Row>
  )
}
