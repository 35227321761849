import * as React from 'react'
import { navigate } from '@reach/router'
import classnames from 'classnames'
import { IconYellowArrow } from 'src/data/icons'
import { getRoute } from 'src/utils/routes'
import { ticker, prev, arrowPrev, arrowNext, next, arrowContainer, colorGrey, arrow, footerName, tickerTitle } from './Member.module.scss'

export const Ticker = ({ member, direction }) => {
  const [hovering, setHovering] = React.useState(false)
  const { uuid, firstName, lastName, title } = member

  return (
    <div
      onClick={() => navigate(getRoute('portal.people.view', { uuid }))}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={classnames(ticker, direction === 'left' ? prev : next)}
    >
      <div className={classnames(arrowContainer, direction === 'left' ? arrowPrev : arrowNext)}>
        <div className={arrow}>
          <IconYellowArrow color={hovering ? undefined : colorGrey} direction={direction} />
        </div>
      </div>
      <div className={footerName}>
        {firstName} {lastName}
      </div>
      <div className={tickerTitle}>{title}</div>
    </div>
  )
}

export default Ticker
