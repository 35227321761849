import * as React from 'react'
import { AnonymousCredential, BlobServiceClient, BlockBlobClient, newPipeline } from '@azure/storage-blob'
import { AbortController } from '@azure/abort-controller'
import { remark } from 'remark'
import remark2react from 'remark-react'
import frontmatter from 'front-matter'

import Row from '../../Row/Row'
import Hero from '../../Hero/Hero'
import Col from '../../Col/Col'
import heroImageMap from '../../MapSection/LargeMap.png'
import { hero } from './Admin.module.scss'

const possibleAttributes = [
  'path',
  'date',
  'title',
  'type',
  'authorName',
  'authorTitle',
  'videoURL',
  'videoPoster',
  'standalone',
  'subTitle',
  'image',
  'tags',
  'private',
]

function getContainerURL() {
  const anonymousCredential = new AnonymousCredential()
  const account = 'emsowebsitestagingtemp' // TODO: GET FROM CONFIG
  const pipeline = newPipeline(anonymousCredential)
  const token = '' // TODO: GET TOKEN FROM FAAS
  const serviceURL = new BlobServiceClient(`https://${account}.blob.core.windows.net?sv=${token}`, pipeline)

  return serviceURL.url
}

async function getBlob(file) {
  const containerURL = getContainerURL()
  const blobURL = new BlobServiceClient(containerURL, file)
  const downloadBlockBlobResponse = await blobURL.download(AbortController, 0)
  const asBlob = await downloadBlockBlobResponse.blobBody
  const text = await new Response(asBlob).text()
  return frontmatter(text)
}

async function uploadFile(fileinput) {
  const containerURL = getContainerURL()
  const browserFile = fileinput.files[0]
  const blobName = `images/uploaded-${new Date().getTime()}.${browserFile.name.split('.').pop()}`
  const blobClient = new BlockBlobClient(containerURL, 'images', blobName)

  return await blobClient.uploadBrowserData(browserFile, {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    parallelism: 20, // 20 concurrency
    progress: ev => console.log(ev),
  })
}

export default ({ file }) => {
  const [markdown, setMarkdown] = React.useState('')
  const [markdownAttr, setMarkdownAttr] = React.useState('')

  async function fetchBlob() {
    const text = await getBlob(file)
    setMarkdownAttr(text.attributes)
    setMarkdown(text.body)
  }

  const uploaderRef = React.useRef()

  React.useEffect(() => {
    fetchBlob()
  }, [])

  const handleValueChange = e => setMarkdown(e.target.value)
  const uploadFileClick = async () => {
    const fileName = await uploadFile(uploaderRef.current)
    console.log('uploaded', { fileName })
  }
  return (
    <Hero header={'Media Editor'} image={heroImageMap} className={hero} content={[]}>
      <input ref={uploaderRef} type="file" id="fileinput" />
      <button onClick={uploadFileClick} id="upload-button">
        Upload
      </button>
      <Row inlineRow>
        <Col large={6}>
          {possibleAttributes.map((field, index) => (
            <input style={{ width: '100%', display: 'block' }} key={index} type="text" placeholder={field} value={markdownAttr[field]} />
          ))}
          <textarea onChange={handleValueChange} style={{ height: '800px', width: '100%' }} value={markdown} />
        </Col>
        <Col large={6}>
          <div>
            {
              remark()
                .use(remark2react)
                .processSync(markdown).contents
            }
          </div>
        </Col>
      </Row>
    </Hero>
  )
}
