import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  text-align: center;
`

const Error = ({message}) => (
  <StyledAlert
    message={message}
    type="error"
  />
)

Error.propTypes = {
  message: PropTypes.string
}

Error.defaultProps = {
  message: 'Something went wrong. Please refresh the page and try again'
}

export default Error

