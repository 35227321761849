import gql from 'graphql-tag'
import {personFieldsFragment} from "../../fragments/person";

export const bulkUpdateIndexMutation = gql`
    mutation BulkUpdatePersonIndex($params: BulkUpdatePersonIndexInput!) {
        bulkUpdatePersonIndex(params: $params){
            results {
                ...personFields
                original {
                    ...personFields
                }
            }
        }
    }
  ${personFieldsFragment}
`