export const ACTION_TYPE_REPLACE_ALL = 'replaceAll'
export const ACTION_TYPE_UPDATE = 'update'

export const fundDataReducer = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPE_REPLACE_ALL:
      return action.payload
    case ACTION_TYPE_UPDATE:
      const { uuid } = action.payload
      return state.map(item => item.uuid === uuid ? action.payload : item)
    default:
      return state
  }
}
