import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'src/utils/constants/insights'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = item => {
  const date = item?.data?.date
  const showDate = dayjs(new Date(date)).format(DATE_FORMAT)
  return date ? (
    <Wrapper>{showDate}</Wrapper>
  ) : null
}
export default DateRenderer
