// extracted by mini-css-extract-plugin
export var arrow = "emso-FundRow-module__arrow";
export var background = "emso-FundRow-module__background";
export var colorDarkGold = "#e7b50c";
export var colorLightGray = "#ebebeb";
export var date = "emso-FundRow-module__date";
export var header = "emso-FundRow-module__header";
export var logo = "emso-FundRow-module__logo";
export var logoName = "emso-FundRow-module__logoName";
export var mobileHide = "emso-FundRow-module__mobileHide";
export var name = "emso-FundRow-module__name";
export var nameDate = "emso-FundRow-module__nameDate";
export var row = "emso-FundRow-module__row";
export var rowSmall = "emso-FundRow-module__rowSmall";
export var value = "emso-FundRow-module__value";
export var wrapper = "emso-FundRow-module__wrapper";
export var yellowColor = "emso-FundRow-module__yellowColor";