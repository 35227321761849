import moment from 'moment'

export const RAW_DATE_FORMAT_FUND = 'YYYY-MM-DD'

export const matrixHeader = [
  'fundstat-monthlyreturn',
  'fundstat-ytdreturn',
  'fundstat-aum',
  'performance-year',
  'performance-month',
  'performance-value',
  'additional-label',
  'additional-value',
  'fundId',
]

export const getFundMatrix = (matrix, fundId) => {
  return matrix.filter(row => row[8] === fundId)
}

export const getDate = ({ month, year }) => {
  return moment().endOf('month').month(month - 1).year(year).format(RAW_DATE_FORMAT_FUND)
}

export const mapFundCsvPerformanceData = (matrix = [[]], fundId) => {
  const isValid = matrix[0].toString().startsWith(matrixHeader.toString())
  if (!isValid) {
    throw new Error('The provided csv file is invalid')
  }

  const data = {
    performance: [],
    monthlyReturn: null,
    ytdReturn: null,
    nav: null,
    additionalPerformance: null,
    isDateAutoFilled: false
  }
  const fundMatrix = getFundMatrix(matrix, fundId)

  if (fundMatrix.length < 4) {
    throw new Error('The external id was not found in the provided csv file')
  }

  fundMatrix.forEach((row, index) => {
    if (row[3] === '' && row[4] === '') {
      const previousRow = fundMatrix[index -1]
      if (previousRow[3] !== '' && previousRow[4] !== '') {
        data.performance.push({
          year: previousRow[3],
          month: previousRow[4],
          value: parseInt(row[5]),
        })
        data.isDateAutoFilled = true;
      }
    } else {
      data.performance.push({
        year: row[3],
        month: parseInt(row[4]),
        value: parseInt(row[5]),
      })
    }
  })

  data.monthlyReturn = parseFloat(fundMatrix[0][0])
  data.ytdReturn = parseFloat(fundMatrix[0][1])
  data.nav = parseFloat(fundMatrix[0][2])

  // set date to latest performance row
  const lastMonth = data?.performance?.[data?.performance?.length - 1]
  if (lastMonth) {
    data.date = getDate(lastMonth)
  }

  if (data.performance.length <= 12) {
    return { ...data, performance: null }
  }

  data.additionalPerformance = {}
  fundMatrix.slice(0, 4).forEach(row => {
    data.additionalPerformance[row[6]] = parseFloat(row[7])
  })

  return data
}
