import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../../layouts/Layout'
import Hero from '../../Hero/Hero'
import { notFoundWrapper, notFoundTitle, seperator, goBackLink, portalPage } from '../../../pages/404.module.scss'

export default props => {
  return (
    <Layout getInTouchVisible={false} lightBlueFooter className={portalPage}>
      <Helmet>
        <title>Not Found - Emso</title>
      </Helmet>
      <Hero className={portal} admin />
      <section className={notFoundWrapper}>
        <h1 className={notFoundTitle}>PAGE NOT FOUND</h1>
        <div className={seperator} />
        <h2 className={goBackLink}>
          Go back <a href="/">home</a>
        </h2>
      </section>
    </Layout>
  )
}
