import * as React from 'react'
import { Card, Input, Form, Col, Radio } from 'antd'
import styled from 'styled-components'
import UsersListQuery from '../../../../apollo/query/users/UsersList'
import PageHeader from '../../ui/PageHeader'
import Table from '../../Table/Table'
import { columns } from './columns'
import ActionButtons from '../../ActionButtons/ActionButtons'
import { navigate } from 'gatsby-link'
import { handleOrderChange } from '../../../../helpers/table'
import Filters from './Filters'

const Wrapper = styled.div`
  //position: relative;
`
const PAGE_SIZE = 20
const START_PAGE = 0

export default () => {
  const [page, setPage] = React.useState(START_PAGE)
  const [limit, setLimit] = React.useState(PAGE_SIZE)
  const [filter, setFilter] = React.useState('')
  const [order, setOrder] = React.useState([])
  const [disabled, setDisabled] = React.useState(false)

  React.useEffect(() => {
    setPage(START_PAGE)
  }, [limit, filter, disabled])

  const handleSearch = value => {
    setFilter(value)
  }

  const handleTableChange = (pagination, filters, sorter, { action }) => {
    if (action === 'sort') {
      handleOrderChange(sorter, setOrder)
    }
  }

  const actions = [{
    children: 'Create user',
    onClick: () => navigate('/portal/admin/users/create'),
  }]

  return (
    <Col xl={24} xxl={18}>
      <Wrapper>
        <PageHeader title='Users' />
        <ActionButtons actions={actions} />
        <Card title="All users" extra={
          <Filters
            onSearch={handleSearch}
            setDisabled={setDisabled}
            disabled={disabled} />
        }>
          <UsersListQuery
            filter={filter}
            page={page}
            perPage={limit}
            order={order.length ? order : undefined}
            disabled={disabled}
            fetchPolicy="cache-and-network">
            {({ data, loading }) => {
              const { users, total } = data?.users || {}
              return (
                <Table
                  thin
                  rowKey='id'
                  loading={loading}
                  dataSource={users}
                  columns={columns}
                  scroll={{ x: 400 }}
                  onChange={handleTableChange}
                  pagination={{
                    current: page + 1,
                    total,
                    pageSize: limit,
                    onChange: page => {
                      setPage(page - 1)
                    },
                    onPageSizeChange: pageSize => {
                      setLimit(pageSize)
                    },
                  }}
                />
              )
            }}
          </UsersListQuery>
        </Card>
      </Wrapper>
    </Col>
  )
}
