import React from 'react'
import styled from 'styled-components'
import { Tag } from 'antd'

const StyledTag = styled(Tag)`
  padding: 5px;
  font-size: 16px;
  flex: 1 1 30px;
  text-align: center;
  margin-right: 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const ResponsiveTagList = ({data, keySelector, textSelector}) => {
  return (
    <Wrapper>
      {data.map(item => <StyledTag key={item[keySelector]}>{item[textSelector]}</StyledTag>)}
    </Wrapper>
  )
}

export default ResponsiveTagList