import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ createdAt }) => {
  const date = moment(createdAt, 'unix')
  const showDate = date.isSame(new Date(), 'day')
    ? date.fromNow()
    : date.format('DD MMM YYYY HH:mm')
  return (
    <Wrapper>{showDate}</Wrapper>
  )
}
export default DateRenderer
