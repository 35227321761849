import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const bulkToggleGroupsFundMutation = gql`
    mutation BulkToggleGroupsFund($params: BulkToggleGroupsFundInput!) {
        bulkToggleGroupsFund(params: $params)
    }
`

const useBulkToggleGroupsFund = ({ params, ...options } = {}) => {
  return useMutation(bulkToggleGroupsFundMutation, {
    variables: { params }, ...options,
  })
}

export default useBulkToggleGroupsFund
