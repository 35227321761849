import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { logFieldsFragment } from '../../fragments/log'

const query = gql`
    query LogsList($params: LogListInput) {
        logs(params: $params) {
            results {
                ...logFields
            }
            page
            limit
            total
            count
        }
    }
    ${logFieldsFragment}
`

const useGetLogs = (options) => {
  return useQuery(query, options)
}

export default useGetLogs
