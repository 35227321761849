import DateRenderer from './renderers/DateRenderer'
import DescriptionRenderer from './renderers/DescriptionRenderer'

const columns = [
  {
    title: 'Action',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    render: DescriptionRenderer,
    key: 'description',
  },
  {
    title: 'Date',
    render: DateRenderer,
    key: 'createdAt',
  },
]

export default columns
