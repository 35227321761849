import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../../layouts/Layout'
import Hero from '../../Hero/Hero'
import { portalPage, portal, documents} from './Documents.module.scss'
import Documents from './Documents'
import PortalMenu from '../../PortalMenu/PortalMenu'

export default ({ fundPath }) => {
  return (
    <Layout getInTouchVisible={false} className={portalPage} portal>
      <Helmet>
        <title>Fund - Emso</title>
      </Helmet>
      <Hero className={portal} admin />
      <PortalMenu />
      <div className={documents}>
        <Documents fundPath={fundPath} />
      </div>
    </Layout>
  )
}
