import React, { useState } from 'react'
import { differenceBy } from 'lodash'
import useGetGroups from 'src/apollo/query/groups/useGetGroups'
import GroupManager from '../../../GroupManager/GroupManager'

const UserPermissions = ({ loading, currentGroups, onSave, toggle, open }) => {
  const [toRemove, setToRemove] = useState([])
  const [toAdd, setToAdd] = useState([])
  const { data: allGroupsData, loading: allGroupsLoading } = useGetGroups({ fetchPolicy: 'network-only' })

  const allGroups = allGroupsData?.groups?.results || []

  const changes = [
    ...toRemove.map(item => ({ ...item, toRemove: true })),
    ...toAdd.map(item => ({ ...item, toAdd: true })),
  ]
  const available = differenceBy(allGroups, [...currentGroups, ...changes], 'uuid')
  const current = differenceBy(currentGroups, changes, 'uuid')

  const resulting = [...current, ...toAdd]

  const handleSelect = value => {
    setToAdd([...toAdd, ...value])
  }

  const handleRevert = value => {
    value.toRemove
      ? setToRemove(differenceBy(toRemove, [value], 'uuid'))
      : setToAdd(differenceBy(toAdd, [value], 'uuid'))
  }

  const handleRemove = value => {
    setToRemove([...toRemove, value])
  }

  const handleSave = () => {
    onSave(resulting)
    setToAdd([])
    setToRemove([])
  }

  return (
    <GroupManager
      toggle={toggle}
      open={open}
      available={available}
      changes={changes}
      current={current}
      onSelect={handleSelect}
      onRemove={handleRemove}
      onRevert={handleRevert}
      loading={allGroupsLoading || loading}
      onSave={handleSave}
      resulting={changes.length ? resulting : []}
      onCancel={() => {
        setToAdd([])
        setToRemove([])
      }}
    />
  )
}

export default UserPermissions
