import React, { useCallback } from 'react'
import { navigate } from 'gatsby'
import { SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, notification, Card, Form, Col } from 'antd'
import useSaveGroup from 'src/apollo/mutation/groups/useSaveGroup'
import useGetGroup from 'src/apollo/query/groups/useGetGroup'
import styled from 'styled-components'
import PageHeader from '../../ui/PageHeader'
import FormItem from '../../ui/FormItem'
import useDeleteGroup from '../../../../apollo/mutation/groups/useDeleteGroup'
import LoadingOverlay from '../../ui/LoadingOverlay'
import EditActions from './EditActions'
import ActionButtons from '../../ActionButtons/ActionButtons'
import LogViewDrawer from '../../LogViewer/LogViewDrawer'
import { GROUP_SUBJECT_TYPE } from '../../LogViewer/constants'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default ({ groupId: uuid }) => {
  const [name, setName] = React.useState()

  const { loading: getLoading } = useGetGroup({
    params: { uuid },
    skip: !uuid,
    onCompleted: data => {
      data && setName(data.group.name)
    },
  })

  const [saveGroup, { loading: saveLoading, error }] = useSaveGroup({
    params: { uuid, name },
    onCompleted: ({ createGroup }) => {
      notification.success({ message: `The group was ${createGroup ? 'created' : 'updated'}` })
      navigate('/portal/admin/groups')
    },
  })

  const [deleteGroup, { loading: deleteLoading }] = useDeleteGroup({
    params: { uuid },
    onCompleted: () => {
      notification.success({ message: `The group was deleted` })
      navigate('/portal/admin/groups')
    },
  })

  const handleInputChange = useCallback(({ target: { value } }) => {
    setName(value)
  }, null)

  const loading = getLoading || deleteLoading || saveLoading
  const actions = [
    {
      children: 'Delete group',
      onClick: deleteGroup,
      loading: deleteLoading,
      disabled: !uuid || loading,
    },
    {
      children: <LogViewDrawer subjectId={uuid} subjectType={GROUP_SUBJECT_TYPE}><span>Activity Log</span></LogViewDrawer>,
      disabled: !uuid || loading,
    },
  ]
  return (
    <Col xl={24} xxl={18}>
      <PageHeader
        title={`${uuid ? 'Edit' : 'Create'} Group`} to='/portal/admin/groups'/>
      <ActionButtons actions={actions}/>
      <LoadingOverlay loading={getLoading}>
        <Card title='Group information'>
          <Form labelCol={{ span: 3 }} wrapperCol={{ span: 17 }}>
            <FormItem label='Name' error={error?.message}>
              <Input name="title" disabled={loading} value={name} onChange={handleInputChange}/>
            </FormItem>
            <Form.Item wrapperCol={{ span: 18, offset: 2 }}>
              <ButtonWrapper>
                <Button
                  type="primary"
                  loading={saveLoading}
                  disabled={loading}
                  onClick={saveGroup}
                  icon={uuid ? <SaveOutlined/> : <PlusOutlined/>}>{uuid ? 'Save' : 'Create'}
                </Button>
              </ButtonWrapper>
            </Form.Item>
          </Form>
        </Card>
      </LoadingOverlay>
    </Col>
  )
}
