import * as React from 'react'
import classnames from 'classnames'
import Modal from 'react-modal'
import 'flatpickr/dist/themes/light.css'

import Button from '../Button/Button'
import Col from '../Col/Col'
import {
  modalLarge,
  contentLarge,
  modal,
  content,
  header as headerStyle,
  overlay,
  bottomRow,
  col,
  cancel as cancelStyle,
  button,
} from './Modal.module.scss'

Modal.setAppElement('#___gatsby')

export default ({ open, cancelClick, primaryButton, primaryClick, header, children, large, className, cancel = 'Cancel' }) => (
  <Modal isOpen={open} overlayClassName={overlay} className={classnames(className, large ? modalLarge : modal)}>
    <h1 className={headerStyle}>{header}</h1>
    <div className={classnames(className, large ? contentLarge : content)}>{children}</div>
    <div className={bottomRow}>
      <Col className={col}>
        <a onClick={cancelClick} className={cancelStyle}>
          {cancel}
        </a>
      </Col>
      <Col className={col}>
        {primaryClick && (
          <Button type="portal" classNames={button} onClick={primaryClick}>
            {primaryButton}
          </Button>
        )}
      </Col>
    </div>
  </Modal>
)
