import React from 'react'
import FundPermissions from '../FundPermissions/FundPermissions'
import LogViewDrawer from '../../../LogViewer/LogViewDrawer'
import { FUND_SUBJECT_TYPE } from '../../../LogViewer/constants'
import ActionButtons from '../../../ActionButtons/ActionButtons'

const Actions = ({ uuid, saveFund, deleteFund, saveLoading, loading, deleteLoading }) => {
  const saveButtonText = uuid ? 'Save changes' : 'Create fund'
  const [permissionsOpen, setPermissionsOpen] = React.useState(false)
  const [permissionsLoading, setPermissionsLoading] = React.useState(false)
  const actions = [
    {
      onClick: saveFund,
      loading: saveLoading,
      children: saveButtonText,
    },
    {
      onClick: () => setPermissionsOpen(true),
      disabled: permissionsLoading,
      children: 'Permissions',
    },
    {
      onClick: deleteFund,
      loading: deleteLoading,
      children: 'Delete Fund',
    },
    {
      loading: false,
      children: <LogViewDrawer subjectId={uuid} subjectType={FUND_SUBJECT_TYPE}><span>Activity Log</span></LogViewDrawer>,
    },
  ]

  return (
    <>
      <ActionButtons actions={actions}/>
      <FundPermissions fundUuid={uuid} open={permissionsOpen} toggle={setPermissionsOpen} onLoadingChange={setPermissionsLoading} />
    </>
  )
}

export default Actions
