import DateRenderer from './renderers/DateRenderer'
import CauserRenderer from './renderers/CauserRendered'

const columns = [
  {
    title: 'Action',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'User',
    render: CauserRenderer,
    key: ['causedBy', 'email'],
  },
  {
    title: 'Date',
    render: DateRenderer,
    key: 'createdAt',
  },
]

export default columns
