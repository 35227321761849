import gql from 'graphql-tag'

export const crmPersonFieldsFragment = gql`
    fragment crmPersonFields on CrmPerson {
        id
        email
        firstName
        lastName
    }
`

export const crmPersonCategoryFieldsFragment = gql`
    fragment crmPersonCategoryFields on CrmPersonCategory {
        id
        name
    }
`
