import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import ActionButtons from '../../ActionButtons/ActionButtons'

const CreateVideo = () => {
  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  const actions = [
    {
      children: 'Create video',
      icon: <PlusOutlined />,
      onClick: showModal,
    },
  ]

  return (
    <div>
      <ActionButtons actions={actions} />
      <Modal
        title="Create new video not supported"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        footer={(
          <Button type="primary" onClick={hideModal}>
            OK
          </Button>
        )}
      >
        <div>Please contact the technology team to upload a video.</div>
      </Modal>
    </div>
  )
}

export default CreateVideo
