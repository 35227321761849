import { get as getValue, orderBy } from 'lodash'

export const sortGroups = (groups) => {
  return orderBy(groups, [group => group.name.toLowerCase()])
}

export const getGroupIdsAsArray = (meta) => {
  const existingGroupIdsRaw = getValue(meta, 'groups',null)
  return existingGroupIdsRaw ? existingGroupIdsRaw.split(',') : []
}
