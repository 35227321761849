import gql from 'graphql-tag'
import useMutation from '../useMutation'
import { tagFieldsFragment } from '../../fragments/tag'

export const createTagMutation = gql`
    mutation CreateTag($params: CreateTagInput!) {
        createTag(params: $params){
            ...tagFields
        }
    }
  ${tagFieldsFragment}
`

const useCreateTag = ({ params, ...options } = {}) => {
  return useMutation(createTagMutation, {
    variables: { params }, ...options,
  })
}

export default useCreateTag
