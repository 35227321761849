import React from 'react'
import { Select } from 'antd'
import {omit} from 'lodash'
import {departments} from 'src/utils/constants/team'


const DepartmentSelect = ({value, onChange, ...props}) => {
  const name = "department"
  return (
    <Select name={name} value={value} onChange={onChange} {...props}>
      {Object.keys(omit(departments, 'all')).map(key => <Select.Option key={key} > {departments[key]} </Select.Option>)}
    </Select>
  )
}

export default DepartmentSelect
