import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import Query from '../Query'
import { PERSON_STATUS_PUBLISHED } from '../../../constants/constants'
import { personFieldsFragment } from '../../fragments/person'

const query = gql`
    query Person($params: PersonFilterInput, $withNext: Boolean!, $withPrevious: Boolean!) {
        person(params: $params) {
            ...personFields
            original {
                ...personFields
            }
            next @include(if: $withNext) {
                ...personFields
            }
            previous @include(if: $withPrevious) {
                ...personFields
            }
        }
    }
    ${personFieldsFragment}
`

const PersonQuery = ({ children, uuid, path, status, version, withNext, withPrevious, ...props }) => {
  const params = {
    uuid,
    status,
    path,
    version,
  }
  return (
    <Query query={query} variables={{ params, withNext, withPrevious }} {...props}>
      {data => {
        return children(data)
      }}
    </Query>
  )
}

PersonQuery.propTypes = {
  children: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  path: PropTypes.string,
  status: PropTypes.string,
  version: PropTypes.bool,
  withNext: PropTypes.bool,
  withPrevious: PropTypes.bool,
}
PersonQuery.defaultProps = {
  version: false,
  status: PERSON_STATUS_PUBLISHED,
  withNext: false,
  withPrevious: false
}

export default PersonQuery
