import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { crmPersonCategoryFieldsFragment } from '../../fragments/crmPerson'

export const crmPersonCategoriesQuery = gql`
    query CrmPersonCategories($params: CrmPersonCategoriesInput!) {
        crmPersonCategories(params: $params) {
            ...crmPersonCategoryFields
        }
    }
    ${crmPersonCategoryFieldsFragment}
`

const useGetCrmPersonCategories = ({ params, ...options } = {}) => {
  return useQuery(crmPersonCategoriesQuery, {
    variables: { params: { ...params } },
    ...options,
  })
}

export default useGetCrmPersonCategories
