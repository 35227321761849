import gql from 'graphql-tag'
import useMutation from '../useMutation'
import { insightFieldsFragment } from '../../fragments/insight'

export const createInsightMutation = gql`
    mutation CreateInsight($params: InsightCreateInput!) {
        createInsight(params: $params) {
            ...insightFields
        }
    }
    ${insightFieldsFragment}
`

const useCreateInsight = ({ params, ...options } = {}) => {
  return useMutation(createInsightMutation, {
    variables: { params }, ...options,
  })
}

export default useCreateInsight
