import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { groupFieldsFragment } from '../../fragments/group'

export const getDirectoryParentGroupsQuery = gql`
    query DirectoryParentGroups($params: DirectoryParentGroupsInput!) {
        directoryParentGroups(params: $params) {
            ...groupFields
        }
    }
    ${groupFieldsFragment}
`

const useGetDirectoryParentGroups = ({ params, ...options } = {}) => {
  return useQuery(getDirectoryParentGroupsQuery, {
    variables: { params },
    ...options,
  })
}

export default useGetDirectoryParentGroups
