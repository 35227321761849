import React from 'react'
import PropTypes from 'prop-types'
import { Mutation as ApolloMutation } from '@apollo/client/react/components'
import { get } from 'lodash'
import { notification } from 'antd'

const Mutation = ({ children, onError, showError, ...props }) => {
  const handleError = error => {
    if (showError) {
      notification.error({ message: get(error, 'message', 'Something went wrong') })
    }
    onError(error)
  }
  return (
    <ApolloMutation errorPolicy='all' onError={handleError} {...props}>
      {(mutation, apolloState) => {
        return children(mutation, apolloState)
      }}
    </ApolloMutation>
  )
}

Mutation.propTypes = {
  children: PropTypes.func.isRequired,
  onError: PropTypes.func,
  showError: PropTypes.bool,
}

Mutation.defaultProps = {
  onError: () => null,
  showError: true,
}

export default Mutation
