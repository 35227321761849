import React, { useCallback, useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Card, Col } from 'antd'
import { navigate } from 'gatsby'
import { get } from 'lodash'
import { INSIGHTS_CREATE_PATH } from '../../../../../utils/constants/insights'
import PageHeader from '../../../ui/PageHeader'
import { INSIGHT_STATUS_PUBLISHED } from '../../../../../constants/constants'
import useGetInsights from '../../../../../apollo/query/insights/useGetInsights'
import ErrorHandler from '../../../../Error/ErrorHandler'
import Table from '../../../Table/Table'
import { columns } from './columns'
import ChangesSummary from './ChangesSummary'
import Filters from './Filters'
import ActionButtons from '../../../ActionButtons/ActionButtons'

import 'react-table-v6/react-table.css'

const PAGE_SIZE = 10
const START_PAGE = 1

const InsightsList = () => {
  const [status, setStatus] = useState(INSIGHT_STATUS_PUBLISHED)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(PAGE_SIZE)
  const [page, setPage] = useState(START_PAGE)

  const handleStatusChange = useCallback(({ target: { value } }) => {
    setStatus(value)
  })

  const handleSearchChange = useCallback(value => {
    setSearch(value)
  })

  useEffect(() => {
    setPage(START_PAGE)
  }, [status, search, limit])

  const { data, loading: listLoading, error } = useGetInsights({
    params: { version: true, status, limit, page, search },
    fetchPolicy: 'cache-and-network',
  })

  const insights = get(data, 'insights.results') || []
  const { total } = get(data, 'insights') || {}

  const actions = [
    {
      children: 'Create Insight',
      icon: <PlusOutlined/>,
      onClick: () => navigate(`/portal/admin/insights/edit/${INSIGHTS_CREATE_PATH}`),
    },
  ]

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Insights' />
      <ActionButtons actions={actions}/>
      <ChangesSummary/>

      <ErrorHandler error={error}>
        <Card title="All Insights" extra={
          <Filters onSearch={handleSearchChange} search={search} onStatusChange={handleStatusChange} status={status}/>
        }>
          <Table
            loading={listLoading}
            rowKey='uuid'
            columns={columns}
            scroll={{ x: 400 }}
            pagination={{
              current: page,
              total,
              pageSize: limit,
              onChange: page => {
                setPage(page)
              },
              onPageSizeChange: size => {
                setLimit(size)
              },
            }}
            dataSource={insights}/>
        </Card>
      </ErrorHandler>

    </Col>
  )
}

export default InsightsList
