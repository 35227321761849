import React from 'react'
import { SolutionOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LogViewer from './LogViewer'

const ButtonWithMargin = styled(Button)`
  margin-right: 10px;
`

const LogViewDrawer = ({ children, className, subjectId, subjectType }) => {

  const [open, setOpen] = React.useState(false)

  const button = React.cloneElement(children, {
    onClick: () => setOpen(true),
    className: className,
  })
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      {button}
      <Drawer
        title="Activity Log"
        placement="right"
        closable={false}
        onClose={handleClose}
        visible={open}
        width={'70%'}
        destroyOnClose
      >
        <LogViewer subjectId={subjectId} subjectType={subjectType}/>
      </Drawer>
    </>
  )
}

LogViewDrawer.propTypes = {
  children: PropTypes.node,
}

LogViewDrawer.defaultProps = {
  children: <ButtonWithMargin><SolutionOutlined /></ButtonWithMargin>,
}

export default LogViewDrawer
