import React, { useState } from 'react'
import useGtag, { CUSTOM_DIMENSION, EVENT_ACTIONS, EVENT_CATEGORIES } from '../../../hooks/useGtag'
import auth from '../../../helpers/auth'
import { addWatermarkToPdf } from '../../../utils/helpers/pdf'
import jsZip from 'jszip'
import { saveAs } from 'file-saver'
import useCreateLog from '../../../apollo/mutation/logs/useCreateLog'
import { useLazyGetFile } from '../../../apollo/query/documents/useLazyGetFile'

export const DOWNLOAD_ERROR = {
  NO_FILES: 'NO_FILES',
  READ_ONLY: 'READ_ONLY',
}

async function getFileBlob(fileBlob, fileName, sendEventCallback, createLogMutationCallback, zipFile, currentPath, onError) {
  let blob = fileBlob
  const userProfile = await auth().getUserInfo()

  // add water mark to blobs if pdf
  try {
    if (fileBlob.type === 'application/pdf') {
      const name = userProfile ? `${userProfile.given_name} ${userProfile.family_name}` : ''
      const pdfBuffer = await blob.arrayBuffer()
      blob = await addWatermarkToPdf(pdfBuffer, name)
    }
    zipFile?.file(fileName, blob)
  } catch {
    // we skip this file if the blob is corrupted
    onError()
  }

  await createLogMutationCallback({
    variables: {
      params: {
        name: 'downloaded',
        subjectId: `${currentPath}/${fileName}`,
        subjectType: 'File',
        description: fileName,
        properties: {
          path: currentPath,
          fileName,
        },
      },
    },
  })

  sendEventCallback({
    category: EVENT_CATEGORIES.documents,
    action: EVENT_ACTIONS.downloadFile,
    label: `${fileName}`,
    customDimensions: {
      [CUSTOM_DIMENSION.Username]: userProfile.name,
      [CUSTOM_DIMENSION.Path]: currentPath,
    },
  })
}

const useCreateZip = (handleError, currentPath) => {
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false)
  const { sendEvent } = useGtag()
  const [createLogMutation] = useCreateLog({
    showErrorNotification: false,
  })

  const createZip = () => {
    setIsDownloadInProgress(true)
    return new jsZip()
  }

  const writeToZip = async (zip, name, blob, params) => {
    const onError = () => {
      handleError(params)
    }

    await getFileBlob(blob, name, sendEvent, createLogMutation, zip, currentPath, onError)
  }

  const saveZip = (zip, zipName) => {
    const zipFiles = Object.keys(zip.files)

    if (zipFiles.length) {
      zip
        .generateAsync({ type: 'blob' })
        .then(content => {
          saveAs(content, `${zipName}.zip`)
        })
        .finally(() => {
          setIsDownloadInProgress(false)
        })
    } else {
      setIsDownloadInProgress(false)
    }
  }

  return {
    isDownloadInProgress,
    saveZip,
    createZip,
    writeToZip,
  }
}

const useFailedFileDownload = () => {
  const [filesFailedToDownload, setFilesFailedToDownload] = useState([])

  const handleError = params => {
    setFilesFailedToDownload(prevState => {
      return [...prevState, params]
    })
  }

  const clearErrors = () => {
    setFilesFailedToDownload([])
  }

  return {
    filesFailedToDownload,
    handleError,
    clearErrors,
  }
}

const useMultiDownload = () => {
  const [filesToDownload, setFilesToDownload] = useState([])
  const [currentPath, setCurrentPath] = useState()
  const [zipName, setZipname] = useState('')

  const { filesFailedToDownload, handleError, clearErrors } = useFailedFileDownload()
  const { isDownloadInProgress, saveZip, createZip, writeToZip } = useCreateZip(handleError, currentPath)
  const getFile = useLazyGetFile()

  const retryDownload = async (params = []) => {
    clearErrors()
    const zip = createZip()

    const allFiles = params.map(async param => {
      const folderPath = param.path
      const folderName = folderPath.replace(currentPath, '')
      await getFile({ name: param.name, path: param.path }, zip, folderName, writeToZip, handleError)
    })
    await Promise.all(allFiles)
    saveZip(zip, zipName)
  }

  const downloadAllFiles = async (files = [], path, currentPath, zip) => {
    const allFiles = files.map(async file => {
      const fileRoot = file.meta ? file.meta.root : ''
      let folderName = fileRoot.replace(currentPath, '')

      // windows does not like folders with leading /
      if (folderName[0] === '/') {
        folderName = folderName.substring(1)
      }

      await getFile({ name: file.name, path }, zip, folderName, writeToZip, handleError)
    })
    await Promise.all(allFiles)
  }

  /**
   *
   * directories : [
   *    {
   *        directories: Directory[],
   *        files : File[]
   *        meta : Meta
   *    }
   * ]
   *
   * recursively download all files within a directory
   *
   */
  const downloadFilesInDirectories = async (directories = [], currentPath = '', zip) => {
    const downloadAllFilesInCurrentDirectories = directories.map(async directory => {
      const isReadOnly = directory.meta?.readOnly || directory.meta?.pathReadOnly

      // don't download files that are marked as readyOnly
      if (!isReadOnly) {
        const files = directory.files
        const path = directory.files[0]?.meta.root
        const directories = directory.directories

        await downloadAllFiles(files, path, currentPath, zip)
        await downloadFilesInDirectories(directories, currentPath, zip)

        if (directories.length === 0 && files.length === 0) {
          handleError({ name: '', path: directory.files[0]?.meta.root || currentPath, error: DOWNLOAD_ERROR.NO_FILES })
        }
      } else {
        handleError({ name: '', path: directory.files[0]?.meta.root || currentPath, error: DOWNLOAD_ERROR.READ_ONLY })
      }
    })
    await Promise.all(downloadAllFilesInCurrentDirectories)
  }

  const downloadMultipleFiles = async (files, directories, path) => {
    const paths = path.split('/');
    const zipName = paths[paths.length-1];

    setCurrentPath(path)
    setZipname(zipName)
    setFilesToDownload([...files, ...directories])

    const zip = createZip()
    await downloadAllFiles(files, path, path, zip)
    await downloadFilesInDirectories(directories, path, zip)
    saveZip(zip, zipName)
  }

  return {
    downloading: isDownloadInProgress,
    downloadMultipleFiles,
    filesFailedToDownload,
    filesToDownload,
    retryDownload,
    clearErrors,
  }
}

export default useMultiDownload
