import gql from 'graphql-tag'
import useQuery from '../useQuery'

export const getPendingFilesQuery = gql`
    query PendingFiles {
        pendingFiles {
            name
            path
            meta {
                visible
            }
        }
    }
`

const useGetPendingFiles= ({ params, ...options } = {}) => {
  return useQuery(getPendingFilesQuery, {
    variables: { params: { ...params } },
    ...options,
  })
}

export default useGetPendingFiles
