import React from 'react'
import { Card } from 'antd'
import styled from 'styled-components'
import FundListContent from './FundListContent'
import LoadingOverlay from '../../../../ui/LoadingOverlay'

const SpacedCard = styled(Card)`
  margin-top: 20px;
  flex-basis: 60%;
`

const FundList = ({ data, loading }) => {
  return (
    <SpacedCard title="Funds accessible by user">
      <LoadingOverlay loading={loading}>
        <FundListContent data={data} loading={loading} />
      </LoadingOverlay>
    </SpacedCard>
  )
}


export default FundList