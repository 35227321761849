import React from 'react'
import styled from 'styled-components'
import Tooltip from '../../../Tooltip/Tooltip'

const Wrapper = styled.div`
  padding-bottom: 10px;
`
const Description = styled.div`
  font-size: 12px;
  line-height: 14px;
`
const UserLevelInfo = () => (
  <Tooltip text={
    <Wrapper>
      <div>
        Master
        <Description>
          Able to do everything plus assign moderator roles to users.
        </Description>
      </div>
      <div>
        Admin
        <Description>
        Can edit data and users (everything the master can do except assign moderator access)
        </Description>
      </div>
      <div>
        User Admin
        <Description>
          Can only add and remove users. This person will not be allowed to edit fund data.
        </Description>
      </div>
    </Wrapper>
  }/>
)

export default UserLevelInfo
