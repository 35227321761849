import React, { useState, useCallback, useEffect } from 'react'
import { xorBy, isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import useGetDirectoryParentGroups from 'src/apollo/query/groups/useGetDirectoryParentGroups'
import useGetGroups from 'src/apollo/query/groups/useGetGroups'
import GroupManager from '../../GroupManager/GroupManager'
import useAddGroupsDocument from '../../../../apollo/mutation/documents/useAddGroupsDocument'

export const NO_ACCESS_TEXT = 'Only administrators can view this folder'
export const ALL_PERMISSIONS_REMOVED_TEXT = 'All permissions will be removed'
export const REVERT_PERMISSIONS_TEXT = 'This folder will inherit parent permissions'
export const SAME_PERMISSIONS_TEXT = 'The selected permissions are the same as parent permission'

const getAvailableGroups = (allGroups, parentGroups, existingGroupIds, changes) => {
  let groups = allGroups

  if (parentGroups.length && existingGroupIds.length <= parentGroups.length) {
    groups = parentGroups
  }
  return groups.filter(group => !existingGroupIds.includes(group.uuid) && !changes.map(({ uuid }) => uuid).includes(group.uuid))
}

const getCurrentGroups = (allGroups, parentGroups, existingGroupIds, toRemove, showParentGroups) => {
  if (showParentGroups && !parentGroups.length) {
    return []
  }
  if (showParentGroups) {
    return getAvailableGroups(allGroups, parentGroups, existingGroupIds, toRemove)
  }
  return allGroups.filter(group => existingGroupIds.includes(group.uuid) && !toRemove.map(({ uuid }) => uuid).includes(group.uuid))
}

const getChanges = (toRemove, toAdd) => {
  return [
    ...toRemove.map(item => ({ ...item, toRemove: true })),
    ...toAdd.map(item => ({ ...item, toAdd: true })),
  ]
}

const getMessage = (parentGroups, existingGroupIds, changes, resulting) => {
  if (!existingGroupIds.length && parentGroups.length && changes.length && isEqual(parentGroups.sort(), resulting.sort())) {
    return SAME_PERMISSIONS_TEXT
  }
  if (!parentGroups.length && !existingGroupIds.length && !changes.length) {
    return NO_ACCESS_TEXT
  }
  if (changes.length && !resulting.length && parentGroups.length) {
    return REVERT_PERMISSIONS_TEXT
  }
  if (!!changes.length && !resulting.length) {
    return ALL_PERMISSIONS_REMOVED_TEXT
  }
}

const getReadOnlyMessage = (existingReadOnly, currentReadOnly) => {
  if(existingReadOnly!== currentReadOnly) {
    return `Downloads will be ${!currentReadOnly ? 'enabled' : 'disabled'} for all files in this directory and all subdirectories`
  }

  return null
}

const Permissions = ({ path, existingGroupIds, onChange, loading, open, toggle, readOnly, pathReadOnly }) => {
  const existingReadOnly = pathReadOnly || readOnly

  const [showParent, setShowParent] = useState(true)

  const showParentGroups = !existingGroupIds.length && !isEmpty(path) && showParent

  const { data: allGroupsData, loading: allGroupsLoading } = useGetGroups({
    fetchPolicy: 'network-only',
  })
  const { data: parentGroupsData, loading: parentGroupsLoading } = useGetDirectoryParentGroups({
    params: { path },
    skip: !path,
    fetchPolicy: 'network-only',
  })

  const parentGroups = parentGroupsData?.directoryParentGroups || []
  const allGroups = allGroupsData?.groups?.results || []

  const [toRemove, setToRemove] = useState([])
  const [toAdd, setToAdd] = useState([])
  const [currentReadOnly, setCurrentReadOnly] = useState(existingReadOnly)
  const readOnlyDisabled = pathReadOnly

  const changes = getChanges(toRemove, toAdd)

  const available = getAvailableGroups(allGroups, parentGroups, existingGroupIds, changes)

  const current = getCurrentGroups(allGroups, parentGroups, existingGroupIds, changes, showParentGroups)

  const resulting = [...current, ...toAdd]

  const resetState = useCallback(() => {
    setToRemove([])
    setToAdd([])
    setShowParent(true)
    setCurrentReadOnly(existingReadOnly)
  }, [existingReadOnly])

  const [saveGroups, { loading: saveLoading }] = useAddGroupsDocument({
    params: {
      path,
      groups: resulting.map(({ uuid }) => uuid),
      readOnly: currentReadOnly
    },
    onCompleted: () => {
      onChange()
    }
  })

  const message = getMessage(parentGroups, existingGroupIds, changes, resulting)
  const readOnlyMessage = getReadOnlyMessage(existingReadOnly, currentReadOnly)

  const toggleSelect = useCallback(selected => {
    const hasDuplicate = current.some(item => selected.includes(item))
    if (hasDuplicate) {
      setShowParent(false)
      setToRemove([])
      setToAdd([])
    }
    setToAdd(xorBy(toAdd, selected, 'uuid'))
  }, [current])

  const toggleRemove = useCallback(option => {
    setToRemove(xorBy(toRemove, [option], 'uuid'))
  }, [toRemove])

  const handleRevert = useCallback(option => {
    option.toRemove ? toggleRemove(option) : toggleSelect([option])
  })

  const handleSave = useCallback(() => {
    saveGroups()
  }, [resulting])

  useEffect(() => {
    if (!loading && !saveLoading) {
      resetState()
    }
  }, [loading, saveLoading])

  useEffect(() => {
    setCurrentReadOnly(pathReadOnly || readOnly)
  }, [pathReadOnly, readOnly])

  return (
    <GroupManager
      open={open}
      toggle={toggle}
      readOnlyDisabled={readOnlyDisabled}
      readOnly={currentReadOnly}
      readOnlyMessage={readOnlyMessage}
      toggleReadOnly={setCurrentReadOnly}
      buttonLoading={allGroupsLoading || parentGroupsLoading}
      loading={loading || allGroupsLoading || parentGroupsLoading || saveLoading}
      available={available}
      current={current}
      changes={changes}
      message={message}
      resulting={changes.length ? resulting : []}
      onRemove={toggleRemove}
      onRevert={handleRevert}
      onSelect={toggleSelect}
      onCancel={resetState}
      onSave={handleSave}/>
  )
}

Permissions.propTypes = {
  path: PropTypes.string,
  existingGroupIds: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  pathReadOnly: PropTypes.bool
}

Permissions.defaultProps = {
  path: '',
  existingGroupIds: [],
  onChange: () => null,
  loading: false,
  readOnly: false,
  pathReadOnly: false
}

export default Permissions
