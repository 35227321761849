import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../../../../../utils/constants/insights'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ date }) => {
  const showDate = dayjs(new Date(date)).format(DATE_FORMAT)
  return (
    <Wrapper>{showDate}</Wrapper>
  )
}
export default DateRenderer
