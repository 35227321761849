import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const deleteUserMutation = gql`
    mutation DeleteUser($params: DeleteUserInput!) {
        deleteUser(params: $params)
    }
`

const useDeleteUser = ({ params, ...options } = {}) => {
  return useMutation(deleteUserMutation, {
    variables: { params }, ...options,
  })
}

export default useDeleteUser
