import React from 'react'
import { Layout, Menu, Grid } from 'antd'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import auther from 'src/helpers/auth'
import AdminMenu from './AdminMenu'

const auth = auther()
const { Header, Content, Sider } = Layout
const { useBreakpoint } = Grid

const StyledHeader = styled(Header)`
  background: white;
  position: fixed;
  right:0;
  z-index: 1;
  width: 100%;
  padding-left: 80px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  .ant-menu-item {
    padding: 0 10px;
  }
`

const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 2;
`

const AdminLayout = ({ children }) => {
  const screens = useBreakpoint()
  if (isEmpty(screens)) {
    return null
  }
  const collapsed = !screens.lg
  const layoutStyle = {
    marginLeft: collapsed ? 80 : 264,
    minHeight: '100vh',
  }
  const contentStyle = {
    padding: collapsed ? '0 5px 5px' : '0 24px 24px',
    marginTop: 64,
  }
  return (
    <Layout>
      <StyledSider
        width={264}
        className="site-layout-background"
        breakpoint="lg"
        collapsedWidth="80"
        collapsed={collapsed}
      >
        <AdminMenu collapsed={collapsed}/>
      </StyledSider>
      <Layout style={layoutStyle}>
        <StyledHeader>
          <Menu mode="horizontal">
            <Menu.Item key="website" onClick={() => navigate('/')}>Website</Menu.Item>
            <Menu.Item key="portal" onClick={() => navigate('/portal')}>Portal</Menu.Item>
            <Menu.Item key="logout" onClick={() => auth.logout()}>Log Out</Menu.Item>
          </Menu>
        </StyledHeader>
        <Content className="site-layout-background" style={contentStyle}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout
