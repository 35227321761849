import React from 'react'
import { List, Tag } from 'antd'
import styled from 'styled-components'
import { CRM_NAME } from 'src/utils/constants/crm'
import ResponsiveTagList from '../../ResponsiveTagList'

const StyledTag = styled(Tag)`
  padding: 5px;
  font-size: 16px;
  flex: 1 1 30px;
  text-align: center;
  margin-right: 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const CrmCategoriesList = ({ id, data, loading, error }) => {

  if (!id || loading) {
    return null
  }

  if(error) {
    return `Failed to retrieve data from ${CRM_NAME}`
  }

  if(!data?.length) {
    return `There are no ${CRM_NAME} categories associated to this user`
  }

  return <ResponsiveTagList data={data} keySelector="id" textSelector="name" />
}

export default CrmCategoriesList
