import React from 'react'
import { Select } from 'antd'
import auther from 'src/helpers/auth'
import FormItem from '../../../ui/FormItem'
import UserLevelInfo from '../UserLevelInfo'
import { buildUserLevelOptions } from './helpers'

const auth = auther()

const UserLevelOptions = ({ user = {}, onChange }) => {
  const levelOptions = buildUserLevelOptions(user, auth)
  return (
    <FormItem label="User Level">
      <Select
        placeholder="Select User Level"
        name="level"
        value={user.level}
        suffixIcon={<UserLevelInfo/>}
        onChange={value => {
          onChange('level', value)
        }}>
        {levelOptions.map(({ name, value }) => {
          return <Select.Option key={value} value={value}>{name}</Select.Option>
        })}
      </Select>
    </FormItem>
  )
}

export default UserLevelOptions
