import {set, get} from 'lodash'

const transform = (object, path = 'root') => {
  return Object
    .entries(object)
    .map(([key, value], i) => {
      const currentPath = `${path}.${key}`
      return Object.assign(
        value.isLeaf ? { key: value.name + value.path, title: value.name, ...value } : { key: currentPath, title: key },
        value && typeof value === 'object' && !value.isLeaf
          ? { value: undefined, children: transform(value, currentPath) }
          : { value, children: [] }
      )
    })
}

export const buildTreeFromFileList = (files = []) => {
  if (!files.length) {
    return []
  }
  const resultObject = {}

  files.forEach(file => {
    const path = file?.path?.split('/').filter(item => !!item).join('.')
    set(resultObject, path, [...get(resultObject, path, []),  {
      title: file.name,
      isLeaf:true,
      key: `${file.path}---${file.name}`,
      ...file
    }])
  })

  const result = transform(resultObject)

  return [{title: 'Root', key: 'root', children: result}]
}