import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSpin = styled(Spin)`
  width: 100%;
  padding: 30px;
`

const Loading = ({ loading, children }) => {
  return loading ? <StyledSpin spinning={true} /> : children
}

Loading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
}

Loading.defaultProps = {
  loading: false,
  children: null
}

export default Loading
