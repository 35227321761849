import gql from 'graphql-tag'
import useMutation from '../useMutation'
import { userFieldsFragment } from '../../fragments/user'

export const approveUserMutation = gql`
    mutation ApproveUserRequest($params: CreateUserInput!) {
        approveUserRequest(params: $params){
            ...userFields
        }
    }
    ${userFieldsFragment}
`

const useApproveUserRequest = ({ params, ...options } = {}) => {
    return useMutation(approveUserMutation, {
        variables: { params }, ...options,
    })
}

export default useApproveUserRequest
