import React from 'react'
import PropTypes from 'prop-types'

const ActionsRenderer = ({ actionsRenderer: Component, ...props }) => {
  return <Component {...props}/>
}

ActionsRenderer.propTypes = {
  actionsRenderer: PropTypes.element.isRequired,
}

export default ActionsRenderer
