import React, { useEffect, useState } from 'react'
import useGetFunds from 'src/apollo/query/funds/useGetFunds'
import { PlusOutlined } from '@ant-design/icons'
import { Card, Input, Col } from 'antd'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import PageHeader from '../../../ui/PageHeader'
import Error from '../../../ui/Error'
import Table from '../../../Table/Table'
import ActionButtons from '../../../ActionButtons/ActionButtons'
import { fundListColumns } from './columns'

const Search = styled(Input.Search)`
  margin-right: 20px;

  .ant-input-affix-wrapper {
    height: 36px;
  }

  .ant-input-search-button {
    bottom: 1px;
  }
`

const StyledTable = styled(Table)`
  .warning {
    color: red;
  }
`

const PAGE_SIZE = 10
const START_PAGE = 1
const FundList = () => {
  const [page, setPage] = useState(START_PAGE)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(PAGE_SIZE)
  const { data, loading, error } = useGetFunds({
    params: {
      page,
      limit,
      filter: { search },
      overridePermissions: true,
    },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    setPage(START_PAGE)
  }, [search, limit])
  const funds = data?.funds?.results || []
  const total = data?.funds?.total

  if (error) return <Error />

  const actions = [
    {
      onClick: () => navigate(`/portal/admin/funds/create`),
      children: 'Create Fund',
      icon: <PlusOutlined />,
    },
  ]

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title="Funds" />
      <ActionButtons actions={actions} />
      <Card extra={<Search onSearch={setSearch} placeholder="Search..." allowClear />}>
        <StyledTable
          loading={loading}
          pagination={{
            current: page,
            total,
            pageSize: limit,
            onChange: page => {
              setPage(page)
            },
            onPageSizeChange: pageSize => {
              setLimit(pageSize)
            },
          }}
          rowClassName={record => {
            if (record?.warnings?.length) {
              return 'warning'
            }
          }}
          scroll={{ x: 400 }}
          columns={fundListColumns}
          dataSource={funds}
        />
      </Card>
    </Col>
  )
}

export default FundList
