import {
  ActionsRenderer,
  ChangesActionsRenderer,
  DateRenderer,
  PreviewStatusRenderer,
  StatusRenderer
} from './renderers'

export const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Date',
    render: DateRenderer,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    render: StatusRenderer,
  },
  {
    title: 'Actions',
    align: 'right',
    render: ActionsRenderer,
  },
]

export const changesColumns = [
  {
    dataIndex: 'title',
    key: 'title',
  },
  {
    render: PreviewStatusRenderer,
  },
  {
    align: 'right',
    render: ChangesActionsRenderer,
  },
]
