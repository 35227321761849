import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'

export const getDirectoryTree = (list, key, children) => {
  return list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      }
    } else if (node.children) {
      return {
        ...node,
        children: getDirectoryTree(node.children, key, children),
      }
    }
    return node
  })
}

export const sortBySelectedDirectories = (directories = [], selectedDirectoryPaths = []) => {
  const first = []
  const last = []
  // add removed directories missing from documents response first
  selectedDirectoryPaths.forEach(name => {
    if (!directories.find(item => item.key === name)) {
      first.push({
        title: name,
        key: name,
        isLeaf: true,
        deleted: true,
        style: { color: 'red' },
        icon: <DeleteOutlined />,
      })
    }
  })
  directories.forEach(item => {
    if (selectedDirectoryPaths.includes(item.key)) {
      first.push(item)
    } else {
      last.push(item)
    }
  })
  return [...first, ...last]
}
