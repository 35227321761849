import React from 'react'
import { Modal } from 'antd'
import ImageUpload from '../../../../../ImageUpload/ImageUpload'

const InsertImage = ({ open, toggle, onInsert }) => {
  const [imageSrc, setImageSrc] = React.useState(null)
  const handleOk = () => {
    onInsert(imageSrc)
    toggle(false)
    setImageSrc(null)
  }
  const handleCancel = () => {
    toggle(false)
    setImageSrc(null)
  }
  return (
    <Modal visible={open} onOk={handleOk} onCancel={handleCancel} closable={false}>
      <ImageUpload value={imageSrc} onChange={setImageSrc} crop={true} aspect={1} />
    </Modal>
  )
}

export default InsertImage
