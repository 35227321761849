import React from 'react'
import styled from 'styled-components'
import { List } from 'antd'
import { hasFootnoteRef } from './helpers'
import FootnoteListItem from './FootnoteListItem'

const StyledList = styled(List)`
  border-top: none;
`

const FootnoteList = ({ text, footnotes, onInsert, onDelete, onChange }) => {
  return (
    <StyledList
      bordered
      dataSource={footnotes}
      locale={{ emptyText: 'No footnotes' }}
      renderItem={(item, index) => (
        <FootnoteListItem
          item={item}
          index={index}
          onChange={onChange}
          onInsert={!hasFootnoteRef(text, index) && onInsert}
          onDelete={index === footnotes.length - 1 && onDelete}/>
      )}
    />
  )
}

export default FootnoteList
