import React, {useCallback, useEffect, useMemo, useState} from 'react'
import SimpleMDE from 'react-simplemde-editor'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FootnoteList from './Footnotes/FootnoteList'
import { addFootnotesToValue } from './Footnotes/helpers'
import useMarkdownInstance from './useMarkdownInstance'
import useFootnotes from './Footnotes/useFootnotes'
import InsertImage from './Images/InsertImage'
import useImages from './Images/useImages'

import 'easymde/dist/easymde.min.css'

const MarkdownWrapper = styled.div`
  .CodeMirror-scroll {
    max-height: 500px;
  }
`

export const TOOLBAR_ICONS = ['heading-2', 'bold', '|', 'link', 'unordered-list', 'ordered-list']

const MarkdownEditor = ({ value, onChange }) => {
  const [setInstance, { codemirrorInstance }] = useMarkdownInstance()

  const {
    text,
    footnotes,
    addFootnote,
    removeFootnote,
    updateFootnote,
  } = useFootnotes(value, codemirrorInstance, onChange)

  const {
    showUpload,
    setShowUpload,
    addImage,
  } = useImages(codemirrorInstance)

  const handleChange = value => {
    onChange(addFootnotesToValue(value, footnotes))
  }

  const toolbar = [
    ...TOOLBAR_ICONS,
    {
      name: 'Add footnote',
      action: addFootnote,
      className: 'fa fa-asterisk',
      title: 'Add footnote',
    },
    {
      name: 'Add image',
      action: () => setShowUpload(true),
      className: 'fa fa-image',
      title: 'Add image',
    },
  ]

  return (
    <>
      <MarkdownWrapper>
        <SimpleMDE
          getMdeInstance={setInstance}
          id="body-markdown"
          onChange={handleChange}
          value={text}
          options={{
            autofocus: false,
            toolbar,
            status: false,
            spellChecker: false,
            maxHeight: '300px',
            forceSync: true,
          }}
        />
      </MarkdownWrapper>
      <FootnoteList text={text} footnotes={footnotes} onInsert={addFootnote} onDelete={removeFootnote} onChange={updateFootnote} />
      <InsertImage open={showUpload} toggle={setShowUpload} onInsert={addImage} />
    </>
  )
}

MarkdownEditor.propTypes = {
  value: PropTypes.string,
}

MarkdownEditor.defaultProps = {
  value: '',
}

export default MarkdownEditor
