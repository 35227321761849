import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { tagFieldsFragment, tagsPaginationFragment } from '../../fragments/tag'

export const tagListQuery = gql`
    query TagList($params: TagListInput!) {
        tags(params: $params) {
            results {
              ...tagFields
            }
            ...tagsPagination
        }
    }
    ${tagFieldsFragment}
    ${tagsPaginationFragment}
`

const defaultOrder = [{ order: 'name', orderDirection: 'ASC' }]

const useGetTags = ({ params: paramsInput = {}, ...options } = {}) => {
  const { order } = paramsInput
  const params = {
    ...paramsInput,
    order: order || defaultOrder,
  }

  return useQuery(tagListQuery, {
    variables: { params }, ...options,
  })
}

export default useGetTags
