import React from 'react'
import { Card, Statistic } from 'antd'
import styled from 'styled-components'

const StyledStatistic = styled(Statistic)`
 ${({hasChildren}) => hasChildren && (
   `
    .ant-statistic-content {
      line-height: 24px;
    }
   `
)}
`

const StyledCard = styled(Card)`
  .ant-card-body {
    min-height: 143px;
  }
`

const StatisticCard = ({title, value, precision, loading, children}) => {
  return  (
    <StyledCard size="default" loading={loading}>
      <StyledStatistic hasChildren={!!children} title={title} value={value} precision={precision} />
      {children}
    </StyledCard>
  )
}

StatisticCard.defaultProps = {
  loading: false,
  precision: 0,
}

export default StatisticCard