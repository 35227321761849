import gql from 'graphql-tag'

export const videoFieldsFragment = gql`
    fragment videoFields on Video {
        uuid
        name
        status
        streamingUrl
        createdAt
    }
`
