import React from 'react'
import { Col } from 'antd'
import PageHeader from '../../ui/PageHeader'
import CreateVideo from './CreateVideo'
import VideosList from './VideosList'

const Videos = () => {
  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Videos' />
      <CreateVideo/>
      <VideosList/>
    </Col>
  )
}

export default Videos
