import * as React from 'react'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import Row from '../../../Row/Row'
import { IconYellowArrow } from '../../../../data/icons'
import logo from '../../../../data/images/sagura.svg'
import Button from '../../../Button/Button'
import Chart from '../Chart/Chart'
import { getImageSource } from '../../../../utils/helpers/image'
import * as styles from './Fund.module.scss'
import * as stylesAdditonalPerformance from './AdditonalPerformance.module.scss'
import AdditonalPerformance from './AdditonalPerformance'

export default ({ name, path, headline, monthlyReturn, ytdReturn, image, nav, date, performance = [], additionalPerformance, disclaimer }) => {
  const [hovering, setHovering] = React.useState(false)
  let arrowColor = styles.colorLightGray
  if (hovering) {
    arrowColor = styles.colorDarkGold
  }

  const hasAddPerformance = additionalPerformance && Object.keys(additionalPerformance).length
  const id = `Fund`
  return (
    <div>
      <div className={styles.headerContainer}>
        <img className={styles.backgroundImage} src={getImageSource(image)} />
        <Row>
          <div
            className={styles.backArrow}
            onClick={() => navigate('/portal')}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            <IconYellowArrow direction={'left'} color={arrowColor} />
          </div>
          <div className={styles.fundLogoContainer}>
            <img className={styles.fundLogo} src={logo} />
          </div>
          <h1 className={styles.title} id={`${id}-name`}>{name}</h1>
          <h2 className={styles.subtitle} id={`${id}-headline`}>{headline}</h2>
          <div className={styles.documents}>
            <Button
              id={`${id}-documents`}
              to={`/portal/documents/${path}`}
              classNames={styles.documentButton}>
              Documents
            </Button>
          </div>
        </Row>
        {!!monthlyReturn && (
          <Row inlineRow className={styles.row} id={`${id}-returns`}>
            <div className={styles.col}>
              <div className={styles.header}>MONTHLY RETURN (%)</div>
              <div className={styles.value} id={`${id}-monthlyReturn`}>{monthlyReturn.toFixed(2)}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.header}>YTD RETURN (%)</div>
              <div className={styles.value} id={`${id}-ytdReturn`}>{(ytdReturn || 0).toFixed(2)}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.header}>NAV (USD million)</div>
              <div className={styles.value} id={`${id}-nav`}>{nav}</div>
            </div>
          </Row>
        )}
        {!!monthlyReturn && (
          <Row>
            <div className={styles.date} id={`${id}-date`}>Data as at {dayjs(date).format('DD MMM YYYY')}</div>
          </Row>
        )}
      </div>
      {!!performance && !!performance.length && (
        <div id={`${id}-performance`} className={styles.performanceRow}>
          <Row>
            <h1 className={styles.performanceHeader}>Performance since inception</h1>
            <Chart performance={performance} xAxis={name} />
          </Row>
        </div>
      )}
      {hasAddPerformance && <div className={styles.perforamnceDataRow}>
        <Row id={`${id}-additionalPerformance`}>
          <AdditonalPerformance additionalPerformance={additionalPerformance} />
        </Row>
      </div>}
      {!!disclaimer && (
        <Row>
          <p className={stylesAdditonalPerformance.estimated}>The most recent month’s data may be estimated by Emso.</p>
          <p className={stylesAdditonalPerformance.disclaimer}>
            {disclaimer}
          </p>
        </Row>
      )}
    </div>
  )
}
