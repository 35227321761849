import ActionsRenderer from './renderers/ActionsRenderer'

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Actions',
    align: 'right',
    render: ActionsRenderer,
  },
]
