import React, { useContext } from 'react'
import auther from 'src/helpers/auth'
import ActionButtons from '../../../ActionButtons/ActionButtons'
import LogViewDrawer from '../../../LogViewer/LogViewDrawer'
import { USER_SUBJECT_TYPE } from '../../../LogViewer/constants'
import UserEditContext from './UserEditContext'
import { canUserEditThisUser } from './helpers'

const auth = auther()

const Actions = () => {
  const {
    isCreate,
    user,
    update,
    updateState,
    resetPassword,
    resetPasswordState,
    create,
    createState,
    disable,
    promote,
    enableUser,
    setPermissionsOpen,
    permissionsLoading,
    getState,
    deleteUser,
    deleteState,
    resetUserMultiFactor,
    resetUserMultiFactorState,
  } = useContext(UserEditContext)

  const canUserEdit = isCreate || canUserEditThisUser(user, auth)
  const showActions = canUserEdit && !getState.loading

  const actions = []

  if (isCreate) {
    actions.push({
      children: 'Create user',
      onClick: create,
      loading: createState.loading || updateState.loading,
    })
  }

  if (user.pending) {
    actions.push(
      {
        children: 'Promote to active user',
        onClick: promote,
        loading: updateState.loading,
      },
      {
        children: 'Disable user',
        onClick: disable,
        loading: updateState.loading,
      },
      {
        children: 'Delete user',
        onClick: deleteUser,
        loading: deleteState.loading,
      },
    )
  }

  if (!isCreate && !user.pending) {
    if (user.level === 0) {
      actions.push({
        children: 'Enable user',
        onClick: enableUser,
        loading: updateState.loading,
      })
    }

    actions.push(
      ...[
        {
          children: 'Save User',
          onClick: update,
          loading: updateState.loading || resetPasswordState.loading,
        },
        {
          children: 'Permissions',
          onClick: () => setPermissionsOpen(true),
          disabled: permissionsLoading || updateState.loading || resetPasswordState.loading,
        },
        {
          children: 'Reset password',
          onClick: resetPassword,
        },
        {
          children: 'Reset 2FA',
          onClick: resetUserMultiFactor,
          loading: resetUserMultiFactorState.loading,
        },
      ],
    )

    if (user.level > 0) {
      actions.push({
        children: 'Disable user',
        onClick: disable,
      })
    }

    actions.push({
      children: (
        <LogViewDrawer subjectId={user.id} subjectType={USER_SUBJECT_TYPE}>
          <span>Activity Log</span>
        </LogViewDrawer>
      ),
    })
  }

  return <ActionButtons actions={showActions ? actions : []} />
}

export default Actions
