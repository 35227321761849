import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const setFileVisibilityMutation = gql`
    mutation SetFileVisibility($params: SetFileVisibilityInput!) {
        setFileVisibility(params: $params)
    }
`

const useSetFileVisibility = ({ params, ...options } = {}) => {
  return useMutation(setFileVisibilityMutation, {
    variables: { params }, ...options,
  })
}

export default useSetFileVisibility
