import React, { useState } from 'react'
import CSVReader from 'react-csv-reader'
import styled from 'styled-components'
import { InboxOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
  border: 1px dashed #d9d9d9;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  input {
    display: none;
  }
  :hover {
    border-color: #5f6894;
  }
`

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 30px;
`

const CsvUpload = ({ onError, onChange, ...props }) => {
  const [name, setName] = useState()
  const handleChange = (file, { name }) => {
    onChange(file)
    setName(name)
    return false
  }
  return (
    <>
      <Wrapper>
        <Label htmlFor="uploadCsvInput">
          <InboxOutlined style={{ color: '#5f6894', fontSize: 20, marginRight: 5 }} />
          {name || 'Select CSV file'}
        </Label>
        <CSVReader inputId="uploadCsvInput" onFileLoaded={handleChange} onError={onError} {...props} />
      </Wrapper>
    </>
  )
}

export default CsvUpload
