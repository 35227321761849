import React from 'react'
import useSetFileVisibility from 'src/apollo/mutation/documents/useSetFileVisibility'
import { xorBy } from 'lodash'

const useFilesVisibility = ({ path, files = [], onUpdateCompleted = () => {} }) => {
  const [filesToUpdate, setFilesToUpdate] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [setFileVisibility] = useSetFileVisibility({})

  React.useEffect(() => {
    setFilesToUpdate([])
  }, [path])

  const handleFileVisibilityChange = (name, visible, path) => {
    setFilesToUpdate(xorBy(filesToUpdate, [{ name, visible, path }], 'name'))
  }

  const isFileVisible = name => {
    const updatedFile = filesToUpdate.find(item => item.name === name)
    if (updatedFile) {
      return updatedFile.visible
    }
    return files.find(item => item.name === name)?.meta?.visible
  }

  const save = async () => {
    setLoading(true)
    for (let { name, visible, path: filePath } of filesToUpdate) {
      await setFileVisibility({
        variables: {
          params: {
            file: name,
            visible,
            path: filePath,
          },
        },
      })
    }
    setLoading(false)
    await onUpdateCompleted()
    setFilesToUpdate([])
  }

  const allFilesChecked = !files.some(file => !isFileVisible(file.name))

  const toggleAllFiles = () => {
    if (allFilesChecked) {
      setFilesToUpdate(xorBy(filesToUpdate, files.map(item => ({ name: item.name, path: item.path, visible: false })), 'name'))
    } else {
      const uncheckedFiles = files.filter(item => !isFileVisible(item.name))
      setFilesToUpdate(uncheckedFiles.map(item => ({ name: item.name, path: item.path, visible: true })))
    }
  }

  const uncheckAllFiles = () => {
    setFilesToUpdate([])
  }

  return {
    filesToUpdate,
    setFilesToUpdate,
    handleFileVisibilityChange,
    isFileVisible,
    save,
    loading,
    allFilesChecked,
    toggleAllFiles,
    confirmOpen,
    setConfirmOpen,
    uncheckAllFiles,
  }
}

export default useFilesVisibility
