import React from 'react'
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer'
import { VIDEO_STATUS_FINISHED, VIDEO_STATUS_PENDING } from '../constants'

const PreviewRenderer = ({ streamingUrl, status }) => {
  const loading = status === VIDEO_STATUS_PENDING
  const finished = status === VIDEO_STATUS_FINISHED
  const error = !loading && !finished

  return (
    <VideoPlayer
      width={240}
      height={136}
      src={streamingUrl}
      controls={!!streamingUrl}
      loading={loading}
      error={error}
      suppressNotSupportedError
      notSupportedMessage="Video processing failed"
    />
  )
}
export default PreviewRenderer
