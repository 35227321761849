// Do not import chartist while server side rendering
const Chartist = typeof window !== 'undefined' ? require('chartist') : { Interpolation: { simple: function() {}, }};

export function transformGraphData(points = []) {
  if (points.length === 0) {
    return {
      data: [],
      graphHasData: false,
    }
  }

  if (points.length < 11) {
    return {
      data: [],
      graphHasData: false,
    }
  }

  let maxValue = points[0] ? parseInt(points[0].value) : 0
  let minValue = points[0] ? parseInt(points[0].value) : 0
  let maxYear = points[0] ? points[0].year : 0
  let minYear = points[0] ? points[0].year : 0
  let data = []
  let ticksY = []
  let ticksX = []
  let lowX

  points.sort(function(a, b) {
    return new Date(a.year, a.month, 1).getTime() - new Date(b.year, b.month, 1).getTime()
  })

  for (let point of points) {
    if (parseInt(point.value) < minValue) {
      minValue = parseInt(point.value)
    }

    if (point.year < minYear) {
      minYear = parseInt(point.year)
    }

    if (parseInt(point.value) > maxValue) {
      maxValue = parseInt(point.value)
    }

    if (point.year > maxYear) {
      maxYear = parseInt(point.year)
    }

    data.push({ x: new Date(point.year, point.month, 1), y: parseInt(point.value) })
  }

  for (let i = minYear; i < maxYear + 1; i++) {
    if (i === minYear) {
      ticksX.push(new Date(i, 0, 1))
    }
    if (i === maxYear) ticksX.push(new Date(i, 0, 1))
    if (i !== minYear && i !== maxYear) ticksX.push(new Date(i, 0, 1))
  }

  if (maxYear - minYear === 1) {
    ticksX = [new Date(points[0].year, 0, 1), new Date(points[points.length - 1].year, 0, 1)]
  }

  lowX = ticksX[0]

  let tickTotal = 6
  let tickSizeRounded
  let tickStart = 1
  let lowY = 0

  if (maxValue < 200) {
    tickTotal = maxValue / 10
    tickSizeRounded = 10
    tickStart = Math.floor(minValue / 10)
    tickStart = tickStart - 1
    lowY = tickStart * 10
    if (tickStart < 1) tickStart = 1
  }

  if (maxValue > 199 && maxValue < 500) {
    tickTotal = maxValue / 50
    tickSizeRounded = 50
    if (minValue > 100) tickStart = 4
  }

  if (maxValue > 499) {
    tickTotal = maxValue / 100
    tickSizeRounded = 100
  }

  if (maxValue > 499) {
    tickTotal = maxValue / 100
    tickSizeRounded = 100
    if (minValue > 100) tickStart = 1
  }

  for (let i = tickStart; i < Math.ceil(tickTotal) + 1; i++) {
    ticksY.push(i * tickSizeRounded)
  }

  return {
    ticksX,
    ticksY,
    lowY,
    lowX,
    maxValue,
    minValue,
    data,
  }
}

export function createdChart(c) {
  const defs = new Chartist.Svg('defs')
  const gradient = defs.elem('linearGradient', { id: 'gradient', gradientUnits: 'userSpaceOnUse', x: '0', y: '0', x2: '0', y2: '300' })
  const stops = [
    { offset: '0%', stopColor: '#10255c' },
    { offset: '60%', stopColor: '#fff' },
    { offset: '85%', stopColor: '#10255c' },
    { offset: '100%', stopColor: '#10255c' },
  ]

  for (let i = 0, len = stops.length; i < len; i++) {
    let stop = stops[i]
    stop = gradient.elem('stop', { offset: stop.offset, 'stop-color': stop.stopColor })
  }
  c.svg.append(defs, true)

  let labels = document.getElementsByClassName('ct-horizontal')

  for (let i in labels) {
    if (labels[i].getAttribute && labels[i].getAttribute('x')) {
    }
  }

  labels = document.getElementsByClassName('ct-vertical')

  for (let i in labels) {
    if (labels[i].getAttribute && labels[i].getAttribute('x')) {
      labels[i].setAttribute('x', parseInt(labels[i].getAttribute('x') - 10))
    }
  }
}

export function getChartOptions(lowX, lowY, ticksX, ticksY) {
  return {
    fullWidth: true,
    height: 300,
    showPoint: false,
    lineSmooth: Chartist.Interpolation.simple(),
    responsive: true,
    axisY: {
      type: Chartist.FixedScaleAxis,
      low: lowY || 0,
      high: ticksY[ticksY.length - 1],
      ticks: ticksY,
      offset: 50
    },
    axisX: {
      type: Chartist.FixedScaleAxis,
      onlyInteger: true,
      ticks: ticksX,
      stretch: true,
      low: lowX.valueOf(),
      labelInterpolationFnc: value => {
        return new Date(value).getFullYear()
      },
    },
  }
}

export function calculateResponsiveTicks(array = []){
  const length = array.length
  const max = length%2===0 ? 6 : 5
  if(length <= max) {
    return array
  }
  let remainingPoints = max - 2
  const minDifference = Math.ceil(length/max)
  let lastIndex = 0
  const ticks = Array(length).fill("")
  return ticks.map((item, i) => {
    if(i===0 || i=== length -1) {
      return array[i]
    }

    if(lastIndex + minDifference <= i && i<length - minDifference && remainingPoints){
      lastIndex = i
      remainingPoints--
      return array[i]
    }

    return ""
  })
}
