import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import Mutation from '../Mutation'
import { reportFieldsFragment } from '../../fragments/report'
import { REPORT_TYPES } from '../../../utils/constants/reports'

const mutation = gql`
    mutation CreateReport($params: ReportCreateInput!) {
        createReport(params: $params){
            ...reportFields
        }
    }
  ${reportFieldsFragment}
`

const CreateReport = ({ children, type, ...props }) => {
  const params = { type }
  return (
    <Mutation mutation={mutation} {...props} variables={{ params }}>
      {(mutate, state) => {
        return children(mutate, state)
      }}
    </Mutation>
  )
}

CreateReport.propTypes = {
  children: PropTypes.func.isRequired,
  type: PropTypes.oneOf(REPORT_TYPES).isRequired,
}

export default CreateReport
