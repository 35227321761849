import React from 'react'
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import { navigate } from 'gatsby-link'

const ActionsRenderer = ({ uuid }) => (
  <Button onClick={() => navigate(`/portal/admin/insights/edit/${uuid}`)} icon={<EditOutlined />}> Edit </Button>
)

export default ActionsRenderer
