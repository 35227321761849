import * as React from 'react'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import Row from '../../../Row/Row'
import Col from '../../../Col/Col'
import { IconYellowArrow } from '../../../../data/icons'
import logo from '../../../../data/images/sagura.svg'
import * as styles from './FundRow.module.scss'
import { getImageSource } from '../../../../utils/helpers/image'

export default ({ name, date, monthlyReturn, ytdReturn, nav, path, image, totalCount }) => {
  const [hovering, setHovering] = React.useState(false)
  let arrowColor = styles.colorLightGray
  if (hovering) {
    arrowColor = styles.colorDarkGold
  }
  return (
    <Row id={`FundRow-${path}`}
         inlineRow
         className={classNames({
           [styles.row]: true,
           [styles.rowSmall]: totalCount > 5
         })}
         onClick={() => navigate(`/portal/fund/${path}`)}
         onTouchStart={() => setHovering(true)}
         onTouchEnd={() => setHovering(false)}
         onMouseEnter={() => setHovering(true)}
         onMouseLeave={() => setHovering(false)}>
      <div className={styles.background} style={{ backgroundImage: `url(${getImageSource(image)})` }} />
      <Row inlineRow className={styles.wrapper}>
        <div className={styles.logoName}>
          <div className={styles.logo}>
            <img src={logo} />
          </div>
          <div className={styles.nameDate}>
            <div className={classNames({
              [styles.name]: true,
              [styles.yellowColor]: hovering
            })} id={`FundRow-${path}-name`}>
              {name}
            </div>
            {!!monthlyReturn && (
              <div className={styles.date} id={`FundRow-${path}-date`}>
                Data as at {dayjs(date).format('DD MMM YYYY')}
              </div>
            )}
          </div>
        </div>
        {!!monthlyReturn && (
          <>
            <div className={styles.mobileHide}>
              <div className={styles.header}>
                MONTHLY RETURN (%)
              </div>
              <div className={styles.value} id={`FundRow-${path}-monthlyReturn`}>
                {monthlyReturn.toFixed(2)}
              </div>
            </div>
            <div className={styles.mobileHide}>
              <div className={styles.header}>
                YTD RETURN (%)
              </div>
              <div className={styles.value} id={`FundRow-${path}-ytdReturn`}>
                {(ytdReturn || 0).toFixed(2)}
              </div>
            </div>
            <div className={styles.mobileHide}>
              <div className={styles.header}>
                NAV (USD million)
              </div>
              <div className={styles.value} id={`FundRow-${path}-nav`}>
                {nav}
              </div>
            </div>
          </>
        )}
        <Col>
          <div className={styles.arrow}>
            <IconYellowArrow direction={'right'} color={arrowColor} />
          </div>
        </Col>
      </Row>
    </Row>
  )
}
