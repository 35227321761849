import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { DATE_FORMAT } from 'src/utils/constants/insights'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ date }) => (
  <Wrapper>{moment(date).format(DATE_FORMAT)}</Wrapper>
)
export default DateRenderer
