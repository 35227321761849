import React from 'react'
import { Tag } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Title = styled.div`
  margin-top:20px;
`

const StyledTag = styled(Tag)`
  padding: 5px;
  font-size: 16px;
`

const getColor = (group) => {
  if (group.toRemove) return 'red'
  if (group.toAdd) return 'green'
  return undefined
}

const GroupTagList = ({ title, list, onClose, ...props }) => {
  if (!list.length) {
    return null
  }
  return (
    <span id={`GroupTagList-${title.replace(' ', '')}`}>
      <Title>{title}</Title>
      {list.map(group => {
        const color = getColor(group)
        return <StyledTag
          id={`GroupTagList-Tag-${group.uuid}`}
          color={color}
          key={group.uuid}
          visible={true}
          closable={!!onClose}
          onClose={() => onClose(group)}
          {...props}>
          {group.name}
        </StyledTag>
      })}
      <hr/>
    </span>
  )
}

GroupTagList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  onClose: PropTypes.func,
}

GroupTagList.defaultProps = {
  title: '',
  list: [],
  onClose: () => null,
}

export default GroupTagList
