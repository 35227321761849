import React from 'react'
import { Router, Redirect } from '@reach/router'
import Fund from '../../components/Login/Funds/FundPage/FundPage'
import PrivateRoute from '../../components/Login/PrivateRoute'
import DocumentsPage from '../../components/Login/Documents/DocumentsPage'
import Documents from '../../components/Admin/Dashboard/Documents/Documents'
import Requests from '../../components/Admin/Dashboard/Requests/Requests'
import Edit from '../../components/Admin/Dashboard/Edit'
import AdminRoute from '../../components/Admin/AdminRoute'
import Users from '../../components/Admin/Dashboard/Users/Users'
import AnalyticsDashboard from '../../components/Admin/Dashboard/Analytics/Dashboard'
import UsersEdit from '../../components/Admin/Dashboard/Users/Edit/UserEdit'
import Groups from '../../components/Admin/Dashboard/Groups/Groups'
import GroupsEdit from '../../components/Admin/Dashboard/Groups/Edit'
import notFound from '../../components/Login/NotFound/NotFound'
import Export from '../../components/Admin/Dashboard/Export/Export'
import { InsightsEdit, InsightsList } from '../../components/Admin/Dashboard/Insights'
import Dashboard from '../../components/Login/Dashboard/DashboardController'
import { ReportList } from '../../components/Admin/Dashboard/Reports'
import { FundsList, FundsEdit } from '../../components/Admin/Dashboard/Funds'
import { PeopleList, PersonEdit } from '../../components/Admin/Dashboard/Team'
import TeamPage from 'src/components/Login/Team/TeamPage'
import TeamMember from '../../components/Login/Team/Member/Member'
import Videos from '../../components/Admin/Dashboard/Videos/Videos'
import BulkUploadData from '../../components/Admin/Dashboard/Funds/BulkUploadData/BulkUploadData'
import DocumentsPending from '../../components/Admin/Dashboard/Documents/DocumentsPending'
import Warning from '../../components/Admin/Dashboard/Funds/Warning/Warning'

const Portal = () => {
  return (
    <Router>
      <PrivateRoute component={notFound} default />

      <PrivateRoute component={Dashboard} path="/portal" />
      <PrivateRoute component={Fund} path="/portal/fund/:fundPath" />
      <PrivateRoute component={DocumentsPage} path="/portal/documents" />
      <PrivateRoute component={DocumentsPage} path="/portal/documents/:fundPath" />
      <PrivateRoute component={TeamPage} path="/portal/team" />
      <PrivateRoute component={TeamMember} path="/portal/people/:uuid" />

      <AdminRoute path="/portal/admin/dashboard" component={AnalyticsDashboard} />
      <AdminRoute path="/portal/admin/funds" component={FundsList} />
      <AdminRoute path="/portal/admin/funds/:fundPath" component={FundsEdit} />
      <AdminRoute component={BulkUploadData} path="/portal/admin/funds/bulk-upload-data" />
      <AdminRoute component={Warning} path="/portal/admin/funds/warning" />
      <AdminRoute path="/portal/admin/export" component={Export} />
      <AdminRoute path="/portal/admin/documents" component={Documents} />
      <AdminRoute path="/portal/admin/documents/pending" component={DocumentsPending} />
      <AdminRoute path="/portal/admin/requests" component={Requests} />
      <AdminRoute path="/portal/admin/edit/:file" component={Edit} />
      <AdminRoute path="/portal/admin/users" component={Users} />
      <AdminRoute path="/portal/admin/users/:userId" component={UsersEdit} />
      <AdminRoute path="/portal/admin/groups" component={Groups} />
      <AdminRoute path="/portal/admin/groups/edit/:groupId" component={GroupsEdit} />
      <AdminRoute path="/portal/admin/groups/add" component={GroupsEdit} />
      <AdminRoute path="/portal/admin/insights" component={InsightsList} />
      <AdminRoute path="/portal/admin/reports" component={ReportList} />
      <AdminRoute path="/portal/admin/insights/edit/:uuid" component={InsightsEdit} />
      <AdminRoute path="/portal/admin/team" component={PeopleList} />
      <AdminRoute path="/portal/admin/people/edit/:uuid" component={PersonEdit} />
      <AdminRoute path="/portal/admin/videos" component={Videos} />
      <Redirect from="/portal/admin/" to="/portal/admin/dashboard" default noThrow />
    </Router>
  )
}

export default Portal;
