import React, { useState } from 'react'
import { DeleteOutlined, PlusOutlined, EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { Button, List, Input } from 'antd'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  width: 70%;
`
const StyledListItem = styled(List.Item)`
  display: flex;
`

const FootnoteListItem = ({ item, onDelete, onChange, onInsert, index }) => {
  const [text, setText] = useState()
  const [isEdit, setIsEdit] = useState(false)

  const handleSetEdit = () => {
    setText(item)
    setIsEdit(true)
  }
  const handleSave = () => {
    onChange(text, index)
    setIsEdit(false)
    setText(null)
  }

  const handleCancel = () => {
    setIsEdit(false)
    setText(null)
  }

  const actions = []

  if (onDelete && isEdit) {
    actions.push({ icon: <DeleteOutlined />, action: () => onDelete(index), type: 'danger' })
  }

  if (!isEdit && onInsert) {
    actions.push({ icon: <PlusOutlined />, action: () => onInsert(null, index), type: 'primary' })
  }

  if (!isEdit) {
    actions.push({ icon: <EditOutlined />, action: handleSetEdit })
  }

  if (isEdit) {
    actions.push({ icon: <CloseOutlined />, action: handleCancel }, { icon: <CheckOutlined />, action: handleSave, type: 'primary' })
  }

  const actionList = actions.map(({ icon, action, type }) => <Button icon={icon} type={type} size="default" onClick={action}/>)

  return (
    <StyledListItem actions={actionList}>
      [{index + 1}]
      <ContentWrapper>
        {isEdit ? <Input.TextArea value={text} onChange={e => setText(e.target.value)}/> : item}
      </ContentWrapper>
    </StyledListItem>
  )
}

export default FootnoteListItem
