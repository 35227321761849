import React from 'react'
import { isObject, isArray } from 'lodash'
import styled from 'styled-components'
import { List, Typography, Card, Popover } from 'antd'

let ReactJson = () => null

if (typeof window !== 'undefined') {
  ReactJson = require('react-json-view')
}

const { Text } = Typography

const StyledCard = styled(Card)`
    background: transparent;
    border: none;
`

const FieldChanges = ({ changes, title }) => {
  if (!changes) return null

  const data = JSON.parse(changes)

  const fields = Object.keys(data)
  const dataSource = fields.reduce((acc, field) => {
    return [...acc, { field, ...data[field] }]
  }, [])

  const updates = dataSource.map(item => {
    if (isObject(item.new) || isArray(item.new)) {
      const { field } = item
      return (
        <div>
          <Text strong>{field}</Text> was <Text type="warning">updated</Text>
        </div>
      )
    }
    return (
      <span>
        <Text strong>{item.field}</Text> was updated from
        <Text delete>{` ${item.original}`}</Text> to <Text type="warning">{'' + item.new}</Text>
      </span>
    )
  })

  return (
    <StyledCard size="small" title={title}>
      <List
        size="small"
        dataSource={updates}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
    </StyledCard>
  )

}

export default FieldChanges
