import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import gql from 'graphql-tag'
import { documentFieldsFragment } from '../../fragments/document'

const query = gql`
  query Documents($params: DocumentListInput!) {
    documents(params: $params) {
      directories {
        ...documentFields
      }
      files {
        ...documentFields
      }
      meta {
        readOnly
        pathReadOnly
      }
    }
  }
  ${documentFieldsFragment}
`

const onError = () => {
  notification.error({ message: 'Something went wrong. Please try again' })
}

const getAllFilesInDocuments = async (apolloClient, params, data) => {
  let newData = data

  if (!data) {
    const result = await apolloClient
      .query({
        query,
        variables: {
          params: { ...params },
        },
      })
      .catch(() => {
        console.warn('error getting documents')
      })
    newData = result.data.documents
  }

  const directories = newData?.directories

  if (directories && directories.length === 0) {
    return newData
  }

  const findFilesInDirectory = directories.map(async directory => {
    const result = await getAllFilesInDocuments(apolloClient, { ...params, path: `${params.path}/${directory.name}` })
    return {
      ...result,
    }
  })

  const directoryAndFiles = await Promise.all(findFilesInDirectory)

  return {
    ...newData,
    directories: directoryAndFiles,
  }
}

const useGetAllDocumentsAndFiles = (params = {}) => {
  const apolloClient = useApolloClient()

  return paths =>
    Promise.all(paths.map(async path => await getAllFilesInDocuments(apolloClient, { ...params, path: `${params.path}/${path}` }))).catch(
      onError,
    )
}

export default useGetAllDocumentsAndFiles
