import React from 'react'
import { navigate } from 'gatsby'
import auth from '../../helpers/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!auth().isAuthenticated()) {
    if (typeof window !== 'undefined') {
      navigate('/login')
    }
    return null
  }
  return <Component {...rest} />
}

export default PrivateRoute
