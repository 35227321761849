import React from 'react'
import { differenceBy, debounce } from 'lodash'
import CrmCategoriesList from './CrmCategoriesList'
import useGetCrmPersonCategories from 'src/apollo/query/crm/useGetCrmPersonCategories'
import { Button, Card, Modal, Select } from 'antd'
import { CRM_NAME } from 'src/utils/constants/crm'
import useAddCategoryCrmPerson from 'src/apollo/mutation/crm/useAddCategoryCrmPerson'
import LoadingOverlay from '../../../../ui/LoadingOverlay'
import styled from 'styled-components'


const StyledCard = styled(Card)`
  flex-basis: 40%;
`

const CrmCategories = ({ id, saving }) => {
  const [crmId, setCrmId] = React.useState(null)

  const [isOpen, setIsOpen] = React.useState(false)
  const [saveLoading, setSaveLoading] = React.useState(false)
  const [categoryId, setCategoryId] = React.useState(null)
  const [pendingCategories, setPendingCategories] = React.useState([])

  const { data, loading, error, refetch: refetchPersonCategories } = useGetCrmPersonCategories({
    params: { id: crmId },
    skip: !crmId,
    fetchPolicy: 'network-only',
    onError: () => null,
  })
  const { data: allData, loading: allDataLoading } = useGetCrmPersonCategories({
    params: {},
    onError: () => null,
  })

  const [addCategoryCrmPerson, { loading: addLoading }] = useAddCategoryCrmPerson({
    params: { id, categoryId },
  })

  const handleAddCategory = () => {
    setPendingCategories([...pendingCategories, categoryId])
    setIsOpen(false)
    setCategoryId(null)
  }

  const handleSaveCategories = async () => {
    setSaveLoading(true)
    for (let categoryId of pendingCategories) {
      await addCategoryCrmPerson({
        variables: {
          params: {
            id, categoryId,
          },
        },
      })
    }
    await refetchPersonCategories()
    setPendingCategories([])
    setSaveLoading(false)
  }

  React.useEffect(() => {
    if (saving && !saveLoading && pendingCategories.length) {
      handleSaveCategories()
    }
  }, [saving])

  const delaySetCrmId = React.useRef(debounce(setCrmId, 500)).current

  React.useEffect(() => {
    delaySetCrmId(id)
  }, [id])

  const allCategories = allData?.crmPersonCategories || []
  const categoriesForId = data?.crmPersonCategories || []
  const remainingCategories = differenceBy(allCategories, categoriesForId, 'id')

  const computedCategoriesForId = [...categoriesForId, ...allCategories.filter(item => pendingCategories.includes(item.id))]
  const computedRemainingCategories = [...remainingCategories.filter(item => !pendingCategories.includes(item.id))]

  return (
    <>
      <StyledCard
        title={`${CRM_NAME} categories`}
        extra={
          <Button
            disabled={!id || error || loading || allDataLoading}
            onClick={() => setIsOpen(true)}>
            Add category
          </Button>
        }>
        <LoadingOverlay loading={loading || saveLoading}>
          <CrmCategoriesList
            id={crmId}
            data={computedCategoriesForId}
            loading={loading || saveLoading}
            error={error} />
        </LoadingOverlay>
      </StyledCard>
      <Modal
        title={`Add new ${CRM_NAME} category`}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={handleAddCategory}
        confirmLoading={addLoading}
        closable={false}>
        <Select
          disabled={addLoading}
          style={{ width: '100%' }}
          onChange={setCategoryId}
          value={categoryId}>
          {computedRemainingCategories.map(item => {
            return (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            )
          })}
        </Select>
      </Modal>
    </>
  )
}

export default CrmCategories
