import React from 'react'
import { Col, Row } from 'antd'
import useGetUserStats from 'src/apollo/query/users/useGetUserStats'
import { Line, Bar } from 'react-chartjs-2'
import styled from 'styled-components'
import StatisticCard from '../../Statistic/StatisticCard'
import { getNewUsersChartDataAndOptions, getActiveUsersChartDataAndOptions } from './helpers'

const ChartWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  canvas {
  height: 145px!important;
  }
`

const UserStats = () => {
  const { data, loading } = useGetUserStats({ params: {} })
  const newUsers = data?.userDailyStats?.reduce((acc, item) => {
    return acc + item.signups
  }, 0)

  const [newUsersData, newUsersCharOptions] = getNewUsersChartDataAndOptions(data)
  const [activeUsersData, activeUsersChartOptions] = getActiveUsersChartDataAndOptions(data)

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <StatisticCard title="Total users with access" value={data?.totalUserCount} loading={loading}/>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <StatisticCard title="New users (30 days)" value={newUsers} loading={loading}>
            <ChartWrapper>
              <Line height={145} data={newUsersData} options={newUsersCharOptions} />
            </ChartWrapper>
          </StatisticCard>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <StatisticCard title="Active users (30 days)" value={data?.activeUsersCount} loading={loading}>
            <ChartWrapper>
              <Bar height={145} data={activeUsersData} options={activeUsersChartOptions} />
            </ChartWrapper>
          </StatisticCard>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6}>
          <StatisticCard title="Users active today" value={data?.activeTodayUsersCount} loading={loading}/>
        </Col>
      </Row>
    </>
  )
}

export default UserStats