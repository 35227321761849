import React from 'react'

const UserEditContext = React.createContext({
  user: {},
  getState: {},
  resetPassword: () => null,
  resetPasswordState: {},
  update: () => null,
  updateState: {},
})

export default UserEditContext
