import React, { useEffect } from 'react'
import useGetFunds from 'src/apollo/query/funds/useGetFunds'
import { Card, Col } from 'antd'
import styled from 'styled-components'
import { mapFundCsvPerformanceData } from 'src/helpers/fund'
import PageHeader from '../../../ui/PageHeader'
import Error from '../../../ui/Error'
import Table from '../../../Table/Table'
import CsvUpload from '../../../CsvUpload/CsvUpload'
import { columns } from './columns'
import Actions from './Actions'
import { ACTION_TYPE_REPLACE_ALL, fundDataReducer } from './reducer'
import { DATA_UPLOAD_STATUS } from './renderers/StatusRenderer'

const StyledTable = styled(Table)`
  .ant-table-row-selected > .ant-table-cell {
    background: white;
  }
`

const BulkUploadData = () => {
  const [csvData, setCsvData] = React.useState(null)
  const [fundData, dispatchFundData] = React.useReducer(fundDataReducer, [])
  const [selectedKeys, setSelectedKeys] = React.useState([])
  const { data, loading, error } = useGetFunds({
    params: {
      overridePermissions: true,
    },
    fetchPolicy: 'cache-and-network',
  })
  const funds = data?.funds?.results || []

  useEffect(() => {
    if (csvData) {
      const allFundsData = []
      funds.forEach(({ groups, ...fund }) => {
        const { uuid, externalId, name } = fund
        const requiredData = { uuid, externalId, name }
        try {
          const data = mapFundCsvPerformanceData(csvData, externalId)
          allFundsData.push({
            ...requiredData,
            status: data.isDateAutoFilled ? DATA_UPLOAD_STATUS.dateAutoFilled : DATA_UPLOAD_STATUS.pending,
            update: true,
            data: { uuid, ...data },
          })
        } catch (e) {
          allFundsData.push({
            ...requiredData,
            status: DATA_UPLOAD_STATUS.noData,
            update: false,
            data: null,
          })
        }
      })
      dispatchFundData({
        type: ACTION_TYPE_REPLACE_ALL,
        payload: allFundsData,
      })
      setSelectedKeys(allFundsData.filter(item => item.update === true).map(item => item.uuid))
    }
  }, [csvData])

  if (error) return <Error/>
  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Bulk upload fund data'/>
      <CsvUpload disabled={loading} onChange={setCsvData}/>

      {!!fundData.length && (
        <Card>
          <StyledTable
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedKeys,
              getCheckboxProps: record => ({
                disabled: !record.data,
                name: record.name,
              }),
              onChange: setSelectedKeys,
            }}
            pagination={false}
            loading={loading}
            columns={columns}
            dataSource={fundData}/>
          <Actions selectedKeys={selectedKeys} data={fundData} dispatchSetData={dispatchFundData}/>
        </Card>
      )}
    </Col>
  )
}

export default BulkUploadData
