import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { documentFieldsFragment } from '../../fragments/document'

export const query = gql`
  query Documents($params: DocumentListInput!) {
    documents(params: $params) {
      directories {
        ...documentFields
      }
      files {
        ...documentFields
      }
      meta {
        visible
        groups
        lastModified
        root
        readOnly
        pathReadOnly
      }
      breadcrumbs {
        name
        path
      }
    }
  }
  ${documentFieldsFragment}
`

const useGetDocuments = ({ params, ...options } = {}) => {
  return useQuery(query, {
    variables: { params: { ...params } },
    ...options,
  })
}

export default useGetDocuments
