import {DateRenderer, ActionsRenderer, WarningsRenderer} from './renderers'

export const fundListColumns = [
  {
    key: 'warning',
    render: WarningsRenderer,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    key: 'date',
    render: DateRenderer,
  },
  {
    title: 'Monthly Return',
    dataIndex: 'monthlyReturn',
    key: 'monthlyReturn',
    align: 'center'
  },
  {
    title: 'YTD Return ',
    dataIndex: 'ytdReturn',
    key: 'ytdReturn',
    align: 'center'
  },
  {
    title: 'Nav',
    dataIndex: 'nav',
    key: 'nav',
    align: 'center'
  },
  {
    title: 'Actions',
    align: 'right',
    render: ActionsRenderer,
  },
]
