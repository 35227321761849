import * as React from 'react'
import classnames from 'classnames'
import Search from '../Search/Search'
import * as styles from './MobileMenu.module.scss'

function getBreadCrumbs(breadcrumbs, fund) {
  const firstCrumb = breadcrumbs[0]
  if (firstCrumb.value !== fund.name) {
    return null
  }
  return (
    <>
      {breadcrumbs.map(
        (breadcrumb, index) =>
          index > 0 && (
            <li onClick={breadcrumb.onClick} key={`fund-menu-${breadcrumb.value}`} className={styles.li}>
              <span className={styles.dash}>- </span>
              {breadcrumb.value}
            </li>
          ),
      )}
    </>
  )
}

export default ({ funds = [], emso = [], breadcrumbs = [] }) => {
  if (!breadcrumbs.length) {
    return null
  }
  const [expanded, setExpanded] = React.useState(false)
  const activeCrumb = breadcrumbs[breadcrumbs.length - 1]

  const toggleMenu = () => {
    setExpanded(!expanded)
  }

  const handleClick = (onClick) => () => {
    onClick()
    toggleMenu()
  }

  const activeText = expanded ? 'Funds' : activeCrumb.value

  return (
    <div className={styles.container}>
      <Search />
      <div
        className={classnames(styles.expander, {
          [styles.expanded]: expanded,
        })}
      >
        <div className={styles.wrapper}>
          <div
            onClick={toggleMenu}
            className={classnames(styles.topActive, {
              [styles.title]: expanded,
            })}
          >
            {activeText}
          </div>
          <div
            className={classnames(styles.arrow, {
              [styles.upArrow]: expanded,
            })}
          />
          <div
            className={classnames(styles.menuContent, {
              [styles.visible]: expanded,
            })}
          >
            <div className={styles.section}>
              {funds.map(fund => (
                <div key={`fund-menu-${fund.name}`} className={classnames(styles.name, { [styles.active]: fund.active })}>
                  <span onClick={handleClick(fund.onClick)} >{fund.name}</span>
                  {getBreadCrumbs(breadcrumbs, fund)}
                </div>
              ))}
            </div>
            <div>
              <h2 className={styles.title}>Emso</h2>
              {emso.map(em => (
                <div onClick={handleClick(em.onClick)} key={`emso-menu-${em.name}`} className={classnames(styles.name, { [styles.active]: em.active })}>
                  {em.name}
                  {getBreadCrumbs(breadcrumbs, em)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
