import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { Card, Checkbox, DatePicker, Input, Select, Form } from 'antd'
import { INSIGHT_TYPES } from '../../../../../constants/constants'
import FormItem from '../../../ui/FormItem'
import { DATE_FORMAT } from '../../../../../utils/constants/insights'
import { TagManager } from '../TagManager'
import ImageUpload from '../../../ImageUpload/ImageUpload'
import { AuthorManager } from '../AuthorManager'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import MarkdownEditor from './Markdown/MarkdownEditor'
import Actions from './Actions'
import VideoSelect from './VideoSelect'

const StyledCheckbox = styled(Checkbox)`
  margin-left: 20px;
`

const MAX_SUMMARY_LENGTH = 300

const NEWS_TYPE = 'news'

const EditForm = ({ loading, setField, fields, errors, setErrors, isCreate, setFields }) => {
  const { uuid, title, type, path, date, body, imageURL, hideDay, summary, externalPath, videoURL, videoPoster } = fields

  const handleInputChange = useCallback(({ target: { name, value } }) => {
    setField(name, value)
  }, null)

  const handleSummaryChange = useCallback(({ target: { value } }) => {
    value.length <= MAX_SUMMARY_LENGTH && setField('summary', value)
  }, null)

  const handleTypeChange = useCallback(type => {
    if (type === NEWS_TYPE) {
      setFields({
        ...fields,
        body: undefined,
        summary: undefined,
        videoURL: undefined,
        videoPoster: undefined,
        type,
      })
    } else {
      setField('type', type)
    }
  }, [setFields, setField, fields])

  const handleDateChange = useCallback(value => {
    setField('date', value)
  }, null)

  const handleImageChange = useCallback(value => {
    setField('imageURL', value)
  }, null)

  const handleVideoPosterChange = useCallback(value => {
    setField('videoPoster', value)
  }, null)

  const handleVideoChange = useCallback(value => {
    setField('videoURL', value)
  }, null)

  const handleBodyChange = useCallback(value => {
    setField('body', value)
  }, null)

  const handleHideDayChange = useCallback(({ target: { checked } }) => {
    setField('hideDay', checked)
  }, null)

  const isNews = type === NEWS_TYPE

  return (
    <LoadingOverlay loading={loading} transparent={false}>
      <Card title='Insight information'>
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 17 }}>

          <FormItem label='Title' error={errors.title}>
            <Input name="title" disabled={loading} value={title} onChange={handleInputChange}/>
          </FormItem>

          <FormItem label='Type' error={errors.type}>
            <Select
              placeholder="Select the type"
              disabled={loading}
              name="type"
              value={type}
              onChange={handleTypeChange}>
              {INSIGHT_TYPES.map(type => <Select.Option key={type} value={type}>{type}</Select.Option>)}
            </Select>
          </FormItem>

          <FormItem label='Path' error={errors.path}>
            <Input disabled value={path}/>
          </FormItem>

          <FormItem label='Date' error={errors.date}>
            <DatePicker format={DATE_FORMAT} allowClear={false} value={date} onChange={handleDateChange}/>
            <StyledCheckbox checked={hideDay} onChange={handleHideDayChange}> Hide Day </StyledCheckbox>
          </FormItem>

          <FormItem label='Authors' error={errors.authorName}>
            <AuthorManager uuid={uuid}/>
          </FormItem>

          <FormItem label='Image' error={errors.image}>
            <ImageUpload value={imageURL} onChange={handleImageChange}/>
          </FormItem>

          <FormItem label='External Link' error={errors.externalPath}>
            <Input value={externalPath} name="externalPath" onChange={handleInputChange}/>
          </FormItem>

          {!isNews && (
            <FormItem label="Video" error={errors.videoURL}>
              <VideoSelect url={videoURL} onSelect={handleVideoChange}/>
            </FormItem>
          )}

          {!isNews && (
            <FormItem label='Video Poster' error={errors.videoPoster}>
              <ImageUpload value={videoPoster} onChange={handleVideoPosterChange}/>
            </FormItem>
          )}

          {!isNews && (
            <FormItem label='Summary' error={errors.summary}>
              <Input.TextArea name="summary" value={summary} rows={4} onChange={handleSummaryChange}/>
              Characters left: {MAX_SUMMARY_LENGTH - get(summary, 'length', 0)}
            </FormItem>
          )}
          <FormItem label='Tags' error={errors.tags}>
            <TagManager uuid={uuid}/>
          </FormItem>
          {!isNews && (
            <FormItem label='Body' error={errors.body}>
              <MarkdownEditor value={body} onChange={handleBodyChange}/>
            </FormItem>
          )}
          <Actions insight={fields} onError={setErrors} loading={loading} isCreate={isCreate}/>
        </Form>
      </Card>
    </LoadingOverlay>
  )
}

EditForm.propTypes = {
  loading: PropTypes.bool,
  setField: PropTypes.func,
  setFields: PropTypes.func,
  fields: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  isCreate: PropTypes.bool,
}

EditForm.defaultProps = {
  loading: false,
  setField: () => null,
  setFields: () => null,
  fields: {},
  errors: {},
  setErrors: () => null,
  isCreate: false,
}

export default EditForm
