import gql from 'graphql-tag'

export const reportFieldsFragment = gql`
    fragment reportFields on Report {
        uuid
        type
        createdAt
        downloadUrl
        status
    }
`
