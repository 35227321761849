import React from 'react'
import ListChanges from './ListChanges'
import FieldChanges from './FieldChanges'

const ChangesRenderer = ({ changes, groupChanges, folderChanges, tagChanges, authorChanges }) => {
  return (
    <>
      <FieldChanges changes={changes} title="Fields"/>
      <ListChanges changes={groupChanges} accessor="name" title="Group changes"/>
      <ListChanges changes={folderChanges} title="Folder changes"/>
      <ListChanges changes={tagChanges} accessor="name" title="Tag changes"/>
      <ListChanges changes={authorChanges} accessor="name" title="Author changes"/>
    </>
  )
}

export default ChangesRenderer
