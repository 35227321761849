import React from 'react'
import { get } from 'lodash'
import { notification } from 'antd'
import PublishInsights from 'src/apollo/mutation/insights/PublishInsights'
import useGetInsights from 'src/apollo/query/insights/useGetInsights'
import InsightsActions from '../../ChangesSummary/renderers/InsightsActions'
import InsightsTitle from '../../ChangesSummary/renderers/InsightsTitle'
import ErrorHandler from '../../../../Error/ErrorHandler'
import Summary from '../../ChangesSummary/ChangesSummary'

const ChangesSummary = () => {
  const { data, loading, error } = useGetInsights({
    params: { version: true, hasChanges: true },
    fetchPolicy: 'cache-and-network',
  })

  const pendingInsights = get(data, 'insights.results') || []
  return (
    <ErrorHandler error={error}>
      <PublishInsights
        uuids={pendingInsights.map(({ uuid }) => uuid)}
        onCompleted={() => {
          notification.success({ message: 'Changes published successfully' })
        }}
        refetchQueries={['InsightsList']}>
        {(publish, { loading: publishLoading }) => {
          return (
            <Summary
              loading={loading || publishLoading}
              data={pendingInsights}
              onPublish={publish}
              actionsRenderer={InsightsActions}
              titleRenderer={InsightsTitle}
              previewLink="/insights"/>
          )
        }}
      </PublishInsights>
    </ErrorHandler>
  )
}

export default ChangesSummary
