import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const addAuthorToInsightsMutation = gql`
    mutation AddAuthorInsights($params: InsightsAddAuthorInput!) {
        addAuthorInsights(params: $params)
    }
`

const useAddAuthorInsights = ({ params, ...options } = {}) => {
  return useMutation(addAuthorToInsightsMutation, {
    variables: { params }, ...options,
  })
}

export default useAddAuthorInsights
