import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { userFieldsFragment } from '../../fragments/user'

export const usersQuery = gql`
    query Users($params: UsersInput!) {
        users(params: $params) {
            users {
                ...userFields
            }
            length
            limit
            start
            total
        }
    }
    ${userFieldsFragment}
`

const useGetUsers = ({ params, ...options }) => {
  return useQuery(usersQuery, {
    variables: { params },
    ...options,
  })
}

export default useGetUsers
