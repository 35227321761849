import React from 'react'
import { navigate } from 'gatsby-link'
import { EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import PublishInsights from '../../../../../../apollo/mutation/insights/PublishInsights'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const SpacedButton = styled(Button)`
  margin-right: 15px;
`

const ChangesActionsRenderer = ({ uuid, path }) => (
  <ButtonWrapper>
    <SpacedButton onClick={() => navigate(`/portal/admin/insights/edit/${uuid}`)} icon='edit'> Edit </SpacedButton>
    <SpacedButton onClick={() => window.open(path)} icon={<EyeOutlined />}> Preview </SpacedButton>
    <PublishInsights uuids={[uuid]}>
      {(publish, { loading }) => (
        <Button
          loading={loading}
          type='primary'
          onClick={publish}
          icon={<SaveOutlined />}> Publish </Button>
      )}
    </PublishInsights>
  </ButtonWrapper>
)

export default ChangesActionsRenderer
