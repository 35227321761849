import React, { useContext } from 'react'
import { Select } from 'antd'
import useGetGroups from 'src/apollo/query/groups/useGetGroups'
import UserEditContext from './UserEditContext'

const { Option } = Select

const Permissions = () => {
  const {
    user,
    updateUserData,
  } = useContext(UserEditContext)

  const { data, loading } = useGetGroups()
  const groups = data?.groups?.results || []
  const handleSelect = selection => {
    updateUserData('groups', selection)
  }

  return (
    <Select
      disabled={loading}
      mode="multiple"
      autoClearSearchValue={false}
      style={{ width: '100%' }}
      placeholder="Add groups"
      filterOption={(inputValue, option) => {
        return option.props.label.toLowerCase().includes(inputValue.toLowerCase())
      }}
      value={user.groups}
      notFoundContent="No groups available. Update the filter"
      onChange={handleSelect}
    >
      {groups.map(({ uuid, name }) => {
        return <Option id={`GroupSelector-Option-${uuid}`} key={uuid} label={name} value={uuid}>{name}</Option>
      })}
    </Select>
  )
}

export default Permissions
