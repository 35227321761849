import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { groupFieldsFragment } from '../../fragments/group'

export const getGroupsQuery = gql`
    query GroupList($params: GroupListInput!) {
        groups(params: $params) {
            results {
                ...groupFields
            }
            page
            limit
            total
            count
        }
    }
    ${groupFieldsFragment}
`

const defaultOrder = [{ order: 'name', orderDirection: 'asc' }]

const useGetGroups = ({ params, ...options } = {}) => {
  return useQuery(getGroupsQuery, {
    variables: { params: { order: defaultOrder, ...params } },
    ...options,
  })
}

export default useGetGroups
