import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const resetUserPasswordMutation = gql`
    mutation ResetUserPassword($params: ResetUserPasswordInput!) {
        resetUserPassword(params: $params)
    }
`

const useResetUserPassword = ({ params, ...options } = {}) => {
  return useMutation(resetUserPasswordMutation, {
    variables: { params }, ...options,
  })
}

export default useResetUserPassword
