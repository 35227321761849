import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Switch, Alert } from 'antd'
import GroupSelector from './GroupSelector'
import GroupTagList from './GroupTagList'
import FormItem from '../ui/FormItem'

const Message = styled(Alert)`
  margin-top: 20px;
`

const GroupManager = props => {
  const {
    available,
    current,
    changes,
    resulting,
    loading,
    onSelect,
    onRemove,
    onRevert,
    onCancel,
    onSave,
    open,
    message,
    toggle,
    readOnlyDisabled,
    readOnly,
    toggleReadOnly,
    readOnlyMessage
  } = props

  const handleCancel = useCallback(() => {
    toggle(false)
    onCancel()
  }, [onCancel])

  const handleOk = useCallback(() => {
    onSave()
  }, [onSave])

  useEffect(() => {
    if (!loading && open) {
      toggle(false)
    }
  }, [loading])

  return (
    <Modal
      confirmLoading={loading}
      width={600}
      bodyStyle={{ minHeight: 400 }}
      title="Manage Permissions"
      visible={open}
      onOk={handleOk}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ id: 'GroupManager-OkButton' }}
      okText="Save"
      onCancel={handleCancel}>
      {toggleReadOnly && (
        <FormItem label="Allow download">
          <Switch checked={!readOnly} disabled={readOnlyDisabled} onChange={bool => toggleReadOnly(!bool)} />
        </FormItem>
      )}
      {open && <GroupSelector loading={loading} groups={available} onSelect={onSelect}/>}

      <GroupTagList list={current} title='Current' onClose={onRemove}/>

      <GroupTagList list={changes} title='Changes' onClose={onRevert}/>

      <GroupTagList list={resulting} title='Resulting groups'/>

      {message && <Message id='message' message={message}/>}
      {readOnlyMessage && (
        <Message id='readOnlyMessage' message={readOnlyMessage}/>
      )}
    </Modal>
  )
}

GroupManager.propTypes = {
  available: PropTypes.array,
  current: PropTypes.array,
  changes: PropTypes.array,
  resulting: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onRevert: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  buttonText: PropTypes.string,
  buttonLoading: PropTypes.bool,
}

GroupManager.defaultProps = {
  available: [],
  current: [],
  changes: [],
  resulting: [],
  loading: false,
  onSelect: () => null,
  onRemove: () => null,
  onRevert: () => null,
  onCancel: () => null,
  onSave: () => null,
  buttonText: 'Permissions',
  buttonLoading: false,
}

export default GroupManager
