import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import { insightFieldsFragment } from '../../fragments/insight'

const createMutation = gql`
    mutation CreateInsight($params: InsightCreateInput!) {
        createInsight(params: $params) {
            ...insightFields
        }
    }
  ${insightFieldsFragment}
`

const updateMutation = gql`
    mutation UpdateInsight($params: InsightCreateInput!) {
        updateInsight(params: $params) {
            ...insightFields
        }
    }
    ${insightFieldsFragment}
`

const SaveInsight = ({ children, params, ...props }) => {
  const { uuid } = params
  const refetchQueries = ['InsightsList']

  return (
    <Mutation
      mutation={uuid ? updateMutation : createMutation}
      variables={{ params }}
      awaitRefetchQueries
      refetchQueries={uuid ? [...refetchQueries, 'Insight'] : refetchQueries}
      {...props}>
      {(mutate, state) => {
        return children(mutate, state)
      }}
    </Mutation>
  )
}

export default SaveInsight
