import React from 'react'
import Mutation from '../Mutation'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { personFieldsFragment } from '../../fragments/person'
import { PERSON_STATUS_PUBLISHED } from '../../../constants/constants'
import { buildSlug } from '../../../utils/helpers/insight'

export const createPersonMutation = gql`
    mutation CreatePerson($params: PersonCreateInput) {
        createPerson(params: $params) {
            ...personFields
        }
    }
  ${personFieldsFragment}
`

export const updatePersonMutation = gql`
    mutation UpdatePerson($params: PersonCreateInput) {
        updatePerson(params: $params) {
            ...personFields
        }
    }
    ${personFieldsFragment}
`

const SavePerson = ({ children, params: paramsInput, ...props }) => {
  const refetchQueries = ['PeopleList']
  const {uuid, status, firstName, lastName} = paramsInput
  const params = {
    status: status || PERSON_STATUS_PUBLISHED,
    path: buildSlug(`${firstName}-${lastName}`),
    ...paramsInput
  }

  return (
    <Mutation
      showError={false}
      mutation={uuid ? updatePersonMutation : createPersonMutation}
      variables={{ params }}
      awaitRefetchQueries
      refetchQueries={uuid ? [...refetchQueries, 'Person'] : refetchQueries}
      {...props}>
      {(mutate, state) => {
        return children(mutate, state)
      }}
    </Mutation>
  )
}

SavePerson.propTypes = {
  children: PropTypes.func.isRequired,
  params: PropTypes.object
}

SavePerson.defaultProps = {
  params: {}
}

export default SavePerson
