import React from 'react'
import gql from 'graphql-tag'
import Mutation from '../Mutation'
import { insightFieldsFragment } from '../../fragments/insight'

export const publishInsightsMutation = gql`
    mutation PublishInsights($params: InsightsPublishInput!) {
        publishInsights(params: $params){
          results {
              ...insightFields
              next {
                  ...insightFields
              }
              previous {
                  ...insightFields
              }
              original {
                  ...insightFields
              }
          }
        }
    }
  ${insightFieldsFragment}
`

const PublishInsights = ({ children, uuids, ...props }) => {
  const params = { uuids }
  return (
    <Mutation mutation={publishInsightsMutation} {...props} variables={{params}}>
      {(mutate, state) => {
        return children(mutate, state)
      }}
    </Mutation>
  )
}

export default PublishInsights
