import React, { useEffect, useState } from 'react'
import useSaveFund from 'src/apollo/mutation/funds/useSaveFund'
import styled from 'styled-components'
import ActionButtons from '../../../ActionButtons/ActionButtons'
import { ACTION_TYPE_UPDATE } from './reducer'
import { DATA_UPLOAD_STATUS } from './renderers/StatusRenderer'

const StyledActionButtons = styled(ActionButtons)`
  margin-top: 20px;
`

const Actions = ({ data, dispatchSetData, selectedKeys }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [saveFund] = useSaveFund({
    params: { uuid: true },
    showErrorNotification: false,
  })

  const dispatchLoading = item => {
    dispatchSetData({
      type: ACTION_TYPE_UPDATE,
      payload: { ...item, status: DATA_UPLOAD_STATUS.loading },
    })
  }
  const dispatchSuccess = item => {
    dispatchSetData({
      type: ACTION_TYPE_UPDATE,
      payload: { ...item, status: DATA_UPLOAD_STATUS.success },
    })
  }
  const dispatchError = item => {
    dispatchSetData({
      type: ACTION_TYPE_UPDATE,
      payload: { ...item, status: DATA_UPLOAD_STATUS.error },
    })
  }
  const dispatchSkipped = item => {
    dispatchSetData({
      type: ACTION_TYPE_UPDATE,
      payload: { ...item, status: DATA_UPLOAD_STATUS.skipped },
    })
  }

  const handleExecuteRequest = async item => {
    const { data: params } = item
    dispatchLoading(item)
    const result = await saveFund({ variables: { params } })
    if (result) {
      dispatchSuccess(item)
    } else {
      dispatchError(item)
    }

    return !!result
  }

  const bulkSaveFundData = async() => {
    setLoading(true)
    const responses = []
    for (let item of data) {
      if (selectedKeys.includes(item.uuid)) {
        const result = await handleExecuteRequest(item)
        responses.push(result)
      } else {
        dispatchSkipped(item)
      }
    }
    if (responses.every(item => item === true)) {
      setDisabled(true)
    }
    setLoading(false)
  }

  const actions = [{
    children: 'Save fund data',
    onClick: bulkSaveFundData,
    loading,
    disabled,
  }]

  useEffect(() => {
    setDisabled(false)
  }, [selectedKeys])

  return <StyledActionButtons actions={actions}/>
}

export default Actions
