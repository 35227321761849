import * as React from 'react'
import { Col, Row, Card } from 'antd'
import Table from '../../Table/Table'
import useGetUsers from '../../../../apollo/query/users/useGetUsers'
import PageHeader from '../../ui/PageHeader'
import { columns } from '../Users/columns'
import { handleOrderChange } from 'src/helpers/table'

const defaultOrder = [{ order: 'createdAt', orderDirection: 'desc' }]

export default () => {
  const [order, setOrder] = React.useState(defaultOrder)
  const { data, loading } = useGetUsers({
    params: { pending: true, perPage: 100, order: order.length ? order : undefined },
    fetchPolicy: 'cache-and-network',
  })
  const users = data?.users?.users || []

  const handleTableChange = (pagination, filters, sorter, { action }) => {
    if (action === 'sort') {
      handleOrderChange(sorter, setOrder, defaultOrder)
    }
  }

  return (
    <Col xl={24} xxl={18}>
      <PageHeader
        title='User Requests' />
      <Row>
        <Col sm={24}>
          <Card title="Pending user requests">
            <Table
              thin
              rowKey='id'
              loading={loading}
              dataSource={users}
              onChange={handleTableChange}
              columns={columns}
              pagination={false}
              scroll={{ x: 400 }}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
