import gql from 'graphql-tag'
import { omit } from 'lodash'
import useMutation from '../useMutation'
import { userFieldsFragment } from '../../fragments/user'

export const updateUserMutation = gql`
    mutation UpdateUser($params: UpdateUserInput!) {
        updateUser(params: $params){
            ...userFields
            funds {
                uuid
                name
            }
        }
    }
    ${userFieldsFragment}
`

const useSaveUser = ({ params: paramsInput, ...options } = {}) => {
  const params = {
    ...omit(paramsInput, ['__typename', 'funds']),
    level: parseInt(paramsInput.level),
  }
  return useMutation(updateUserMutation, {
    variables: { params }, ...options,
  })
}

export default useSaveUser
