import React from 'react'
import { Avatar, List, Grid, Button } from 'antd'
import { getImageSource } from 'src/utils/helpers/image'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { isEmpty } from 'lodash'
import { navigate } from 'gatsby-link'
import { EditOutlined } from '@ant-design/icons'
import { getRoute } from '../../../../../utils/routes'
import { StatusRenderer } from './renderers'

const { useBreakpoint } = Grid

const StyledListItem = styled(List.Item)`
  ${({ screen }) => screen.lg && `
    padding: 20px;
  `}
   ${({ screen }) => screen.xs && `
    .ant-list-item-action {
      margin-left: 0;
      width: 100%;
      li {
        width: 100%;
      }
    }
  `}
`

const Content = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50ch;
    width: 100%;
`

const getStyle = (isDragging, draggableStyle) => ({
  borderBottom: '1px solid #e8e8e8',
  background: isDragging ? 'rgb(232, 232, 232)' : 'white',
  ...draggableStyle,
})

const PersonCard = ({ person, index }) => {
  const screen = useBreakpoint()

  if (isEmpty(screen)) {
    return null
  }

  const actions = [
    <Button
      block={screen.xs}
      icon={<EditOutlined/>} onClick={() => navigate(getRoute('admin.people.edit', { uuid }))}>
      Edit
    </Button>,
  ]

  const { uuid, image, firstName, lastName, title, shortBio, bio, status, previewStatus } = person
  return (
    <Draggable key={uuid} draggableId={uuid} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(snapshot.isDragging, provided.draggableProps.style)}>
          <StyledListItem screen={screen} actions={actions}>
            <List.Item.Meta
              avatar={
                <Avatar shape='square' size='large' src={getImageSource(image)}/>
              }
              title={`${firstName} ${lastName}`}
              description={title}
            />
            <Content screen={screen}>
              <StatusRenderer status={status} previewStatus={previewStatus}/>
              {shortBio || bio}
            </Content>
          </StyledListItem>
        </div>
      )}
    </Draggable>

  )
}

export default PersonCard
