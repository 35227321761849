import React from 'react'
import useGetVideo from 'src/apollo/query/media/useGetVideo'
import { Button } from 'antd'
import useDeleteVideo from 'src/apollo/mutation/media/useDeleteVideo'

const Actions = ({ uuid, status }) => {
  const { startPolling, stopPolling } = useGetVideo({
    variables: {
      params: { uuid },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (status === 'pending') {
    startPolling(2000)
  }
  if (status !== 'pending') {
    stopPolling()
  }

  const [deleteVideo, { loading }] = useDeleteVideo({
    variables: {
      params: {
        uuid,
      },
    },
    refetchQueries: () => ['Videos'],
  })

  return <Button loading={loading} onClick={deleteVideo}>Delete</Button>
}
// The Actions component needed to be extracted in order to be able to use hooks
const ActionsRenderer = props => {
  return <Actions {...props} />
}

export default ActionsRenderer
