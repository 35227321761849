import React from 'react'
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import { navigate } from 'gatsby'

const ActionsRenderer = ({ uuid }) => {
  return <Button onClick={() => navigate(`/portal/admin/funds/${uuid}`)} icon={<EditOutlined />}> Edit </Button>;
}

export default ActionsRenderer
