import React from 'react'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'
import useGetCrmPerson from 'src/apollo/query/crm/useGetCrmPerson'
import {debounce} from 'lodash'
import Success from './Succes'
import NotFound from './NotFound'
import Error from './Error'
import Tooltip from '../../../../../Tooltip/Tooltip'
import { CRM_NAME } from 'src/utils/constants/crm'

const CrmId = ({ onChange, user }) => {
  const [email, setEmail] = React.useState(null)
  const [crmId, setCrmId] = React.useState(null)
  const { data: dataByEmail, loading: dataByEmailLoading, error: dataByEmailError, refetch: refetchDataByEmail } = useGetCrmPerson({
    params: {
      email,
    },
    skip: !email,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: () => null
  })

  const { data: dataById, error: dataByIdError, loading: dataByIdLoading, refetch: refetchDataById } = useGetCrmPerson({
    params: {
      id: crmId,
    },
    skip: !crmId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: () => null
  })

  const refetchData = () => {
    if(user.crmId) {
      refetchDataById()
    }
    refetchDataByEmail()
  }

  const delaySetEmail = React.useRef(debounce(setEmail, 500)).current
  const delaySetCrmId = React.useRef(debounce(setCrmId, 500)).current

  React.useEffect(() => {
    delaySetEmail(user?.email)
  }, [user?.email])

  React.useEffect(() => {
    delaySetCrmId(user?.crmId)
  }, [user?.crmId])

  const idByEmail = dataByEmail?.crmPerson?.id
  const validId = dataById?.crmPerson?.id
  const validEmail = dataById?.crmPerson?.email
  const loading = dataByEmailLoading || dataByIdLoading
  const error = dataByEmailError || dataByIdError

  if (!user.email) {
    return null
  }

  if (loading) {

    return <LoadingOutlined />
  }

  if (error) {
    return <Error refetch={refetchData} />
  }

  if (!idByEmail || (!validId && user.crmId)) {
    return <NotFound />
  }

  if(validId) {
    return <Tooltip text={`${CRM_NAME} ID associated with the email ${validEmail}`}><CheckOutlined /> </Tooltip>
  }

  return <Success id={idByEmail} onChange={onChange} />
}

export default CrmId
