import gql from 'graphql-tag'
import useQuery from '../useQuery'

import { PERSON_STATUS_PUBLISHED } from '../../../constants/constants'
import { peoplePaginationFragment, personFieldsFragment } from '../../fragments/person'

export const peopleQuery = gql`
    query PeopleList($params: PeopleFilterInput!) {
        people(params: $params) {
            results {
                ...personFields
                original {
                    ...personFields
                }
            }
            ...peoplePagination
        }
    }
    ${personFieldsFragment}
    ${peoplePaginationFragment}
`

const defaultOrder = [{ order: 'index', orderDirection: 'ASC' }]

const useGetPeople = ({ params: paramsInput, ...options }) => {
  const { version, order = defaultOrder, status = PERSON_STATUS_PUBLISHED } = paramsInput
  const params = {
    ...paramsInput,
    status,
    version: version || false,
    order,
  }

  return useQuery(peopleQuery, {
    variables: { params }, ...options,
  })
}

export default useGetPeople
