import * as React from 'react'
import { Card, Row, Col, Input } from 'antd'
import useGetGroups from 'src/apollo/query/groups/useGetGroups'
import { navigate } from 'gatsby-link'
import { PlusOutlined } from '@ant-design/icons'
import PageHeader from '../../ui/PageHeader'
import Table from '../../Table/Table'
import ActionButtons from '../../ActionButtons/ActionButtons'
import { columns } from './columns'
import styled from 'styled-components'

const StyledInput = styled(Input.Search)`
  .ant-input-affix-wrapper {
    height: 36px;
  }

  .ant-input-search-button {
    bottom: 1px;
  }
`

const PAGE_SIZE = 10
const START_PAGE = 1
export default () => {
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(START_PAGE)
  const [limit, setLimit] = React.useState(PAGE_SIZE)

  const { data, loading } = useGetGroups({
    params: {
      page,
      limit,
      filter: {
        search,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  React.useEffect(() => {
    setPage(START_PAGE)
  }, [search, limit])

  const groups = data?.groups?.results || []
  const total = data?.groups?.total

  const actions = [
    {
      onClick: () => navigate(`/portal/admin/groups/add`),
      children: 'Create Group',
      icon: <PlusOutlined />,
    },
  ]

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title="Groups" />
      <ActionButtons actions={actions} />
      <Row>
        <Col sm={24}>
          <Card extra={<StyledInput onSearch={setSearch} placeholder="Search..." allowClear />}>
            <Table
              showHeader={false}
              loading={loading}
              rowKey="uuid"
              columns={columns}
              pagination={{
                current: page,
                total,
                pageSize: limit,
                onChange: page => {
                  setPage(page)
                },
                onPageSizeChange: pageSize => {
                  setLimit(pageSize)
                },
              }}
              dataSource={groups}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
