import * as React from 'react'
import classnames from 'classnames'
import Search from '../Search/Search'
import { search, container, header, section, name, active } from './DocumentMenu.module.scss'
import Loading from '../../../Admin/ui/Loading'

export default ({ funds = [], emso = [], fundsLoading, emsoLoading }) => {
  return (
    <div className={container}>
      <div className={search}>
        <Search />
      </div>
      <div id="DocumentMenu-funds" className={section}>
        <h2 className={header}>Funds</h2>
        <Loading loading={fundsLoading}>
          {funds.map(fund => (
            <div
              id={`DocumentMenu-fund-${fund.path}`}
              onClick={fund.onClick}
              key={`fund-menu-${fund.name}`}
              className={classnames(name, { [active]: fund.active })}
            >
              {fund.name}
            </div>
          ))}
        </Loading>
      </div>
      <div id="DocumentMenu-emso-fund" className={section}>
        <h2 className={header}>Emso Asset Management</h2>
        <Loading loading={emsoLoading}>
          {emso.map(em => (
            <div
              id={`DocumentMenu-fund-${em.name
                .split(' ')
                .join('-')
                .toLowerCase()}`}
              onClick={em.onClick}
              key={`emso-menu-${em.name}`}
              className={classnames(name, { [active]: em.active })}
            >
              {em.name}
            </div>
          ))}
        </Loading>
      </div>
    </div>
  )
}
