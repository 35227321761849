import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import styled from 'styled-components'
import colors from '../../../utils/constants/colors'
const { Text } = Typography

const StyledText = styled(Text)`
  ${({ color }) => `color: ${color}`}
`

const TextWithColor = ({ color, children, ...props }) => {
  return <StyledText color={color} {...props}> {children} </StyledText>
}

TextWithColor.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

TextWithColor.defaultProps = {
  color: colors.grey,
}

export default TextWithColor
