import * as React from 'react'
import classNames from 'classnames'
import Row from '../../Row/Row'
import { error, row } from './Error.module.scss'

export default ({ className }) => {
  const textStyle = classNames(error, className)
  return (
    <Row inlineRow className={row}>
      <div className={textStyle}>Sorry there has been an error, please refresh and try again</div>
    </Row>
  )
}
