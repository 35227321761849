import React from 'react'
import { Tag } from 'antd'
import { INSIGHT_STATUS_ARCHIVED, INSIGHT_STATUS_PUBLISHED } from '../../../../../../constants/constants'
import colors from '../../../../../../utils/constants/colors'

const getStatusColor = (status, previewStatus) => {
  if (previewStatus) {
    return colors.warning
  }
  switch (status) {
    case INSIGHT_STATUS_ARCHIVED:
      return colors.error
    case INSIGHT_STATUS_PUBLISHED:
      return colors.success
    default:
      return null
  }
}

const StatusRenderer = ({ status,previewStatus }) => {
  const text = previewStatus ? 'pending' : status
  return (
    <Tag color={getStatusColor(status, previewStatus)}>{text}</Tag>
  )
}

export default StatusRenderer
