import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { videoFieldsFragment } from '../../fragments/video'

const query = gql`
    query Videos($params: VideoListInput) {
        videos(params: $params) {
            results {
                ...videoFields
            }
            page
            limit
            total
            count
        }
    }
    ${videoFieldsFragment}
`

const useGetVideos = options => {
  return useQuery(query, options)
}

export default useGetVideos
