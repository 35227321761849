import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const removeAuthorFromInsightsMutation = gql`
    mutation RemoveAuthorInsights($params: InsightsAddAuthorInput!) {
        removeAuthorInsights(params: $params)
    }
`

const useRemoveAuthorInsights = ({ params, ...options } = {}) => {
  return useMutation(removeAuthorFromInsightsMutation, {
    variables: { params }, ...options,
  })
}

export default useRemoveAuthorInsights
