import * as React from 'react'
import DocumentHeader from '../../../Login/Documents/Header/DocumentHeader'

export default ({ path = '', setPath }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState([
    {
      value: 'Root',
    },
  ])

  async function buildBreadcrumbs() {
    const directoryPrefix = []
    setBreadcrumbs(
      path.split('/').map(directory => {
        directoryPrefix.push(directory)
        if (!directory) {
          return {
            value: 'Root',
            onClick: () => {
              setPath('')
            },
          }
        }
        return {
          value: directory,
          onClick: () => {
            setPath(directoryPrefix.slice(0, directoryPrefix.length - 1).join('/'))
          },
        }
      }),
    )
  }

  React.useEffect(() => {
    buildBreadcrumbs()
  }, [path])

  return <DocumentHeader breadcrumbs={breadcrumbs} showFilter={false} />
}
