import React from 'react'
import { Radio, Input, Grid } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { INSIGHT_STATUS_ARCHIVED, INSIGHT_STATUS_PUBLISHED } from '../../../../../constants/constants'

const { useBreakpoint } = Grid

const Search = styled(Input.Search)`
  margin-right: 20px;

  .ant-input-affix-wrapper {
    height: 36px;
  }
`

const RadioGroup = styled(Radio.Group)`
  ${({ screen }) =>
    screen.xs &&
    `
    margin-top: 10px;
  `}
`

const Wrapper = styled.div`
  display: flex;
  > :last-child {
    width: 100%;
  }
  ${({ screen }) =>
    screen.xs &&
    `
    flex-direction: column;
  `}
`

const Filters = ({ status, onStatusChange, onSearch }) => {
  const screen = useBreakpoint()

  if (isEmpty(screen)) {
    return null
  }
  return (
    <Wrapper screen={screen}>
      <Search onSearch={onSearch} placeholder="Search..." allowClear />
      <RadioGroup screen={screen} value={status} onChange={onStatusChange} size="small">
        <Radio.Button value={INSIGHT_STATUS_PUBLISHED}>Active</Radio.Button>
        <Radio.Button value={INSIGHT_STATUS_ARCHIVED}>Archived</Radio.Button>
      </RadioGroup>
    </Wrapper>
  )
}

Filters.propTypes = {
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Filters
