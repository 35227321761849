import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types'

const Tooltip = ({ children, text }) => {

  return (
    <>
      <AntTooltip title={text}>
        {children}
      </AntTooltip>
    </>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
}

Tooltip.defaultProps = {
  children: <InfoCircleOutlined />,
}
export default Tooltip
