import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react'
import styled from 'styled-components'
import LogViewDrawer from '../../LogViewer/LogViewDrawer'
import { GROUP_SUBJECT_TYPE } from '../../LogViewer/constants'

const ButtonWithMargin = styled(Button)`
  margin-right: 5px;
`

const EditActions = ({ loading, uuid, deleteGroup }) => (
  <>
    <Dropdown disabled={loading} overlay={
      <Menu>
        <Menu.Item key="delete-group" disabled={!uuid || loading} onClick={deleteGroup}>Delete group</Menu.Item>
      </Menu>
    }>
      <ButtonWithMargin>
        <DownOutlined />
      </ButtonWithMargin>
    </Dropdown>
    <LogViewDrawer
      subjectId={uuid}
      subjectType={GROUP_SUBJECT_TYPE}/>
  </>
)

export default EditActions
