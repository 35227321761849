function getImage(frontmatter) {
  if (frontmatter.image) {
    return frontmatter.image.childImageSharp.fluid
  }
  return null
}

export function edgeToObject(edge) {
  return {
    id: edge.node.id,
    ...edge.node.frontmatter,
    image: getImage(edge.node.frontmatter),
  }
}

export function remarkToObject(markdownRemark) {
  return {
    id: markdownRemark.id,
    ...markdownRemark.frontmatter,
    image: getImage(markdownRemark.frontmatter),
    html: markdownRemark.html,
  }
}

export function filterPeopleByDepartment(people, department) {
  if (department === 'all') {
    return people
  }
  return people.filter(person => person.department.toLowerCase() === department.toLowerCase())
}

export function filterTransformTeam(edges, selectedDepartment) {
  let filtered = edges
  if (selectedDepartment !== 'all') {
    filtered = edges.filter(edge => selectedDepartment === edge.node.frontmatter.department)
  }
  return filtered.map(edge => edgeToObject(edge))
}
