import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { fundFieldsFragment } from '../../fragments/fund'

const query = gql`
    query Fund($params: FundInput!) {
        fund(params: $params) {
            ...fundFields
        }
    }
    ${fundFieldsFragment}
`

const useGetFund = ({ params, ...options }) => {
  return useQuery(query, {
    variables: { params },
    ...options,
  })
}

export default useGetFund
