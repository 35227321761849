import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ createdAt }) => (
  <Wrapper>{moment.unix(createdAt / 1000).format('DD MMM YYYY H:mm')}</Wrapper>
)
export default DateRenderer
