import React from 'react'
import CSVReader from 'react-csv-reader'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mapFundCsvPerformanceData } from 'src/helpers/fund'
import { header } from '../../Admin.module.scss'

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CsvInput = ({ fundId, onFileLoaded, onError }) => {

  const mapData = matrix => {
    try {
      const data = mapFundCsvPerformanceData(matrix, fundId)
      onFileLoaded(data)
    } catch (e) {
      onError(e)
    }
  }

  if (!fundId) return null
  return (
    <>
      <h2 className={header}>Upload Fund Performance</h2>
      <Wrapper>
        <CSVReader
          cssClass="csv-reader-input"
          onFileLoaded={mapData}
          onError={onError}
          inputId="performanceData"/>
      </Wrapper>
      <hr/>
    </>
  )
}

CsvInput.propTypes = {
  fundId: PropTypes.string.isRequired,
  onFileLoaded: PropTypes.func,
  onError: PropTypes.func,
}

CsvInput.defaultProps = {
  onFileLoaded: () => null,
  onError: () => null,
}

export default CsvInput
