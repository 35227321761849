import * as React from 'react'
import classnames from 'classnames'
import { Checkbox } from 'antd'
import Row from '../../../Row/Row'
import Col from '../../../Col/Col'
import fileIcon from './file.svg'
import * as tableStyles from '../DocumentTable.module.scss'
import * as styles from './Item.module.scss'

export default ({ name, date, type, onClick, marked, visible, onEdit, showVisibility, path, nameOverride, checkboxDisabled = false, showCheckBoxForDirectories= false }) => {
  const handleEdit = e => {
    if (onEdit) {
      onEdit(name, e.target.checked, path)
    }
  }
  const isFile = type === 'file'
  const isFolder = type === 'folder'
  const showCheckBox = (isFile && showVisibility) || (isFolder && showVisibility && showCheckBoxForDirectories)

  return (
    <Row inlineRow className={styles.row}>
      <Col large={7}>
        {isFile && <img className={styles.fileIcon} src={fileIcon} />}
        <span
          onClick={onClick}
          id={`DocumentItem-${type}-${name
            .split(' ')
            .join('')
            .replace('.pdf', '')}`}
          className={classnames(styles.hover, {
            [styles.folder]: type === 'folder',
            [styles.marked]: marked,
            [styles.name]: marked || type === 'folder',
            [styles.file]: isFile,
          })}
        >
          {nameOverride || name}
        </span>
      </Col>
      <Col large={3} className={tableStyles.dateCol}>
        <span>{date}</span>
      </Col>
      <div className={styles.visibilityCol}>
        {showCheckBox && <Checkbox style={{marginTop: '-3px', top: 'unset'}} onChange={handleEdit} checked={visible} disabled={checkboxDisabled} />}
      </div>
    </Row>
  )
}
