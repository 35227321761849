import React from 'react'
import { Col, Row } from 'antd'
import UserStats from './UserStats'
import PageHeader from '../../ui/PageHeader'

const Dashboard = () => {

  return (
    <Col xl={24}>
      <PageHeader
        title="Dashboard"/>
      <Row>
        <Col sm={24}>
          <UserStats />
        </Col>
      </Row>
    </Col>
  )
}

export default Dashboard
