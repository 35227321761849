export const FOOTNOTE_REGEX = '(\\[\\^\\d\\]:.{1,})'
export const FOOTNOTE_MARKDOWN_REGEX = '\\[\\^\\d\\]:'

export const hasFootnoteRef = (value = '', index) => {
  return value.match(RegExp(`\\[\\^${index + 1}\\]`, 'g'))
}
export const getFootnoteRef = index => {
  return `[^${index + 1}]`
}
const buildFootnote = (item, index) => {
  return `[^${index + 1}]: ${item}`
}
export const getFootnotesFromValue = value => {
  const match = new RegExp(FOOTNOTE_REGEX, 'g')
  return value.match(match) || []
}
export const removeFootnoteMarkdown = footnote => {
  return footnote.replace(RegExp(FOOTNOTE_MARKDOWN_REGEX, 'g'), '')
}
export const removeAllFootnotes = value => {
  if (!value) return ''
  return value.replace(RegExp(FOOTNOTE_REGEX, 's'), '').trim()
}
export const addFootnotesToValue = (value, footnotes) => {
  return value.trim() + '\n' + footnotes.map(buildFootnote).join('\n')
}
