// extracted by mini-css-extract-plugin
export var backArrow = "emso-Fund-module__backArrow";
export var backgroundImage = "emso-Fund-module__backgroundImage";
export var col = "emso-Fund-module__col";
export var colorDarkGold = "#e7b50c";
export var colorLightBlue = "#8590ad";
export var colorLightGray = "#ebebeb";
export var date = "emso-Fund-module__date";
export var documentButton = "emso-Fund-module__documentButton";
export var documents = "emso-Fund-module__documents";
export var fundLogo = "emso-Fund-module__fundLogo";
export var fundLogoContainer = "emso-Fund-module__fundLogoContainer";
export var header = "emso-Fund-module__header";
export var headerContainer = "emso-Fund-module__headerContainer";
export var perforamnceDataRow = "emso-Fund-module__perforamnceDataRow";
export var performanceHeader = "emso-Fund-module__performanceHeader";
export var performanceRow = "emso-Fund-module__performanceRow";
export var row = "emso-Fund-module__row";
export var subtitle = "emso-Fund-module__subtitle";
export var title = "emso-Fund-module__title";
export var value = "emso-Fund-module__value";