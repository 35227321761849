import React from 'react'
import { debounce, differenceBy } from 'lodash'
import { Select } from 'antd'
import useGetPeople from '../../../../../apollo/query/people/useGetPeople'
import useAddAuthorInsights from '../../../../../apollo/mutation/insights/useAddAuthorInsights'
import useRemoveAuthorInsights from '../../../../../apollo/mutation/insights/useRemoveAuthorInsights'
import LoadingOverlay from '../../../ui/LoadingOverlay'

const AuthorManager = ({ uuid }) => {
  const [search, setSearch] = React.useState('')
  const [selectDisabled, setSelectDisabled] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const { data, loading: allLoading } = useGetPeople({
    params: { search, limit: 5 },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSelectDisabled(false),
  })

  const { data: existingData, loading: existingLoading, refetch } = useGetPeople({
    params: { authorableUuid: uuid, authorableType: 'insightVersion' },
    notifyOnNetworkStatusChange: true,
    skip: !uuid,
  })

  const [addAuthor, { loading: addAuthorLoading }] = useAddAuthorInsights({
    onCompleted: () => {
      refetch()
      setOpen(false)
    },
  })

  const [removeTag, { loading: removeAuthorLoading }] = useRemoveAuthorInsights({
    onCompleted: refetch,
  })

  const allAuthors = data?.people?.results || []
  const existingAuthors = existingData?.people?.results || []

  const handleAdd = ({ key: authorUuid }) => {
    if (selectDisabled) {
      return
    }
    if (authorUuid) {
      addAuthor({ variables: { params: { uuid, authorUuid } } })
    }
    selectRef.current.blur()
    setTimeout(() => setOpen(false), 0)
  }

  const handleRemove = ({ key: authorUuid }) => {
    removeTag({ variables: { params: { uuid, authorUuid } } })
    setOpen(false)
  }

  const searchDebounce = debounce(query => {
    if (query) {
      setSearch(query)
    } else {
      setOpen(false)
    }
  }, 300)

  const handleSearch = searchQuery => {
    setOpen(true)
    setSelectDisabled(true)
    searchDebounce(searchQuery)
  }

  const loading = allLoading || addAuthorLoading || existingLoading || removeAuthorLoading
  const remaining = differenceBy(allAuthors, [...existingAuthors], 'uuid')
  const selectRef = React.useRef()

  return (
    <Select
      ref={selectRef}
      open={open}
      disabled={!uuid || addAuthorLoading || existingLoading || removeAuthorLoading}
      mode="multiple"
      autoClearSearchValue={false}
      loading={loading}
      value={existingAuthors.map(({ uuid, firstName, lastName }) => ({ key: uuid, label: `${firstName} ${lastName}` }))}
      placeholder="Select authors"
      filterOption={false}
      labelInValue
      onSearch={handleSearch}
      onSelect={handleAdd}
      onDeselect={handleRemove}
      onFocus={() => {
        setOpen(true)
      }}
      onBlur={() => {
        setOpen(false)
        setSearch('')
      }}
      dropdownRender={node => (
        <LoadingOverlay loading={loading} transparent={false}>
          {node}
        </LoadingOverlay>
      )}
    >
      {remaining.map(({ uuid, firstName, lastName }) => {
        return (
          <Select.Option key={uuid} value={uuid} label={name}>{firstName} {lastName}</Select.Option>
        )
      })}
    </Select>
  )
}

export default AuthorManager
