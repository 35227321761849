import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const addCategoryCrmPersonMutation = gql`
    mutation AddCategoryToCrmPerson($params: AddCategoryCrmPersonInput!) {
        addCategoryCrmPerson(params: $params)
    }
`

const useAddCategoryCrmPerson = ({ params, ...options } = {}) => {
  return useMutation(addCategoryCrmPersonMutation, {
    variables: { params }, ...options,
  })
}

export default useAddCategoryCrmPerson
