import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../../layouts/Layout'
import Hero from '../../Hero/Hero'
import Dashboard from './Dashboard'
import { portalPage, portal } from '../../../pages/portal/portal.module.scss'
import PortalMenu from '../../PortalMenu/PortalMenu'

export default () => {
  return (
    <Layout getInTouchVisible={false} lightBlueFooter className={portalPage} portal>
      <Helmet>
        <title>Dashboard - Emso</title>
      </Helmet>
      <Hero className={portal} admin />
      <PortalMenu />
      <Dashboard />
    </Layout>
  )
}
