import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { List } from 'antd'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import { PERSON_STATUS_ARCHIVED } from '../../../../../constants/constants'
import PersonCard from './PersonCard'
import OrderChanges from './OrderChanges'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const OrderedPeopleList = ({ people, loading, filter }) => {

  const [items, setItems] = React.useState([])

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    setItems(newItems)
  }

  React.useEffect(() => {
    if (!loading) {
      setItems(people)
    }
  }, [people.length, loading, filter])

  return (
    <LoadingOverlay loading={loading} transparent={false}>
      {filter !== PERSON_STATUS_ARCHIVED && <OrderChanges original={people} changed={items}/>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div{...provided.droppableProps} ref={provided.innerRef}>
              <List>
                {items.map((item, index) => (
                  <PersonCard
                    key={item.uuid}
                    index={index}
                    person={item}/>
                ))}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </LoadingOverlay>
  )

}

OrderedPeopleList.propTypes = {
  people: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.string,
}

OrderedPeopleList.defaultProps = {
  people: [],
  loading: false,
  filter: '',
}

export default OrderedPeopleList
