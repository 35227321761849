import gql from 'graphql-tag'
import useMutation from './useMutation'

export const fileUploadMutation = gql`
    mutation UploadFile($file: String!) {
        uploadFile(file: $file)
    }
`

const useFileUpload = ({ file, ...options } = {}) => {
  return useMutation(fileUploadMutation, {
    variables: { file }, ...options,
  })
}

export default useFileUpload
