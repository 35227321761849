import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const resetUserMultiFactorMutation = gql`
    mutation ResetUserMultiFactor($params: DeleteUserInput!) {
        resetUserMultiFactor(params: $params)
    }
`

const useResetUserMultiFactor = ({ params, ...options } = {}) => {
    return useMutation(resetUserMultiFactorMutation, {
        variables: { params }, ...options,
    })
}

export default useResetUserMultiFactor
