import { ActionsRenderer, StatusRenderer } from './renderers'
import TitleRenderer from './renderers/TitleRenderer'

export const columns = [
  {
    render: TitleRenderer,
    key: 'title'
  },
  {
    render: StatusRenderer,
    key: 'status'
  },
  {
    render: ActionsRenderer,
    key: 'actions'
  },
]
