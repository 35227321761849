import * as React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { departments } from 'src/utils/constants/team'
import { downArrow, item, membersCategory, selection, show, wrapper } from './Departments.module.scss'

export const Departments = ({ select, selected = 'all' }) => {
  const [open, setOpen] = React.useState(false)
  const wrapperRef = React.useRef()
  React.useEffect(() => {
    const handler = () => {
      if (open) {
        setOpen(false)
      }
    }
    window.addEventListener('click', handler)

    return () => window.removeEventListener('click', handler)
  }, [open])

  return (
    <div
      ref={wrapperRef}
      onClick={event => {
        setOpen(!open)
        event.stopPropagation()
      }}
      className={classnames(selection, { [show]: open })}
    >
      <div className={wrapper}>
        <span>{departments[selected]}</span> <div className={downArrow} />
      </div>
      <ul className={membersCategory}>
        {Object.keys(departments).map(
          key =>
            !(key === 'all' && selected === 'all') && (
              <li
                className={item}
                key={key}
                onClick={() => {
                  select(key)
                }}
              >
                {departments[key]}
              </li>
            ),
        )}
      </ul>
    </div>
  )
}

Departments.propTypes = {
  select: PropTypes.func,
  selected: PropTypes.string,
}

export default Departments
