import * as React from 'react'
import useGetFunds from 'src/apollo/query/funds/useGetFunds'
import useGetDocuments from 'src/apollo/query/documents/useGetDocuments'
import Col from '../../Col/Col'
import DocumentMenu from './Menu/DocumentMenu'
import MobileMenu from './MobileMenu/MobileMenu'
import { mobileMenu, menuCol } from './Documents.module.scss'

export default ({ openFund, breadcrumbs, fundPath, path, emsoMode, openEmso }) => {

  const { data, loading: fundsLoading } = useGetFunds()
  const funds = data?.funds?.results || []

  const formattedFunds = funds.map(f => {
    return {
      ...f,
      active: f.path === fundPath && !emsoMode,
      onClick: () => {
        openFund(f.path)
      },
    }
  })

  const { data: emsoData, loading: emsoLoading } = useGetDocuments({
    params: { emso: true },
  })

  const emsoFolders = emsoData?.documents?.directories || []
  const formattedEmsoFolders = emsoFolders.map(dir => {
    const emsoFolder = path.split('/')[1]
    return {
      name: dir.name,
      active: emsoFolder === dir.name && emsoMode,
      onClick: () => {
        openEmso(`${dir.meta.root}${dir.name}`)
      },
    }
  })

  return (
    <>
      <div className={mobileMenu}>
        <MobileMenu funds={formattedFunds} emso={formattedEmsoFolders} breadcrumbs={breadcrumbs}/>
      </div>
      <Col className={menuCol} large={4}>
        <DocumentMenu
          funds={formattedFunds}
          emso={formattedEmsoFolders}
          fundsLoading={fundsLoading}
          emsoLoading={emsoLoading}/>
      </Col>
    </>
  )
}
