import React from 'react'
import { Tag } from 'antd'
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'

export const DATA_UPLOAD_STATUS = {
  pending: 'pending',
  loading: 'loading',
  success: 'success',
  dateAutoFilled: 'date-autofilled',
  error: 'error',
  skipped: 'skipped',
  noData: 'noData',
}

const StatusRenderer = ({ status }) => {
  switch (status) {
    case DATA_UPLOAD_STATUS.pending:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          waiting
        </Tag>
      )
    case DATA_UPLOAD_STATUS.skipped:
      return (
        <Tag icon={<MinusCircleOutlined />} color="default">
          skipped
        </Tag>
      )
    case DATA_UPLOAD_STATUS.loading:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          processing
        </Tag>
      )
    case DATA_UPLOAD_STATUS.success:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          success
        </Tag>
      )
    case DATA_UPLOAD_STATUS.error:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          error
        </Tag>
      )
    case DATA_UPLOAD_STATUS.noData:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          no data
        </Tag>
      )
    case DATA_UPLOAD_STATUS.dateAutoFilled:
      return (
          <Tag icon={<ExclamationCircleOutlined />} color="default">
            pending - date autofilled
          </Tag>
      )
    default:
      return null
  }
}

export default StatusRenderer
