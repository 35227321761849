import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.div`
  display:flex;
`

const { Option } = Select

const GroupSelector = ({ groups, onSelect, loading }) => {
  const handleSelect = useCallback(selectedItem => {
    onSelect([groups.find(({ uuid }) => uuid === selectedItem)])
  }, [groups])

  return (
    <Wrapper>
      <Select
        disabled={loading}
        mode="multiple"
        autoClearSearchValue={false}
        style={{ width: '100%' }}
        placeholder="Add groups"
        filterOption={(inputValue, option) => {
          return option.props.label.toLowerCase().includes(inputValue.toLowerCase())
        }}
        value={[]}
        notFoundContent="No groups available. Update the filter or check parent permissions"
        onSelect={handleSelect}
      >
        {groups.map(({ uuid, name }) => {
          return <Option id={`GroupSelector-Option-${uuid}`} key={uuid} label={name} value={uuid}>{name}</Option>
        })}
      </Select>
    </Wrapper>
  )
}

GroupSelector.propTypes = {
  groups: PropTypes.array,
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
}

GroupSelector.defaultProps = {
  groups: [],
  onSelect: () => null,
  loading: false,
}
export default GroupSelector
