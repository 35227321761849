import React from 'react'
import { navigate } from 'gatsby-link'
import { SaveOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PublishInsights from 'src/apollo/mutation/insights/PublishInsights'
import { getRoute } from 'src/utils/routes'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const SpacedButton = styled(Button)`
  margin-right: 15px;
`

const InsightsActions = ({ uuid, path }) => (
  <ButtonWrapper>
    <SpacedButton
      id="InsightsActions-edit" onClick={() => navigate(getRoute('admin.insights.edit', { uuid }))}
      icon={<EditOutlined/>}>
      Edit
    </SpacedButton>
    <SpacedButton
      id="InsightsActions-preview"
      onClick={() => window.open(path)}
      icon={<EyeOutlined/>}> Preview </SpacedButton>
    <PublishInsights uuids={[uuid]} refetchQueries={['InsightsList']}>
      {(publish, { loading }) => (
        <Button
          id="InsightsActions-publish"
          loading={loading}
          type='primary'
          onClick={publish}
          icon={<SaveOutlined/>}>
          Publish
        </Button>
      )}
    </PublishInsights>
  </ButtonWrapper>
)

InsightsActions.propTypes = {
  uuid: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default InsightsActions
