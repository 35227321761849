import { CRM_NAME } from 'src/utils/constants/crm'
import Text from 'antd/lib/typography/Text'
import { RedoOutlined } from '@ant-design/icons'
import React from 'react'

const Error = ({ refetch }) => {
  const errorText = `Fetching ID from ${CRM_NAME} failed. `
  const handleClick = () => {
    refetch()
  }
  return (
    <>
      <Text type="danger">{errorText}</Text>
      <RedoOutlined onClick={handleClick}/>
    </>
  )
}

export default Error
