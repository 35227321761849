import React from 'react'
import { Alert, Button, Row, Typography } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { navigate } from 'gatsby-link'
import { CHANGE_STATUS_DELETED, CHANGE_STATUS_NEW } from 'src/constants/constants'
import { getUnpublishedChangeStatus, hasUnpublishedChanges } from 'src/utils/helpers/insight'

const StyledNotification = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`

const StyledRow = styled(Row)`
  margin-bottom: 20px;
  width: 100%;
  .ant-alert {
    width: 100%;
  }
`

const getStatusText = status => {
  switch (status) {
    case CHANGE_STATUS_NEW:
      return 'Click the publish button to make this item available publicly.'
    case CHANGE_STATUS_DELETED:
      return 'Click the publish button to remove this item from the website.'
    default:
      return 'Click the publish button to apply the changes to the public version of this item.'
  }
}

const ChangesNotification = ({ item, mutation, navigateTo }) => {

  const hasChanges = item.hasChanges || hasUnpublishedChanges(item)
  const status = getUnpublishedChangeStatus(item)
  const statusText = getStatusText(status)

  const { uuid } = item

  const [createPublish, { loading }] = useMutation(mutation, {
    onCompleted: () => {
      navigateTo && navigate(navigateTo)
    },
  })

  const publish = React.useCallback(() => {
    return createPublish({
      variables: { params: { uuids: [uuid] } },
    })
  }, [uuid])

  return hasChanges ? (
    <StyledRow>
      <Alert closable message='This item has unpublished changes' description={
        <StyledNotification>
          <Typography.Text type='primary'>
            {statusText}
          </Typography.Text>
          <ButtonWrapper>
            <Button size='default' type='primary' onClick={publish} loading={loading}>Publish</Button>
          </ButtonWrapper>
        </StyledNotification>
      }/>
    </StyledRow>
  ) : null
}

ChangesNotification.propTypes = {
  item: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  navigateTo: PropTypes.string,
}

export default ChangesNotification
