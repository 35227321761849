import * as React from 'react'
import { Card, notification, Switch, Form, Col, DatePicker } from 'antd'
import styled from 'styled-components'
import { omit } from 'lodash'
import useSaveFund from 'src/apollo/mutation/funds/useSaveFund'
import useGetFund from 'src/apollo/query/funds/useGetFund'
import { navigate } from 'gatsby-link'
import useDeleteFund from 'src/apollo/mutation/funds/useDeleteFund'
import moment from 'moment'
import { RAW_DATE_FORMAT_FUND } from 'src/helpers/fund'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import PageHeader from '../../../ui/PageHeader'
import FormItem from '../../../ui/FormItem'
import FormInput from '../../../ui/FormInput'
import ImageUpload from '../../../ImageUpload/ImageUpload'
import Error from '../../../ui/Error'
import Tooltip from '../../../../Tooltip/Tooltip'
import { buildSlug } from '../../../../../utils/helpers/insight'
import { DATE_FORMAT } from '../../../../../utils/constants/insights'
import AdditionalPerformanceInput from './AdditionalPerformanceInput'
import CsvInput from './CsvInput'
import FoldersInput from './FoldersInput'
import Actions from './Actions'

const SpacedCard = styled(Card)`
  margin-bottom: 20px;
`

const FundsEdit = ({ fundPath: fundPathInput }) => {
  const fundPath = fundPathInput === 'create' ? null : fundPathInput
  const [isBasic, setIsBasic] = React.useState(false)
  const [fund, setFund] = React.useState({})
  const [validationError, setValidationError] = React.useState({})

  const { loading: getLoading, error: getError } = useGetFund({
    params: { uuid: fundPath, overridePermissions: true },
    onCompleted: data => {
      data && setFund(data.fund)
    },
    skip: !fundPath,
  })

  const [saveFund, { loading: saveLoading }] = useSaveFund({
    params: {
      ...omit(fund, 'warnings'),
    },
    onCompleted: () => {
      notification.success({ message: 'Fund updated' })
      navigate('/portal/admin/dashboard')
    },
    onError: error => {
      const hasValidationErrors = error?.graphQLErrors[0]?.extensions?.exception
      if (hasValidationErrors.name === 'SequelizeValidationError') {
        const errors = hasValidationErrors.errors.reduce((acc, item) => {
          return {
            ...acc,
            [item.path]: item.message,
          }
        }, {})

        setValidationError(errors)
      }
    },
  })

  const [deleteFund, { loading: deleteLoading }] = useDeleteFund({
    params: { uuid: fund.uuid },
    onCompleted: () => {
      notification.success({ message: 'Fund updated' })
      navigate('/portal/admin/dashboard')
    },
  })

  const toggleIsBasic = isBasic => {
    const emptyValues = Object.keys(omit(fund, ['uuid', 'name', 'headline', 'path', 'image', 'groups', 'folders']))
    const emptyValuesObject = emptyValues.reduce((acc, item) => {
      return { ...acc, [item]: null }
    }, {})
    if (isBasic) {
      setFund({ ...fund, ...emptyValuesObject })
    }
    setIsBasic(isBasic)
  }

  const updateFundData = (item, valueInput) => {
    const value = item === 'path' ? buildSlug(valueInput) : valueInput
    setFund({
      ...fund,
      [item]: value,
    })
    setValidationError({ ...validationError, [item]: false })
  }

  const handleFileLoaded = data => {
    setFund({
      ...fund,
      ...data,
    })
    notification.success({ message: 'Fund performance data successfully added' })
  }

  const handleDateChange = momentDate => {
    const date = momentDate.format(RAW_DATE_FORMAT_FUND)
    setFund({ ...fund, date })
  }
  const handleFileError = ({ message }) => {
    setFund({ ...fund, performance: [], additionalPerformance: null })
    notification.error({ message })
  }

  if (getError) {
    return <Error/>
  }

  const { name, externalId, headline, path, disclaimer, image, monthlyReturn, uuid, date } = fund
  const { ytdReturn, nav, folders } = fund

  const loading = getLoading || saveLoading || deleteLoading

  return (
    <Col xl={24} xxl={18}>
      <PageHeader
        to='/portal/admin/funds'
        title={fundPath ? 'Edit Fund' : 'Create Fund'}/>
      <LoadingOverlay loading={loading}>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Actions
            uuid={uuid}
            deleteFund={deleteFund}
            saveFund={saveFund}
            loading={loading}
            saveLoading={saveLoading}
            deleteLoading={deleteLoading}/>
          <SpacedCard title='Fund information' extra={<Switch checked={isBasic} onChange={toggleIsBasic}/>}>

            <FormInput
              error={validationError.name}
              name='name'
              label='Fund Name'
              value={name}
              onChange={updateFundData}/>

            {!isBasic && (
              <FormInput
                error={validationError.externalId}
                name='externalId' label='External Id'
                value={externalId}
                onChange={updateFundData}
                suffix={<Tooltip text='The value of the FundId column from the fund performance CSV file.'/>}/>
            )}

            <FormInput
              error={validationError.path}
              name='path'
              label='Path'
              value={path}
              onChange={updateFundData}
              suffix={<Tooltip text='The path can only contain lowercase letters, numbers and dashes'/>}
            />

            <FormInput
              error={validationError.headline}
              type='textarea'
              name='headline'
              label='Headline'
              value={headline} onChange={updateFundData}/>

            {!isBasic && (
              <FormInput
                error={validationError.disclaimer}
                type='textarea'
                name='disclaimer'
                label='Disclaimer'
                value={disclaimer}
                onChange={updateFundData}/>
            )}

            {!isBasic && (
              <FormItem label='Date' error={validationError.date}>
                <DatePicker format={DATE_FORMAT} allowClear={false} value={date ? moment(date) : null} onChange={handleDateChange}/>
              </FormItem>
            )}

            <FormItem label='Image' error={validationError.image}>
              <ImageUpload value={image} onChange={value => updateFundData('image', value)}/>
            </FormItem>

            {!isBasic && (
              <>
                <FormInput
                  error={validationError.monthlyReturn}
                  type='number'
                  name='monthlyReturn'
                  label='Monthly Return'
                  value={monthlyReturn}
                  onChange={updateFundData}/>
                <FormInput
                  error={validationError.ytdReturn}
                  type='number'
                  name='ytdReturn'
                  label='YTD Return'
                  value={ytdReturn}
                  onChange={updateFundData}/>

                <FormInput
                  error={validationError.nav}
                  type='number'
                  name='nav'
                  label='Nav'
                  value={nav}
                  onChange={updateFundData}/>
              </>
            )}

          </SpacedCard>

          <FoldersInput uuid={fundPath} folders={folders} onChange={data => updateFundData('folders', data)}/>

          {!isBasic && (
            <SpacedCard title='Performance Data'>
              <AdditionalPerformanceInput fund={fund}/>
              {externalId && <CsvInput fundId={externalId} onFileLoaded={handleFileLoaded} onError={handleFileError}/>}
            </SpacedCard>
          )}

          <Actions
            uuid={uuid}
            deleteFund={deleteFund}
            saveFund={saveFund}
            loading={loading}
            saveLoading={saveLoading}
            deleteLoading={deleteLoading}/>
        </Form>
      </LoadingOverlay>

    </Col>
  )
}

export default FundsEdit
