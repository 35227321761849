import { DateRenderer, StatusRenderer } from './renderers'

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date',
    render: DateRenderer,
    key: 'status',
  },
  {
    title: 'Nav',
    dataIndex: ['data', 'nav'],
    key: 'nav',
  },
  {
    title: 'YTD',
    dataIndex: ['data', 'ytdReturn'],
    key: 'ytdReturn',
  },
  {
    title: 'Month',
    dataIndex: ['data', 'monthlyReturn'],
    key: 'monthlyReturn',
  },
  {
    title: 'Status',
    render: StatusRenderer,
    key: 'status',
    align: 'center',
  },
]
