import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { videoFieldsFragment } from '../../fragments/video'

const query = gql`
    query Video($params: VideoInput) {
        video(params: $params) {
            ...videoFields
        }
    }
    ${videoFieldsFragment}
`

const useGetVideo = options => {
  return useQuery(query, options)
}

export default useGetVideo
