import gql from 'graphql-tag'
import {userFieldsFragment} from "./user"
export const logFieldsFragment = gql`
    fragment logFields on Log {
        uuid
        name
        description
        subjectId
        subjectType
        causerId
        causerType
        properties
        createdAt
        changes
        groupChanges {
            added {
                id
                name
            }
            removed {
                id
                name
            }
        }
        folderChanges {
            added
            removed
        }
        tagChanges {
            added {
                uuid
                name
            }
            removed {
                uuid
                name
            }
        }
        authorChanges {
            added {
                uuid
                name
            }
            removed {
                uuid
                name
            }
        }
        causedBy {
            ...userFields
        }
    }
    ${userFieldsFragment}
`
