import * as React from 'react'

import Col from '../../../Col/Col'
import Row from '../../../Row/Row'
import { box, value, header, title } from './AdditonalPerformance.module.scss'

const formatKey = (key = '') => {
  if (key.toLowerCase() !== 'sharpe ratio') {
    return `${key} (%)`
  }
  return key
}

export default ({ additionalPerformance = {} }) => {
  return (
    <>
      <h2 className={header}>additional performance data</h2>
      <Row inlineRow>
        {Object.keys(additionalPerformance).map(key => (
          <Col key={key} large={3} medium={3} small={6}>
            <div className={box}>
              <div className={title}>{formatKey(key)}</div>
              <div id={`AdditionalPerformance-item${key.split(' ').join('')}`} className={value}>
                {additionalPerformance[key].toFixed(2)}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}
