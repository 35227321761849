import React from 'react'

export const EVENT_CATEGORIES = {
  documents: 'documents',
  funds: 'funds'
}

export const EVENT_ACTIONS = {
  viewDocumentsList: 'View document list',
  viewFile: 'View file',
  downloadFile: 'Download file',
  viewFundList: 'View funds list',
  viewFund: 'View fund'
}

export const EVENT_LABELS = {
  viewDocumentsList: '[View documents list]',
  viewFile: '[View file]',
  downloadFile: '[Download file]',
  viewFundList: '[View funds list]',
  viewFund: '[View fund]'
}

export const CUSTOM_DIMENSION = {
  Username: 'Username',
  Path: 'Path'
}

const useGtag = () => {
  const gtag = window.gtag || function(...params) {
    // TODO: Debug GA events in production
    console.log("window.gtag not loaded", {params})
  }

  const sendEvent = ({ action, category, label, value, customDimensions}) => {
    let data = {
      'event_category': category,
      'event_label': label,
      value
    }

    if (customDimensions){
      // gatsby plugin doesn't allow us to define custom_map so have to manually set it here
      gtag('config', process.env.GATSBY_GA_TRACKING_ID, {
        'custom_map': {'dimension1': CUSTOM_DIMENSION.Username, 'dimension2': CUSTOM_DIMENSION.Path}
      });
      data = {
        ...data,
        ...customDimensions
      };
    }

    gtag('event', action, {
      ...data
    })
  }

  return {
    sendEvent,
  }
}

export default useGtag
