import React, { useContext } from 'react'
import { Card, Form, Select } from 'antd'
import FormInput from '../../../ui/FormInput'
import FormItem from '../../../ui/FormItem'
import { STRATEGY_INTEREST_OPTIONS, TYPE_OF_USER_OPTIONS } from '../constants'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import UserLevelOptions from './UserLevelOptions'
import UserEditContext from './UserEditContext'
import Actions from './Actions'
import Permissions from './Permissions'
import CrmId from './CrmId/CrmId'
import { countryList, selectCountryFilter } from 'src/utils/helpers/countries'

const EditForm = () => {
  const {
    isCreate,
    user,
    updateState,
    errors,
    updateUserData,
    getState,
    resetPasswordState,
    createState,
  } = useContext(UserEditContext)

  const showPermissions = user.pending || isCreate

  return (
    <LoadingOverlay
      loading={getState.loading || updateState.loading || resetPasswordState.loading || createState.loading}>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
        <Card title='User information'>
          <FormInput
            disabled={!isCreate}
            name='email'
            label='Email'
            value={user.email}
            error={errors.email}
            onChange={updateUserData} />
          <FormInput
            disabled={!isCreate}
            name='givenName'
            label='First Name'
            value={user.givenName}
            error={errors.givenName}
            onChange={updateUserData} />
          <FormInput
            disabled={!isCreate}
            name='familyName'
            label='Last Name'
            value={user.familyName}
            error={errors.familyName}
            onChange={updateUserData} />
          <FormInput
            name='company'
            label='Company'
            value={user.company}
            error={errors.company}
            onChange={updateUserData} />
          <FormInput
            addonAfter={<CrmId user={user} onChange={updateUserData} />}
            name='crmId'
            label='CRM ID'
            value={user.crmId}
            error={errors.crmId}
            onChange={updateUserData} />
          {showPermissions && (
            <FormItem label="Permissions" error={errors.groups}>
              <Permissions />
            </FormItem>
          )}
          <FormInput
            name='street'
            label='Street'
            value={user.street}
            error={errors.street}
            onChange={updateUserData} />
          <FormInput
            name='city'
            label='City'
            value={user.city}
            error={errors.city}
            onChange={updateUserData} />
          <FormItem label="Country" error={errors.country}>
            <Select
              showSearch
              placeholder="Select Country"
              name="country"
              value={user.country}
              filterOption={selectCountryFilter}
              onChange={value => {
                updateUserData('country', value)
              }}>
              {countryList.map(({ name, code }) => (
                <Select.Option key={code} value={name}>{name}</Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormInput
            name='phoneNumber'
            label='Phone'
            value={user.phoneNumber}
            error={errors.phoneNumber}
            onChange={updateUserData} />
          <FormItem label="Strategy Interest" error={errors.strategyInterest}>
            <Select
              placeholder="Select Strategy Interest"
              name="strategyInterest"
              value={user.strategyInterest}
              onChange={value => {
                updateUserData('strategyInterest', value)
              }}>
              {STRATEGY_INTEREST_OPTIONS.map(value => (
                <Select.Option key={value} value={value}>{value}</Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Type of User" error={errors.typeOfUser}>
            <Select
              placeholder="Select Type of User"
              name=""
              value={user.typeOfUser}
              onChange={value => {
                updateUserData('typeOfUser', value)
              }}>
              {TYPE_OF_USER_OPTIONS.map(value => {
                return <Select.Option key={value} value={value}>{value}</Select.Option>
              })}
            </Select>
          </FormItem>
          <UserLevelOptions user={user} onChange={updateUserData} />
          <Actions />
        </Card>
      </Form>
    </LoadingOverlay>
  )
}

export default EditForm
