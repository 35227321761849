import gql from 'graphql-tag'
import useMutation from '../useMutation'
import { groupFieldsFragment } from '../../fragments/group'

export const createGroupMutation = gql`
    mutation CreateGroup($params: CreateGroupInput!) {
        createGroup(params: $params){
            ...groupFields
        }
    }
    ${groupFieldsFragment}
`
export const updateGroupMutation = gql`
    mutation UpdateGroup($params: CreateGroupInput!) {
        updateGroup(params: $params){
            ...groupFields
        }
    }
    ${groupFieldsFragment}
`

const useSaveGroup = ({ params, ...options } = {}) => {
  const { uuid } = params
  return useMutation(uuid ? updateGroupMutation : createGroupMutation, {
    variables: { params }, ...options,
  })
}

export default useSaveGroup
