import { Menu } from 'antd'
import {
  GroupOutlined,
  UserOutlined,
  FundOutlined,
  FolderOpenOutlined,
  UserAddOutlined,
  BarChartOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  HomeOutlined,
} from '@ant-design/icons'
import React from 'react'
import { navigate } from 'gatsby-link'
import { useLocation } from '@reach/router'
import useGetUsers from 'src/apollo/query/users/useGetUsers'
import styled from 'styled-components'

const menuItems = [
  { activePaths: ['dashboard'], key: 'dashboard', title: 'Dashboard' },
  { activePaths: ['funds/bulk-upload-data'], key: 'bulkUploadData', title: 'Bulk Upload Data' },
  { activePaths: ['funds/warning'], key: 'warning', title: 'Warnings' },
  { activePaths: ['funds'], key: 'funds', title: 'Funds' },
  { activePaths: ['documents'], key: 'documents', title: 'Documents' },
  { activePaths: ['users'], key: 'users', title: 'Users' },
  { activePaths: ['requests'], key: 'requests', title: 'Requests' },
  { activePaths: ['groups'], key: 'groups', title: 'Groups' },
  { activePaths: ['reports'], key: 'reports', title: 'Reports' },
  { activePaths: ['team', 'people'], key: 'team', title: 'Team' },
  { activePaths: ['insights'], key: 'insights', title: 'Insights' },
  { activePaths: ['videos'], key: 'videos', title: 'Videos' },
]

const getSelectedKey = ({ pathname }) => {
  const isActive = item => {
    return item.activePaths.some(i => pathname.includes(i))
  }
  const format = item => {
    return item.key
  }
  return menuItems
    .filter(isActive)
    .slice(0, 1)
    .map(format)
}

const VerticalMenu = styled(Menu)`
  background: #00275a !important;
  color: white !important;
  .ant-menu-item-selected {
    color: white !important;
    background: #001b3d !important;
  }
  .ant-menu-item::after {
    border-right: 3px solid #001b3d;
  }
  .ant-menu-item:hover {
    color: white;
    opacity: 0.7;
  }
`
const Logo = styled.img`
  height: ${({ small }) => (small ? '40' : '70')}px;
  margin: ${({ small }) => (small ? '10' : '20')}px;
`
const LogoMenuItem = styled(Menu.Item)`
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
`
const ItemGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title {
    color: white;
    opacity: 0.6;
    margin: ${({ small }) => (small ? '0 10px' : '0')};
  }
`

const Submenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title {
    color: white !important;
    &:hover {
      opacity: 0.7;
    }
  }

  .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-arrow::before {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)) !important;
  }

  ul {
    background: #00275a !important;
    color: white !important;
  }
`

const AdminMenu = ({ collapsed }) => {
  const { data } = useGetUsers({ params: { pending: true, perPage: 100 } })
  const count = data?.users?.length || 0
  const location = useLocation()
  const handleNavigate = link => {
    return () => navigate(link)
  }
  const key = getSelectedKey(location)

  return (
    <VerticalMenu mode="inline" forceSubMenuRender style={{ height: '100%' }} selectedKeys={key}>
      <LogoMenuItem onClick={handleNavigate('/portal/admin/dashboard')} key="logo">
        <Logo src="/logo.svg" small={collapsed ? 1 : 0} />
      </LogoMenuItem>
      <ItemGroup small={collapsed ? 1 : 0} key="g1" title="Portal">
        <Menu.Item onClick={handleNavigate('/portal/admin/dashboard')} icon={<HomeOutlined />} key="dashboard">
          Dashboard
        </Menu.Item>
        <Submenu key="s1" icon={<FundOutlined />} title="Funds">
          <Menu.Item onClick={handleNavigate('/portal/admin/funds')} key="funds">
            List
          </Menu.Item>
          <Menu.Item onClick={handleNavigate('/portal/admin/funds/bulk-upload-data')} key="bulkUploadData">
            Bulk Upload Data
          </Menu.Item>
          <Menu.Item onClick={handleNavigate('/portal/admin/funds/warning')} key="warning">
            Warnings
          </Menu.Item>
        </Submenu>
        <Submenu key="s2" icon={<FolderOpenOutlined />} title="Documents">
          <Menu.Item onClick={handleNavigate('/portal/admin/documents')} key="documents">
            List
          </Menu.Item>
          <Menu.Item onClick={handleNavigate('/portal/admin/documents/pending')} key="documents-pending">
            Pending
          </Menu.Item>
        </Submenu>
        <Menu.Item onClick={handleNavigate('/portal/admin/groups')} icon={<GroupOutlined />} key="groups">
          Groups
        </Menu.Item>
        <Menu.Item onClick={handleNavigate('/portal/admin/users')} icon={<UserOutlined />} key="users">
          Users
        </Menu.Item>
        <Menu.Item onClick={handleNavigate('/portal/admin/requests')} icon={<UserAddOutlined />} key="requests">
          User Requests
          {!!count && ` (${count})`}
        </Menu.Item>
      </ItemGroup>
      <ItemGroup small={collapsed ? 1 : 0} key="g2" title="Website">
        <Menu.Item onClick={handleNavigate('/portal/admin/team')} icon={<TeamOutlined />} key="team">
          Team
        </Menu.Item>
        <Menu.Item onClick={handleNavigate('/portal/admin/insights')} icon={<FileTextOutlined />} key="insights">
          Insights
        </Menu.Item>
        <Menu.Item onClick={handleNavigate('/portal/admin/videos')} icon={<VideoCameraOutlined />} key="videos">
          Videos
        </Menu.Item>
      </ItemGroup>
      <ItemGroup small={collapsed ? 1 : 0} key="g3" title="Reporting">
        <Menu.Item onClick={handleNavigate('/portal/admin/reports')} icon={<BarChartOutlined />} key="reports">
          Portal Reports
        </Menu.Item>
      </ItemGroup>
    </VerticalMenu>
  )
}

export default AdminMenu
