import React from 'react'
import { notification } from 'antd'
import {
  addFootnotesToValue,
  getFootnoteRef,
  getFootnotesFromValue,
  hasFootnoteRef, removeAllFootnotes,
  removeFootnoteMarkdown,
} from './helpers'

const useFootnotes = (value, codemirrorInstance, onChange) => {
  const [footnotes, setFootnotes] = React.useState([])
  const [footnotesLoaded, setFootnotesLoaded] = React.useState(false)
  const text = removeAllFootnotes(value)

  const footnotesRef = React.useRef(footnotes)
  React.useEffect(() => {
    footnotesRef.current = footnotes
  }, [footnotes])

  const codeMirrorRef = React.useRef(codemirrorInstance)
  React.useEffect(() => {
    codeMirrorRef.current = codemirrorInstance
  }, [codemirrorInstance])

  const checkPositionIsValid = index => {
    if (index === 0) {
      return true
    }
    const codemirror = codeMirrorRef.current
    const pos = codemirror.getCursor()
    const text = codemirror.doc.getRange({ ch: 0, line: 0 }, pos)
    return hasFootnoteRef(text, index - 1)
  }

  const handleInsertFootnoteNumberInText = index => {
    const codemirror = codeMirrorRef.current
    const pos = codemirror.getCursor()
    codemirror.setSelection(pos, pos)
    codemirror.replaceSelection(`${getFootnoteRef(index)}`)
  }

  const addFootnote = (_, indexOverride) => {
    const index = indexOverride ?? footnotesRef.current.length
    if (checkPositionIsValid(index)) {
      if (indexOverride === undefined) { // if index is provided assume the footnote exists
        setFootnotes([...footnotesRef.current, 'Edit me'])
      }
      handleInsertFootnoteNumberInText(index)
    } else {
      notification.error({ message: `The footnote position is not valid. Footnote [${index + 1}] must come after [${index}]` })
    }
  }

  const handleRemoveFootnoteNumberFromText = index => {
    const codemirror = codeMirrorRef.current
    const text = getFootnoteRef(index)
    const pos = codemirror.getSearchCursor(text)
    if (pos.findNext()) {
      codemirror.setSelection(pos.from(), pos.to())
      codemirror.replaceSelection(``)
    }
  }

  const removeFootnote = index => {
    const newData = [...footnotesRef.current]
    newData.splice(index, 1)
    setFootnotes(newData)
    handleRemoveFootnoteNumberFromText(index)
  }

  const updateFootnote = (text, index) => {
    const newData = [...footnotesRef.current]
    newData.splice(index, 1, text)
    setFootnotes(newData)
  }

  React.useEffect(() => {
    if (value && !footnotesLoaded) {
      setFootnotes(getFootnotesFromValue(value).map(removeFootnoteMarkdown))
      setFootnotesLoaded(true)
    }
  }, [value])

  React.useEffect(() => {
    if (footnotesLoaded) {
      onChange(addFootnotesToValue(text, footnotes))
    }
  }, [footnotes])

  return {
    text,
    footnotes,
    addFootnote,
    removeFootnote,
    updateFootnote,
  }
}

export default useFootnotes
