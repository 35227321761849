import React from 'react'
import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import { get, omit } from 'lodash'
import { getErrorsAsObject } from 'src/helpers/apollo'
import { FileOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { createPersonMutation, updatePersonMutation } from 'src/apollo/mutation/people/SavePerson'
import { useLazyQuery } from '@apollo/client'
import { PERSON_STATUS_ARCHIVED, PERSON_STATUS_PUBLISHED } from 'src/constants/constants'
import { newIndexQuery } from 'src/apollo/query/people/newIndex'
import { useMutation } from 'src/apollo/mutation'
import { buildSlugWithUuid } from 'src/utils/helpers/insight'
import LogViewDrawer from '../../../LogViewer/LogViewDrawer'
import { PERSON_SUBJECT_TYPE } from '../../../LogViewer/constants'
import ActionButtons from '../../../ActionButtons/ActionButtons'

const handleOnCompleted = ({ createPerson, updatePerson }) => {
  if (createPerson) {
    notification.success({ message: 'The person was created' })
  } else {
    if (get(updatePerson, 'status') === PERSON_STATUS_ARCHIVED) {
      notification.success({ message: 'The person was archived' })
    } else {
      notification.success({ message: 'The person was updated' })
    }
  }
  navigate('/portal/admin/team')
}

const Actions = ({ fields, loading: disabled, onError }) => {
  const { uuid, status, firstName, lastName } = fields
  const isCreate = uuid === 'create'
  const allFields = { ...fields, path: buildSlugWithUuid(`${firstName}-${lastName}`) }
  const params = isCreate ? omit(allFields, 'uuid') : allFields
  const updateMutation = isCreate ? createPersonMutation : updatePersonMutation

  const [savePerson, { loading: updateLoading }] = useMutation(updateMutation, {
    variables: { params },
    onCompleted: handleOnCompleted,
    onError: error => {
      onError(getErrorsAsObject(error))
      notification.error({ message: 'There was a problem with your input' })
    },
  })

  const [unArchive, { loading: unArchiveLoading }] = useLazyQuery(newIndexQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ newIndex: index }) => {
      savePerson(
        {
          variables: { params: { ...params, index, status: PERSON_STATUS_PUBLISHED } },
        })
    },
  })

  const loading = updateLoading || unArchiveLoading

  const archive = () => {
    return savePerson({ variables: { params: { ...params, status: PERSON_STATUS_ARCHIVED } } })
  }

  const save = () => {
    return savePerson({ variables: { params: { ...params, status: PERSON_STATUS_PUBLISHED } } })
  }

  const actions = [
    {
      children: !uuid ? 'Create Person' : 'Save Person',
      onClick: save,
      disabled,
      loading,
      icon: !uuid ? <PlusOutlined/> : <SaveOutlined/>,
    },
    {
      children: 'Archive',
      onClick: archive,
      disabled,
      icon: <FileOutlined/>,
    },
    {
      children: 'Unarchive',
      onClick: unArchive,
      disabled: status !== PERSON_STATUS_ARCHIVED,
    },
    {
      children: (
        <LogViewDrawer subjectId={uuid} subjectType={PERSON_SUBJECT_TYPE}>
          <span>Activity Log</span>
        </LogViewDrawer>
      ),
    },
  ]

  return <ActionButtons actions={actions}/>
}

Actions.propTypes = {
  fields: PropTypes.object.isRequired,
  onError: PropTypes.func,
}

Actions.defaultProps = {
  onError: () => null,
}

export default Actions
