import React from 'react'
import { Modal, Typography } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const { Text } = Typography

const Name = styled.span`
  margin-left: 10px;
`
const VisibilityConfirm = ({ open, toggle, onSave, changes, loading }) => {
  React.useEffect(() => {
    if (!loading) {
      toggle(false)
    }
  }, [loading])
  return (
    <Modal
      visible={open}
      onOk={onSave}
      onCancel={() => toggle(false)}
      closable={false}
      okText="Save changes"
      confirmLoading={loading}
    >
      {changes.map(({ name, visible }) => (
        <div key={name}>
          <Text type={visible ? 'success' : 'danger'}>
            {visible ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
            <Name>{name}</Name>
          </Text>
        </div>
      ))}
    </Modal>
  )
}

export default VisibilityConfirm
