import React from 'react'
import { Tag } from 'antd'
import {
  PERSON_STATUS_PUBLISHED,
  PERSON_STATUS_ARCHIVED
} from 'src/constants/constants'
import colors from 'src/utils/constants/colors'
import PropTypes from 'prop-types'

const getStatusColor = (status, previewStatus) => {
  if (previewStatus) {
    return colors.warning
  }
  switch (status) {
    case PERSON_STATUS_ARCHIVED:
      return colors.error
    case PERSON_STATUS_PUBLISHED:
      return colors.success
    default:
      return null
  }
}

const StatusRenderer = ({ status, previewStatus }) => {
  const text = previewStatus ? 'pending' : status
  return status || previewStatus ? (
    <Tag color={getStatusColor(status, previewStatus)}>{text}</Tag>
  ) : null
}

StatusRenderer.propTypes = {
  status: PropTypes.string,
  previewStatus: PropTypes.string
}

StatusRenderer.defaultProps = {
  status: null,
  previewStatus: null
}

export default StatusRenderer
