import * as React from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/light.css'
import Modal from '../../../Modal/Modal'
import { form } from './Popup.module.scss'
import './Popup.scss'

export default ({ open, closePopup }) => (
  <Modal open={open} cancelClick={closePopup} primaryButton="Filter" primaryClick={closePopup} header="Filter by date range" >
    <form className={form}>
      <div>
        <Flatpickr placeholder="Start date (YYYY/MM/DD)" />
        <Flatpickr placeholder="End date (YYYY/MM/DD)" />
      </div>
    </form>
  </Modal>
)
