import React from 'react'
import PropTypes from 'prop-types'

const InsightsTitle = ({ title }) => {
  return `${title}`
}

InsightsTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default InsightsTitle
