import React from 'react'
import { DatePicker } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import useGetLogs from 'src/apollo/query/logs/useGetLogs'
import moment from 'moment'
import Table from '../Table/Table'
import defaultColumns from './columns'
import ChangesRenderer from './renderers/ChangesRenderer'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_START_PAGE = 1

const ExpandIcon = ({ record: { name, changes, groupChanges, folderChanges, tagChanges, authorChanges }, expanded, onExpand }) => {
  const isUpdate = name === 'updated'
  const shouldExpand = !!changes || !!groupChanges || !!folderChanges || !!tagChanges || !!authorChanges

  return isUpdate && shouldExpand ? (
    expanded ? <UpOutlined onClick={onExpand}/> : <DownOutlined onClick={onExpand}/>
  ) : null
}

const LogViewer = ({ subjectType, subjectId, causerType, causerId, columns }) => {
  const [page, setPage] = React.useState(DEFAULT_START_PAGE)
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE)
  const [dateRange, setDateRange] = React.useState({
    startDate: moment().subtract(1, 'months').startOf('day'),
    endDate: moment().endOf('day'),
  })
  const variables = {
    params: {
      filter: {
        subjectType,
        subjectId,
        causerType,
        causerId,
        startDate: dateRange.startDate.format(),
        endDate: dateRange.endDate.format(),
      },
      order: [{ order: 'createdAt', orderDirection: 'DESC' }],
      limit: pageSize,
      page,
    },
  }

  const { data, loading } = useGetLogs({
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const { results, total } = data?.logs || {}

  const handlePageChange = (page) => {
    setPage(page)
  }

  const handlePageSizeChange = (current, selected) => {
    setPage(DEFAULT_START_PAGE)
    setPageSize(selected)
  }

  const handleDateRangeChange = ([start, end]) => {
    setPage(DEFAULT_START_PAGE)
    if (start && end) {
      setDateRange({
        startDate: start.startOf('day'),
        endDate: end.endOf('day'),
      })
    }
  }

  return (
    <>
      <DatePicker.RangePicker
        defaultValue={Object.values(dateRange)}
        onChange={handleDateRangeChange}/>
      <Table
        pagination={{
          pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '100'],
          current: page,
          total,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        showHeader={false}
        expandedRowRender={ChangesRenderer}
        expandIcon={ExpandIcon}
        expandRowByClick
        loading={loading}
        columns={columns}
        dataSource={results || []}/>
    </>
  )
}

LogViewer.propTypes = {
  subjectType: PropTypes.string,
  subjectId: PropTypes.string,
  columns: PropTypes.array,
}
LogViewer.defaultProps = {
  columns: defaultColumns
}

export default LogViewer
