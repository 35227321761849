import * as React from 'react'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import useGetDocuments from 'src/apollo/query/documents/useGetDocuments'
import useGetFund from 'src/apollo/query/funds/useGetFund'
import Row from '../../Row/Row'
import Col from '../../Col/Col'
import Error from '../Error/Error'
import LoadingOverlay from '../../Admin/ui/LoadingOverlay'
import DocumentTable from './DocumentTable'
import DocumentHeader from './Header/DocumentHeader'
import Menu from './Menu'
import PreviewFile from './PreviewFile'
import Loading from '../../Admin/ui/Loading'
import useGtag, { EVENT_ACTIONS, EVENT_CATEGORIES, EVENT_LABELS } from '../../../hooks/useGtag'
import { Button } from 'antd'
import { downloadButton } from './Documents.module.scss'
import useMultiDownload from './useMultiDownload'
import useGetAllDocumentsAndFiles from '../../../apollo/query/documents/useGetAllDocuments'
import useDownloadableChecklist from './useDownloadableChecklist'
import DownloadError from './DownloadError/DownloadError'

// Handle getting path for root directory in documents
// root directories path returns as ''. Therefore a directory/file needs to
// derive path from the meta from the file/directory
const getCurrentPath = (documents, path) => {
  const files = documents?.files || []
  const directories = documents?.directories || []

  if (path === '') {
    if (files.length) {
      return files[0]?.meta.root
    }
    if (directories.length) {
      return directories[0]?.meta.root
    }
  }

  return path
}

export default ({ fundPath }) => {
  const [emsoMode, setEmsoMode] = React.useState(false)
  const [isQueryingDocuments, setIsQueryingDocuments] = React.useState(false)
  const [path, setPath] = React.useState('')
  const [filename, setFilename] = React.useState()
  const [builtBreadcrumbs, setBuiltBreadcrumbs] = React.useState([])
  const { sendEvent } = useGtag()
  const { data: fundData, loading: fundLoading } = useGetFund({
    params: { path: fundPath },
  })
  const fundUuid = fundData?.fund?.uuid

  const { data, loading: getLoading, error } = useGetDocuments({
    params: { fundUuid, path, emso: emsoMode },
    skip: !fundUuid,
    onCompleted: () => {
      sendEvent({
        category: EVENT_CATEGORIES.documents,
        action: EVENT_ACTIONS.viewDocumentsList,
        label: `${EVENT_LABELS.viewDocumentsList} ${emsoMode ? 'Emso' : fundData?.fund?.name}`,
      })
    },
  })

  const documents = data?.documents

  const {
    itemsChecked,
    handleCheckBoxClicked,
    isItemChecked,
    areAllItemsChecked,
    toggleAllItems,
    uncheckAllFiles,
    filesChecked,
    directoriesChecked,
  } = useDownloadableChecklist({
    files: documents?.files?.map(item => ({ ...item, path })),
    directories: documents?.directories?.map(item => ({ ...item, path })),
    path,
  })
  const directories = path.split('/')
  const lastDirectory = directories[directories.length - 1] || fundPath
  const isEstimateDirectory = lastDirectory.toUpperCase().includes('ESTIMATE')
  const currentPath = getCurrentPath(documents, path)
  const { downloading, downloadMultipleFiles, filesToDownload, filesFailedToDownload, retryDownload, clearErrors } = useMultiDownload()

  const { data: documentData } = useGetDocuments({
    params: { fundUuid, path: currentPath, emso: emsoMode },
    skip: !fundUuid,
    onCompleted: () => {
      sendEvent({
        category: EVENT_CATEGORIES.documents,
        action: EVENT_ACTIONS.viewDocumentsList,
        label: `${EVENT_LABELS.viewDocumentsList} ${emsoMode ? 'Emso' : fundData?.fund?.name}`,
      })
    },
  })

  const getAllDocumentsAndFiles = useGetAllDocumentsAndFiles({ fundUuid, path: currentPath, emso: emsoMode })
  const allowDownloadFile = !(documentData?.documents?.meta?.readOnly || documentData?.documents?.meta?.pathReadOnly)

  async function buildBreadcrumbs() {
    if (documents) {
      const crumbs = documents.breadcrumbs.map(crumb => ({
        value: crumb.name,
        onClick: () => {
          if (crumb.path === '' && emsoMode) {
            return
          }
          setPath(crumb.path)
        },
      }))
      setBuiltBreadcrumbs(crumbs)
    }
  }

  React.useEffect(() => {
    buildBreadcrumbs()
  }, [documents])

  if (error) {
    return <Error />
  }

  const openFolder = (root, folder) => {
    setPath(`${root}/${folder}`)
  }

  const openFund = path => {
    setEmsoMode(false)
    setPath('')
    navigate(`/portal/documents/${path}`)
  }

  const openEmso = path => {
    setEmsoMode(true)
    setPath(path)
  }

  const loading = getLoading || fundLoading

  if (!documents && loading) {
    return <Loading loading={true} />
  }

  const pendingFilesToDownload = itemsChecked.length > 0 ? `Download ${itemsChecked.length} item(s)` : 'Download'
  const numberFilesDownloading = `Downloading ${filesToDownload.length} item(s)`
  const downloadButtonText = downloading ? numberFilesDownloading : pendingFilesToDownload

  const queryForAllDocuments = async () => {
    setIsQueryingDocuments(true);
    const directoryPaths = directoriesChecked.map(directory => directory.name)
    const allDirectories = directoryPaths.length > 0 ? await getAllDocumentsAndFiles(directoryPaths) : []
    setIsQueryingDocuments(false);

    return allDirectories;
  }

  const onDownloadFilesClicked = async () => {
    uncheckAllFiles()
    const allDirectories = await queryForAllDocuments();
    await downloadMultipleFiles(filesChecked, allDirectories, currentPath)
  }

  const hasDownloadError = filesFailedToDownload.length > 0 && !downloading
  const isCheckboxDisabled = isQueryingDocuments || downloading

  return (
    <div>
      <DownloadError
        open={hasDownloadError}
        handleCancel={clearErrors}
        handleRetryDownload={retryDownload}
        downloading={false}
        documents={filesFailedToDownload}
      />
      <Row inlineRow>
        <Button
          type="primary"
          className={downloadButton}
          loading={isCheckboxDisabled}
          disabled={itemsChecked.length === 0 || isCheckboxDisabled}
          onClick={onDownloadFilesClicked}
        >
          {downloadButtonText}
        </Button>
        <Menu breadcrumbs={builtBreadcrumbs} openFund={openFund} emsoMode={emsoMode} path={path} fundPath={fundPath} openEmso={openEmso} />
        <Col large={8} small={12}>
          {documents && (
            <>
              <DocumentHeader breadcrumbs={builtBreadcrumbs} />
              <LoadingOverlay loading={loading}>
                <DocumentTable
                  showCheckBoxForDirectories={true}
                  checkboxDisabled={isCheckboxDisabled}
                  currentDirectory={lastDirectory}
                  sortByData={isEstimateDirectory}
                  showVisibility={allowDownloadFile}
                  onToggleAll={toggleAllItems}
                  allFilesChecked={areAllItemsChecked}
                  data={[
                    ...documents.directories.map(directory => ({
                      name: directory.name,
                      id: `${directory.meta.root}-${directory.name}`,
                      onEdit: handleCheckBoxClicked,
                      type: 'folder',
                      visible: isItemChecked(directory.name),
                      date: dayjs(new Date(directory.meta.lastModified)).format('YYYY-MM-DD'),
                      onClick: () => openFolder(directory.meta.root, directory.name),
                    })),
                    ...documents.files.map(file => ({
                      name: file.name,
                      visible: isItemChecked(file.name),
                      date: dayjs(new Date(file.meta.lastModified)).format('YYYY-MM-DD'),
                      type: 'file',
                      onEdit: handleCheckBoxClicked,
                      onClick: () => {
                        setPath(file.meta.root)
                        setFilename(file.name)
                      },
                    })),
                  ]}
                />
              </LoadingOverlay>
            </>
          )}
        </Col>
        {filename && (
          <PreviewFile
            allowDownload={allowDownloadFile}
            path={path}
            fileName={filename}
            open={!!filename}
            closePopup={() => setFilename()}
          />
        )}
      </Row>
    </div>
  )
}
