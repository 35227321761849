import * as React from 'react'
import { Card, Alert, Row, Col, Tree, Modal, Typography, Button } from 'antd'
import LoadingOverlay from '../../ui/LoadingOverlay'
import PageHeader from '../../ui/PageHeader'
import ActionButtons from '../../ActionButtons/ActionButtons'
import useGetPendingFiles from '../../../../apollo/query/documents/useGetPendingFiles'
import { buildTreeFromFileList } from './helpers'
import useSetFileVisibility from 'src/apollo/mutation/documents/useSetFileVisibility'

const { DirectoryTree } = Tree

const ADD = 'add'
const CLEAR = 'clear'

const reducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [...state, action.payload]
    case CLEAR:
      return []
    default:
      return state
  }
}

const DocumentsPending = () => {
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [completedFiles, dispatchCompletedFiles] = React.useReducer(reducer, [])
  const [updateLoading, setUpdateLoading] = React.useState(false)

  const [fetchDocuments, { data, loading: getLoading, error, called }] = useGetPendingFiles({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    isLazy: true,
  })

  const documents = data?.pendingFiles || []

  const [setFileVisibility] = useSetFileVisibility({})

  const handleUpdate = async () => {
    setUpdateLoading(true)
    for (let { name, path } of selectedFiles) {
      const response = await setFileVisibility({
        variables: {
          params: {
            file: name,
            visible: true,
            path,
          },
        },
      })
      if(response?.data?.setFileVisibility) {
        dispatchCompletedFiles({type: ADD, payload: {name, path, completed: true}})
      } else {
        dispatchCompletedFiles({type: ADD, payload: {name, completed: false}})
      }
    }
    setUpdateLoading(false)
    fetchDocuments()
    setSelectedFiles([])
  }

  const loading = getLoading || updateLoading

  const actions = [
    {
      onClick: handleUpdate,
      children: `Make visible`,
      loading: updateLoading,
      disabled: !selectedFiles.length || loading,
    },
    {
      onClick: () => fetchDocuments(),
      children: `Fetch documents`,
      loading: loading,
      disabled: loading,
    },
  ]

  const hideModal = () => {
    dispatchCompletedFiles({type: CLEAR})
  }

  const treeData = buildTreeFromFileList(documents)

  const emptyText = called ? 'No pending files found' : 'Use the button above to search for pending files'

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Pending Documents' />
      <ActionButtons actions={actions} />
      <Row>
        <Col sm={24}>
          {!!error && (
            <Alert
              message="Something went wrong. Please refresh the page and try again"
              type="error"
            />
          )}
          <LoadingOverlay loading={loading}>
            <Card loading={getLoading}>
              {!!treeData.length ? (
                <DirectoryTree
                  checkable
                  multiple
                  onCheck={(keys, e) => {
                    const { checkedNodes } = e
                    setSelectedFiles(checkedNodes.filter(item => item.isLeaf))
                  }}
                  defaultExpandAll
                  treeData={treeData}
                />
              ) : emptyText}
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
      <Modal
        title="Files made visible"
        visible={completedFiles.length}
        onCancel={hideModal}
        onOk={hideModal}
        closable={false}
        footer={<Button onClick={hideModal}>Ok</Button>}
      >
        {completedFiles.map(item => (
          <div>
            <Typography.Text key={item.name + item.path}>{item.name}</Typography.Text>
          </div>
        ))}
      </Modal>
    </Col>
  )
}

export default DocumentsPending

