import React from 'react'
import gql from 'graphql-tag'
import Mutation from '../Mutation'
import { personFieldsFragment } from '../../fragments/person'

export const publishPeopleMutation = gql`
    mutation PublishPeople($params: PeoplePublishInput) {
        publishPeople(params: $params){
            results {
                ...personFields
                original {
                    ...personFields
                }
            }
        }
    }
  ${personFieldsFragment}
`

const PublishPeople = ({ children, uuids, ...props }) => {
  const params = { uuids }
  return (
    <Mutation mutation={publishPeopleMutation} {...props} variables={{ params }}>
      {(mutate, state) => {
        return children(mutate, state)
      }}
    </Mutation>
  )
}

export default PublishPeople
