import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const addTagToInsightsMutation = gql`
    mutation AddTagInsights($params: AddTagInput!) {
        addTagInsights(params: $params)
    }
`

const useAddTagInsights = ({ params, ...options } = {}) => {
  return useMutation(addTagToInsightsMutation, {
    variables: { params }, ...options,
  })
}

export default useAddTagInsights
