import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import Query from '../Query'
import { reportFieldsFragment } from '../../fragments/report'

export const query = gql`
  query Reports {
    reports {
      ...reportFields
    }
  }
  ${reportFieldsFragment}
`

const ReportsListQuery = ({ children, page, perPage, ...props }) => {
  const params = {
    page,
    perPage,
  }

  return (
    <Query query={query} variables={{ params }} {...props}>
      {data => {
        return children(data)
      }}
    </Query>
  )
}

ReportsListQuery.propTypes = {
  children: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
}

export default ReportsListQuery
