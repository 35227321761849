import * as React from 'react'
import { toast } from 'react-toastify'
import { get } from '../../../../helpers/remote'
import Row from '../../../Row/Row'
import Col from '../../../Col/Col'
import Error from '../../../Login/Error/Error'
import { IconYellowArrow } from '../../../../data/icons'
import Button from '../../../Button/Button'
import Loader from '../../../Login/Loader/Loader'
import logger from '../../logger'
import Empty from '../Empty'
import { colorLightBlue, addButtonRow, deleteIcon, icon, item } from '../Admin.module.scss'

export default () => {
  const [exports, setExports] = React.useState([])
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  async function getExports() {
    setLoading(true)
    try {
      const jsonFund = await get('admin', {
        method: 'getExports',
      })
      setExports(jsonFund)
      setLoading(false)
    } catch (_) {
      setError(true)
      setLoading(false)
    }
  }
  React.useEffect(() => {
    getExports()
  }, [])

  async function exportUsers() {
    if (confirm('Are you sure you wish to start an export job?')) {
      try {
        await get('admin', {
          method: 'exportUsers',
        })
        toast.info('Job Started')
      } catch (error) {
        toast.error('Error creating job, please try again')
        logger.error(`exportUsers - `, error)
      }
    }
  }

  async function deleteExport(name) {
    if (confirm('Are you sure you wish to delete this export?')) {
      try {
        setLoading(true)
        await get('admin', {
          method: 'deleteExport',
          name,
        })
        toast.success('Export deleted')
        setLoading(false)
        getExports()
      } catch (error) {
        setLoading(false)
        toast.error('Error deleting job, please try again')
        logger.error(`deleteExport - `, error)
      }
    }
  }

  async function downloadExport(name) {
    try {
      setLoading(true)
      const blob = await get(
        'admin',
        {
          method: 'downloadExport',
          name,
        },
        true,
      )
      const url = window.URL.createObjectURL(blob)
      const anchorElem = document.createElement('a')
      anchorElem.style = 'display: none'
      anchorElem.href = url
      anchorElem.download = name
      anchorElem.click()
      setLoading(false)
    } catch (error) {
      toast.error('Error downloading, please try again')
      logger.error(`downloadExport - `, error)
      setLoading(false)
    }
  }

  const noRecords = !loading && !error && !exports.length
  return (
    <>
      <Row>
        <Col>
          <Row inlineRow className={addButtonRow}>
            <Button onClick={exportUsers} type="portal">
              Export Users
            </Button>
          </Row>
          <hr />
          {error && <Error />}
          {noRecords && <Empty />}
          {loading && <Loader />}
          {!error &&
            exports.map(value => (
              <Row key={value.path} inlineRow className={item}>
                <Col large={8}>
                  <div className={content}>{value.name}</div>
                </Col>
                <div className={deleteIcon} onClick={() => deleteExport(value.name)}>
                  &times;
                </div>
                <div className={icon} onClick={() => downloadExport(value.name)}>
                  <IconYellowArrow direction="down" color={colorLightBlue} />
                </div>
              </Row>
            ))}
        </Col>
      </Row>
    </>
  )
}
