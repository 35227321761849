import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import styled, {css} from 'styled-components'


const StyledTable = styled(AntTable)`
${({thin}) => thin && css`
  .ant-table-tbody > tr > td {
    padding: 5px 5px;
  }
`}
`

const DEFAULT_PAGE_SIZE = 10
const Table = ({ pagination, className, ...props }) => {
  const {
    total,
    onChange = () => null,
    onPageSizeChange = () => null,
    pageSize = DEFAULT_PAGE_SIZE,
    ...paginationProps
  } = pagination

  const pageSizeOptions = ['10', '20', '30', '100']

  const paginationValue = pagination === false
    ? false
    : {
      total,
      pageSize,
      showSizeChanger: true,
      onChange,
      onShowSizeChange: (current, pageSize) => {
        onPageSizeChange(pageSize)
      },
      pageSizeOptions,
      ...paginationProps,
    }

  return (
    <StyledTable
      className={className}
      rowKey='uuid'
      scroll={{ x: 400 }}
      pagination={paginationValue}
      {...props}
    />
  )
}

Table.propTypes = {
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

Table.defaultProps = {
  pagination: {},
}

export default Table
