import React from 'react'
import { Card } from 'antd'
import LogViewer from '../../../../LogViewer/LogViewer'
import columns from './columns'

const ActivityLog = ({userId}) => {
  return (
    <Card title="Activity log">
      <LogViewer causerId={userId} causerType="User" columns={columns} />
    </Card>
  )
}

export default ActivityLog