import * as React from 'react'
import { container, input, icon } from './Search.module.scss'

export default ({ name, date, type, id }) => {
  return null
  // TODO: Add search functionality to backend
  const [hover, setHover] = React.useState(false)
  return (
    <div className={container}>
      <input className={input} placeholder="Search" name="search" type="text" />
      <span>
        <span className={icon} />
      </span>
    </div>
  )
}
