import React from 'react'
import PropTypes from 'prop-types'
import TextWithColor from '../../../ui/TextWithColor'
import {
  CHANGE_STATUS_DELETED, CHANGE_STATUS_DELETED_TEXT,
  CHANGE_STATUS_NEW, CHANGE_STATUS_NEW_TEXT,
  CHANGE_STATUS_UPDATED,
  CHANGE_STATUS_UPDATED_TEXT
} from 'src/constants/constants'
import colors from "src/utils/constants/colors"

const getStatus = previewStatus => {
  switch (previewStatus) {
    case CHANGE_STATUS_UPDATED:
      return {
        text: CHANGE_STATUS_UPDATED_TEXT,
        color: colors.warning
      }
    case CHANGE_STATUS_NEW:
      return {
        text: CHANGE_STATUS_NEW_TEXT,
        color: colors.success
      }
    case CHANGE_STATUS_DELETED:
      return {
        text: CHANGE_STATUS_DELETED_TEXT,
        color: colors.error
      }
    default:
      return {
        text: null,
        color: null
      }
  }
}

const StatusRenderer = ({ previewStatus: status }) => {
  const {color, text} = getStatus(status)
  return text ? <TextWithColor color={color}> {text} </TextWithColor> : null
}

StatusRenderer.propTypes = {
  previewStatus: PropTypes.string.isRequired
}

export default StatusRenderer
