import { ActionsRenderer, DateRenderer } from './renderers'

export const reportListColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Date',
    render: DateRenderer,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Actions',
    align: 'right',
    render: ActionsRenderer,
  },
]
