import { get } from 'lodash'

const ADMIN_BASE_URL = '/portal/admin/'
const PORTAL_BASE_URL = '/portal/'

const routes = {
  admin: {
    insights: {
      edit: ({ uuid }) => `${ADMIN_BASE_URL}insights/edit/${uuid}`,
    },
    people: {
      edit: ({ uuid }) => `${ADMIN_BASE_URL}people/edit/${uuid}`,
    },
  },
  portal: {
    people: {
      view: ({ uuid }) => `${PORTAL_BASE_URL}people/${uuid}`,
    },
  },
}

const getRoute = (path, params) => get(routes, path)(params)

export {
  getRoute
}
