import gql from 'graphql-tag'
import useQuery from '../useQuery'
import { crmPersonFieldsFragment } from '../../fragments/crmPerson'

const query = gql`
    query CrmPerson($params: CrmPersonInput!) {
        crmPerson(params: $params) {
            ...crmPersonFields
        }
    }
    ${crmPersonFieldsFragment}
`

const useGetCrmPerson = ({ params, ...options } = {}) => {
  return useQuery(query, {
    variables: { params: { ...params } },
    ...options,
  })
}

export default useGetCrmPerson
