import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const removeTagFromInsightsMutation = gql`
    mutation RemoveTagInsights($params: AddTagInput!) {
        removeTagInsights(params: $params)
    }
`

const useRemoveTagInsights = ({ params, ...options } = {}) => {
  return useMutation(removeTagFromInsightsMutation, {
    variables: { params }, ...options,
  })
}

export default useRemoveTagInsights
