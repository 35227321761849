import gql from 'graphql-tag'
import { omit } from 'lodash'
import useMutation from '../useMutation'
import { fundFieldsFragment } from '../../fragments/fund'

export const createFundMutation = gql`
    mutation CreateFund($params: CreateFundInput!) {
        createFund(params: $params){
            ...fundFields
        }
    }
    ${fundFieldsFragment}
`
export const updateFundMutation = gql`
    mutation UpdateFund($params: CreateFundInput!) {
        updateFund(params: $params){
            ...fundFields
        }
    }
    ${fundFieldsFragment}
`

const useSaveFund = ({ params, ...options } = {}) => {
  const { uuid } = params

  return useMutation(uuid ? updateFundMutation : createFundMutation, {
    variables: {
      params: {
        ...omit(params, ['createdAt', 'updatedAt', '__typename', 'groups']),
        disclaimer: params.disclaimer || '',
      },
    },
    ...options,
  })
}

export default useSaveFund
