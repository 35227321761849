import React, { useState } from 'react'
import { Card, Select, Button, Col, Grid } from 'antd'
import { get, isEmpty } from 'lodash'
import styled from 'styled-components'
import CreateReport from '../../../../apollo/mutation/reports/CreateReport'
import ReportsListQuery from '../../../../apollo/query/reports/ReportsList'
import PageHeader from '../../ui/PageHeader'
import Table from '../../Table/Table'
import { reportListColumns } from './columns'
import UsersListQuery from "../../../../apollo/query/users/UsersList";

const { Option } = Select
const { useBreakpoint } = Grid

const SelectWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  ${({ screen }) => screen.xs && `
    flex-direction: column;
    .ant-select {
      width:100%;
      margin-bottom: 10px;
    }
  `}
`

const TextWrapper = styled.span`
  margin-right: 20px;
`

const REPORT_TYPE_USER_GROUPS = 'USERS_GROUPS'
const REPORT_TYPE_GROUPS_FOLDERS = 'GROUPS_FOLDERS'
const REPORT_TYPE_FUNDS_GROUPS = 'FUNDS_GROUPS'
const PAGE_SIZE = 10
const START_PAGE = 0

const ReportList = () => {
  const [type, setType] = useState('USERS_GROUPS')
  const [page, setPage] = React.useState(START_PAGE)
  const [limit, setLimit] = React.useState(PAGE_SIZE)
  const screen = useBreakpoint()

  if (isEmpty(screen)) {
    return null
  }

  const onChange = value => {
    setType(value)
  }

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Reports'/>
      <Card>
        <SelectWrapper screen={screen}>
          <div>
            <TextWrapper>
              Report type:
            </TextWrapper>
            <Select value={type} onChange={onChange}>
              <Option value={REPORT_TYPE_USER_GROUPS}>Users / Groups</Option>
              <Option value={REPORT_TYPE_GROUPS_FOLDERS}>Groups / Folders</Option>
              <Option value={REPORT_TYPE_FUNDS_GROUPS}>Funds / Groups</Option>
            </Select>
          </div>
          <CreateReport type={type} refetchQueries={['Reports']} awaitRefetchQueries>
            {(createReport, { loading }) => {
              return (
                <Button loading={loading} onClick={createReport} type='primary'>Create report</Button>
              )
            }}
          </CreateReport>
        </SelectWrapper>
      </Card>
      <Card>
        <ReportsListQuery
            page={page}
            perPage={limit}
        >
          {({ data, loading }) => {
            const reports = get(data, 'reports') || []
            return (
              <Table
                rowKey='uuid'
                loading={loading}
                scroll={{ x: 400 }}
                columns={reportListColumns}
                dataSource={reports}
                pagination={{
                  current: page + 1,
                  pageSize: limit,
                  onChange: page => {
                    setPage(page - 1)
                  },
                  onPageSizeChange: pageSize => {
                    setLimit(pageSize)
                  },
                }}
              />
            )
          }}
        </ReportsListQuery>
      </Card>
    </Col>
  )
}

export default ReportList
