import React, { useEffect } from 'react'
import { omit } from 'lodash'
import { Col, notification, Row } from 'antd'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import useResetUserPassword from 'src/apollo/mutation/users/useResetUserPassword'
import { userLevels } from 'src/helpers/auth'
import useGetUser from 'src/apollo/query/users/useGetUser'
import { getErrorsAsObject } from 'src/helpers/apollo'
import useSaveUser from 'src/apollo/mutation/users/useSaveUser'
import PageHeader from '../../../ui/PageHeader'
import UserPermissions from './../UserPermissions/UserPermissions'
import UserEditContext from './UserEditContext'
import Actions from './Actions'
import { handleUserUpdateCompleted } from './helpers'
import EditForm from './EditForm'
import CrmCategories from './CrmCategories/CrmCategories'
import useApproveUserRequest from '../../../../../apollo/mutation/users/useApproveUserRequest'
import ActivityLog from './ActivityLog/ActivityLog'
import FundList from './FundList/FundList'
import useDeleteUser from '../../../../../apollo/mutation/users/useDeleteUser'
import useResetUserMultiFactor from '../../../../../apollo/mutation/users/useResetUserMultiFactor'

const CategoriesAndFundsCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

const getGroupUuids = ({ groups = [] }) => groups
  .map(group => typeof group === 'string' ? group : group.uuid)
  .filter(uuid => uuid != null)

export default ({ userId }) => {
  const isCreate = userId === 'create'
  const [user, setUser] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [hasGroupChanges, setHasGroupChanges] = React.useState(false)
  const [permissionsOpen, setPermissionsOpen] = React.useState(false)
  const [permissionsLoading, setPermissionsLoading] = React.useState(false)

  const userFields = omit(user, 'funds')
  const createRequestFields = omit(userFields, ['emailVerified', 'id', 'level', 'pending', 'groups', 'crmId'])

  const getState = useGetUser({
    params: { id: userId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setUser(omit(data?.user, ['__typename']))
      }
    },
    skip: isCreate,
  })

  const [resetPassword, resetPasswordState] = useResetUserPassword({
    params: { email: user.email },
    onCompleted: () => {
      notification.success({ message: 'Password reset email has been sent' })
      if (!user.level) {
        navigate('/portal/admin/requests')
      }
    },
  })

  const [deleteUser, deleteState] = useDeleteUser({
    params: { id: userId },
    onCompleted: async () => {
      notification.success({ message: 'User was deleted' })
      navigate('/portal/admin/users')
    },
  })

  const [resetUserMultiFactor, resetUserMultiFactorState] = useResetUserMultiFactor({
    params: { id: userId },
    onCompleted: async () => {
      notification.success({ message: 'User 2fa reset' })
      navigate('/portal/admin/users')
    },
  })

  const [update, updateState] = useSaveUser({
    params: { ...userFields, groups: getGroupUuids(userFields) },
    onError: error => {
      setErrors(getErrorsAsObject(error))
    },
    refetchQueries: () => ['Users'],
    onCompleted: async ({ updateUser: { level } }) => {
      handleUserUpdateCompleted(user.level, level, resetPassword)
    },
  })

  const [createMutation, createState] = useApproveUserRequest({
    params: createRequestFields,
    onError: error => {
      setErrors(getErrorsAsObject(error))
    },
    onCompleted: async data => {
      const id = data?.createUserRequest?.id
      if (id) {
        notification.success({ message: 'User was created' })
        await promote(id)
      }
    },
  })

  const create = () => {
    if (!user.crmId) {
      setErrors({ ...errors, crmId: 'This field is required' })
      return
    }

    createMutation()
  }

  const promote = async id => {
    const groupUuids = getGroupUuids(userFields)
    if (groupUuids.length === 0) {
      setErrors({ ...errors, groups: 'Please add at least one permission group' })
      return
    }

    const level = user.level || userLevels.PORTAL_USER
    return update({
      variables: {
        params: {
          id,
          ...userFields,
          groups: groupUuids,
          level,
          pending: false,
          sendEmail: true,
        },
      },
    })
  }

  const enableUser = id => {
    const level = user.level || userLevels.PORTAL_USER
    return update({
      variables: {
        params: {
          id,
          ...userFields,
          groups: getGroupUuids(userFields),
          level,
          pending: false,
        },
      },
    })
  }

  const disable = () => {
    update({
      variables: { params: { ...userFields, groups: [], pending: false, level: 0 } },
    })
  }

  function updateUserData(item, value) {
    const updatedUserData = {
      ...user,
      [item]: value,
    }
    setUser(updatedUserData)
    setErrors({ ...errors, [item]: false })
  }

  useEffect(() => {
    if (hasGroupChanges) {
      update()
      setHasGroupChanges(false)
    }
  }, [user.groups])

  const handleGroupSave = groups => {
    setHasGroupChanges(true)
    updateUserData('groups', groups)
  }

  const contextValue = {
    isCreate,
    user,
    getState,
    resetPassword,
    resetPasswordState,
    update,
    updateState,
    deleteUser,
    deleteState,
    resetUserMultiFactor,
    resetUserMultiFactorState,
    create,
    createState,
    disable,
    promote,
    enableUser,
    setPermissionsOpen,
    permissionsLoading,
    updateUserData,
    errors,
  }

  return (
    <UserEditContext.Provider value={contextValue}>
      <Col xl={24}>
        <PageHeader to="/portal/admin/users" title={isCreate ? 'Create user' : 'Edit user'} />
        <Actions />
        <Row gutter={[20, 20]}>
          <Col lg={24} xl={14}>
            <EditForm />
          </Col>
          <CategoriesAndFundsCol lg={24} xl={10}>
            <CrmCategories id={user.crmId} saving={updateState.loading} />
            <FundList data={user?.funds || []} loading={getState.loading} />
          </CategoriesAndFundsCol>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xl={24}>
            <ActivityLog userId={userId} />
          </Col>
        </Row>
      </Col>
      <UserPermissions
        open={permissionsOpen}
        toggle={setPermissionsOpen}
        onLoadingChange={setPermissionsLoading}
        loading={updateState.loading}
        currentGroups={user.groups || []}
        onSave={handleGroupSave}
      />
    </UserEditContext.Provider>
  )
}
