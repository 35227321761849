import React from 'react'
import PropTypes from 'prop-types'


const PeopleTitle = ({ firstName, lastName }) => {
  return   `${firstName} ${lastName}`
}

PeopleTitle.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
}
export default PeopleTitle
