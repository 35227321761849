import React from 'react'
import { debounce, differenceBy } from 'lodash'
import { Select } from 'antd'
import styled from 'styled-components'
import useGetTags from 'src/apollo/query/tags/useGetTags'
import useAddTagInsights from 'src/apollo/mutation/insights/useAddTagInsights'
import useRemoveTagInsights from 'src/apollo/mutation/insights/useRemoveTagInsights'
import useCreateTag from 'src/apollo/mutation/tags/useCreateTag'
import LoadingOverlay from '../../../ui/LoadingOverlay'
import Tooltip from '../../../../Tooltip/Tooltip'

const DropdownWrapper = styled.div`
  li.ant-select-dropdown-menu-item-selected {
    display: none;
  }
`

const CreateWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%
`

const TagManager = ({ uuid }) => {
  const [search, setSearch] = React.useState('')
  const [selectDisabled, setSelectDisabled] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const { data, loading: allLoading } = useGetTags({
    params: { filter: { search }, limit: 5 },
    onCompleted: () => setSelectDisabled(false),
  })

  const { data: existingData, loading: existingLoading, refetch } = useGetTags({
    params: { filter: { taggableUuid: uuid, taggableType: 'insightVersion' } },
    notifyOnNetworkStatusChange: true,
    skip: !uuid,
  })

  const [addTag, { loading: addTagLoading }] = useAddTagInsights({
    onCompleted: () => {
      refetch()
      setOpen(false)
    },
  })

  const [removeTag, { loading: removeTagLoading }] = useRemoveTagInsights({
    onCompleted: refetch,
  })

  const [createTag, { loading: createTagLoading }] = useCreateTag({
    onCompleted: ({ createTag: { uuid: newTagUuid } }) => {
      addTag({ variables: { params: { uuid, tagUuid: newTagUuid } } })
    },
  })

  const allTags = data?.tags?.results || []
  const existingTags = existingData?.tags?.results || []

  const handleAdd = ({ key: tagUuid }) => {
    if (selectDisabled) {
      return
    }
    if (tagUuid) {
      addTag({ variables: { params: { uuid, tagUuid } } })
    }
    selectRef.current.blur()
    setTimeout(() => setOpen(false), 0)
  }

  const handleCreate = () => {
    createTag({
      variables: { params: { name: search } },
    })
    selectRef.current.blur()
    setTimeout(() => setOpen(false), 0)
  }
  const handleRemove = ({ key: tagUuid }) => {
    removeTag({ variables: { params: { uuid, tagUuid } } })
    setOpen(false)
  }

  const searchDebounce = debounce(query => {
    if (query) {
      setSearch(query)
    } else {
      setOpen(false)
    }
  }, 300)

  const handleSearch = searchQuery => {
    setOpen(true)
    setSelectDisabled(true)
    searchDebounce(searchQuery)
  }

  const loading = allLoading || addTagLoading || existingLoading || removeTagLoading || createTagLoading
  const remaining = differenceBy(allTags, [...existingTags], 'uuid')
  const selectRef = React.useRef()

  return (
    <Select
      ref={selectRef}
      open={open}
      disabled={!uuid || addTagLoading || existingLoading || removeTagLoading || createTagLoading}
      mode="multiple"
      autoClearSearchValue={false}
      loading={loading}
      value={existingTags.map(({ uuid, name }) => ({ key: uuid, label: name }))}
      placeholder="Select tags"
      filterOption={false}
      labelInValue
      onSearch={handleSearch}
      onSelect={handleAdd}
      onDeselect={handleRemove}
      onFocus={() => {
        setOpen(true)
      }}
      onBlur={() => {
        setOpen(false)
        setSearch('')
      }}
      dropdownRender={node => (
        <LoadingOverlay loading={loading}>
          {!loading && !remaining.length && (
            <div onMouseDown={e => e.preventDefault()}>
              <li className="ant-select-dropdown-menu-item">
                <CreateWrapper onClick={handleCreate}>
                  <span><b>{search}</b></span> <a>Create new tag</a>
                </CreateWrapper>
              </li>
            </div>
          )}
          <DropdownWrapper>
            {node}
          </DropdownWrapper>
        </LoadingOverlay>
      )}
    >
      {remaining.map(({ uuid, name }) => {
        return (
          <Select.Option key={uuid} value={uuid} label={name}>{name}</Select.Option>
        )
      })}
    </Select>
  )
}

export default TagManager
