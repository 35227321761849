import { userLevels } from 'src/helpers/auth'
import { get as getValue } from 'lodash'
import { notification } from 'antd'
import { navigate } from 'gatsby-link'

export const buildUserLevelOptions = (user, auth) => {
  const options = [
    {
      name: 'Disabled',
      value: 0,
    },
    {
      name: 'Portal User',
      value: userLevels.PORTAL_USER,
    },
  ]
  const userEmail = getValue(user, 'email', '')
  if (!userEmail.endsWith('@emso.com')) {
    return options
  }

  options.push(
    {
      name: 'User Admin',
      value: userLevels.USER_ADMIN,
    },
    {
      name: 'Admin',
      value: userLevels.ADMIN,
    },
    {
      name: 'Master',
      value: userLevels.MASTER,
    })

  const isMaster = auth.level() >= userLevels.MASTER

  return options.filter(level => (isMaster || (level.value < auth.level() && (!isMaster && level.value < 200))))
}

export const canUserEditThisUser = (user, auth) => {
  if (auth.level() >= userLevels.ADMIN) {
    return auth.level() >= user.level
  }
  return auth.level() > user.level
}

export const handleUserUpdateCompleted = (prevLevel, level, resetPassword) => {
  if (level > 0 && !prevLevel) {
    notification.success({ message: 'User was promoted' })
    resetPassword()
  }
  if (level === 0) {
    notification.success({ message: 'User was deleted' })
    navigate('/portal/admin/users')
  }
  if (level > 0 && prevLevel > 0) {
    notification.success({ message: 'User was updated' })
  }
}
