import React from 'react'
import { Select } from 'antd'
import useGetVideos from 'src/apollo/query/media/useGetVideos'
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer'

const VideoSelect = ({ url, onSelect }) => {

  const { data, loading } = useGetVideos({
    variables: {
      params: {
        filter: {
          status: 'finished',
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  const videos = data?.videos?.results || []

  const handleSelect = ({ key }) => {
    const video = videos.find(video => video.uuid === key)
    if (video) {
      onSelect(video.streamingUrl)
    }
  }

  const getValue = () => {
    if (!url) {
      return undefined
    }
    const video = videos.find(({ streamingUrl, uuid, name }) => streamingUrl === url)
    return video ? { key: video.uuid, label: video.name } : { key: url, label: url }
  }

  return (
    <div>
      <Select
        loading={loading}
        showSearch
        value={getValue()}
        labelInValue
        autoClearSearchValue={false}
        style={{ width: '100%' }}
        placeholder="Select video"
        notFoundContent="No videos available. Update the search filter"
        onSelect={handleSelect}
        filterOption={(inputValue, option) => {
          return option.props.label.includes(inputValue)
        }}
      >
        {videos.map(({ uuid, name }) => {
          return <Select.Option key={uuid} value={uuid} label={name}>{name}</Select.Option>
        })}
      </Select>
      {url && (
        <VideoPlayer src={url} controls fluid/>
      )}
    </div>
  )
}

export default VideoSelect
