import { API_PATH } from '../../config'
import auther from './auth'

const auth = auther()

export async function get(path, body, binary = false) {
  const fetchedData = await fetch(`${API_PATH}${path}`, {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      authorization: auth.getAccessToken(),
    },
  })
  if (fetchedData.status === 401) {
    auth.renewSession()
    return
  }
  if (!fetchedData.ok) {
    const errors = await fetchedData.json()
    throw errors
  }
  if (binary) {
    return fetchedData.blob()
  }
  return fetchedData.json()
}
