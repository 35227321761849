import * as React from 'react'
import { Helmet } from 'react-helmet'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import auth from 'src/helpers/auth'
import Layout from '../../../../layouts/Layout'
import Row from '../../../Row/Row'
import Col from '../../../Col/Col'
import PersonQuery from '../../../../apollo/query/people/Person'
import { getImageSource } from '../../../../utils/helpers/image'
import Loading from '../../../Admin/ui/Loading'
import Button from '../../../Button/Button'
import * as styles from './Member.module.scss'
import { teamPage } from './../Team.module.scss'
import Ticker from './Ticker'

export const Member = ({ uuid }) => {
  return (
    <Layout className={teamPage} getInTouchVisible={false} blueFooter>
      <Helmet>
        <title>Team - Emso</title>
      </Helmet>
      <div className={styles.blueBar}/>
      <PersonQuery uuid={uuid} withNext withPrevious version={auth().isAdmin()} returnPartialData>
        {({ data, loading }) => {
          const teamMember = get(data, 'person', {})
          const { image, location, firstName, lastName, title, bio, imageCredit, next, previous } = teamMember
          return (
            <>
              <div className={styles.content}>
                <Loading loading={!firstName && loading}>
                  <Row inlineRow className={classnames(!image && styles.center)}>
                    <Col small={12} medium={image ? 6 : 12} large={image ? 6 : 12}>
                      <div className={classnames(styles.container, image && styles.hasImage)}>
                        <div className={styles.location}>{location}</div>
                        <h1 className={styles.name}>
                          {firstName}
                          <br/>
                          {lastName}
                        </h1>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.text}>
                          {bio}
                        </div>
                      </div>
                    </Col>
                    {image && (
                      <Col small={12} medium={4} large={4} noPadding>
                        <div className={styles.photo}>
                          <img src={getImageSource(image)} alt={firstName}/>
                          <div className={styles.photoCred}>{imageCredit}</div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Loading>
              </div>
              <div className={styles.banner}>
                <Row>
                  <div className={styles.bannerContent}>
                    {!previous && <div className={styles.ticker}/>}
                    {!!previous && <Ticker member={previous} direction="left"/>}
                    <div>
                      <Button hoverClass={Button.blueHover} classNames={styles.button} to="/portal/team">
                        Team
                      </Button>
                    </div>
                    {!!next && <Ticker member={next} direction="right"/>}
                    {!next && <div className={styles.ticker}/>}
                  </div>
                </Row>
              </div>
            </>
          )
        }}
      </PersonQuery>
    </Layout>
  )
}

Member.propTypes = {
  select: PropTypes.func,
  selected: PropTypes.string,
}

export default Member
