import React from 'react'
import useGetFunds from 'src/apollo/query/funds/useGetFunds'
import { Card, Col, Table } from 'antd'
import styled from 'styled-components'
import Error from '../../../ui/Error'
import PageHeader from '../../../ui/PageHeader'
import { fundListColumns } from '../FundsList/columns'

const StyledTable = styled(Table)`
  .warning {
    color: red;
  }
`

const Warning = () => {
  const { data, loading, error } = useGetFunds({
    params: {
      overridePermissions: true,
    },
    fetchPolicy: 'cache-and-network',
  })

  const funds = data?.funds?.results || []
  const fundsWithWarning = funds.filter(fund => fund.warnings.length)

  if (error) return <Error/>

  return (
    <Col xl={24} xxl={18}>
      <PageHeader title='Funds with warning' />
      <Card>
        <StyledTable
          loading={loading}
          rowClassName={(record) => {
            if(record?.warnings?.length) {
              return "warning"
            }
          }}
          pagination={false}
          scroll={{ x: 400 }}
          columns={fundListColumns}
          dataSource={fundsWithWarning}/>
      </Card>
    </Col>
  )
}

export default Warning
