import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { navigate } from 'gatsby-link'
import InsightQuery from 'src/apollo/query/insights/Insight'
import { INSIGHTS_CREATE_PATH } from 'src/utils/constants/insights'
import { buildSlugWithUuid, mapResponseToState } from 'src/utils/helpers/insight'
import useCreateInsight from 'src/apollo/mutation/insights/useCreateInsight'
import { publishInsightsMutation } from 'src/apollo/mutation/insights/PublishInsights'
import { Col } from 'antd'
import ChangesNotification from '../../ChangesNotification/ChangesNotification'
import PageHeader from '../../../ui/PageHeader'
import Actions from './Actions'
import EditForm from './EditForm'

const defaultFields = { date: moment(), status: 'published' }

const InsightsEdit = ({ uuid: pathUuid }) => {
  const [fields, setFields] = React.useState(defaultFields)
  const [errors, setErrors] = React.useState({})
  const { title, type, path, uuid } = fields

  const setField = (field, value) => {
    setFields({ ...fields, [field]: value })
    setErrors({ ...errors, [field]: undefined })
  }

  const [createInsight, { loading: createInsightLoading }] = useCreateInsight({
    params: { status: 'draft' },
    onCompleted: ({ createInsight }) => {
      navigate(`/portal/admin/insights/edit/${createInsight.uuid}`)
    },
  })

  React.useEffect(() => {
    pathUuid === INSIGHTS_CREATE_PATH && createInsight()
  }, [])

  React.useEffect(() => {
    title && type && !path && setField('path', `/insights/${type}/${buildSlugWithUuid(title)}`)
  }, [title, type, path, uuid])

  const populateFieldsFromObject = ({ insight } = {}) => {
    if (insight) {
      setFields(mapResponseToState(insight))
    }
  }

  const isCreate = pathUuid === INSIGHTS_CREATE_PATH

  return (
    <InsightQuery
      version
      uuid={pathUuid}
      skip={isCreate}
      notifyOnNetworkStatusChange
      onCompleted={populateFieldsFromObject}>
      {({ data, loading }) => {
        const insight = get(data, 'insight') || {}
        return (
          <Col xl={24} xxl={18}>
            <PageHeader
              to='/portal/admin/insights'
              title={isCreate ? 'Create Insight' : 'Edit Insight'}/>
            <Actions insight={fields} onError={setErrors} loading={loading} isCreate={isCreate}/>
            <ChangesNotification
              mutation={publishInsightsMutation}
              item={insight}
              navigateTo='/portal/admin/insights'
            />

            <EditForm
              loading={loading || createInsightLoading}
              errors={errors}
              setErrors={setErrors}
              fields={fields}
              setField={setField}
              setFields={setFields}
              isCreate={isCreate}/>
          </Col>
        )
      }}
    </InsightQuery>
  )
}

export default InsightsEdit
