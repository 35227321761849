import React from 'react'
import { SaveOutlined, EyeOutlined } from '@ant-design/icons'
import { Card, Table } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ActionButtons from '../../ActionButtons/ActionButtons'
import { columns } from './columns'
import InsightsActions from './renderers/InsightsActions'
import InsightsTitle from './renderers/InsightsTitle'

const ButtonWrapper = styled.div`
  padding-top: 20px;
`

const StyledCard = styled(Card)`
  margin-bottom: 20px;
`

const ChangesSummary = ({ data, onPublish, previewLink, loading, actionsRenderer, titleRenderer }) => {

  const handlePreviewClick = () => {
    window.open(previewLink)
  }

  const actions = []

  if (onPublish) {
    actions.push({
      children: 'Publish all changes',
      onClick: onPublish,
      icon: <SaveOutlined/>,
      loading,
    })
  }

  if (previewLink) {
    actions.push({
      children: 'Preview Listing',
      onClick: handlePreviewClick,
      icon: <EyeOutlined/>,
    })
  }

  return data.length ? (
    <StyledCard title="Pending changes summary">
      <Table
        id="ChangesSummary-table"
        loading={loading}
        rowKey="uuid"
        columns={columns}
        dataSource={data.map(item => ({ ...item, actionsRenderer, titleRenderer }))}
        pagination={false}
        scroll={{ x: 400 }}
        showHeader={false}/>
      <ButtonWrapper>
        <ActionButtons actions={actions} size='default'/>
      </ButtonWrapper>
    </StyledCard>
  ) : null
}

ChangesSummary.propTypes = {
  data: PropTypes.array,
  onPublish: PropTypes.func,
  previewLink: PropTypes.string,
  loading: PropTypes.bool,
  actionsRenderer: PropTypes.elementType,
  titleRenderer: PropTypes.elementType,
}

ChangesSummary.defaultProps = {
  data: [],
  onPublish: () => null,
  previewLink: null,
  loading: false,
  actionsRenderer: InsightsActions,
  titleRenderer: InsightsTitle,
}
export default ChangesSummary
