import React from 'react'
import { EyeOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { get } from 'lodash'
import SaveInsight from 'src/apollo/mutation/insights/SaveInsight'
import { getErrorsAsObject } from 'src/helpers/apollo'
import {
  INSIGHT_STATUS_ARCHIVED,
  INSIGHT_STATUS_PUBLISHED
} from 'src/constants/constants'
import { mapStateToRequestParams, removeEmptyStrings } from 'src/utils/helpers/insight'
import LogViewDrawer from '../../../LogViewer/LogViewDrawer'
import { INSIGHT_SUBJECT_TYPE } from '../../../LogViewer/constants'
import ActionButtons from '../../../ActionButtons/ActionButtons'

const Actions = ({ insight, onError, loading: loadingProp, isCreate }) => {
  const { status, path } = insight
  const params = mapStateToRequestParams(insight)
  removeEmptyStrings(params)

  return (
    <SaveInsight
      params={params}
      onError={error => {
        onError(getErrorsAsObject(error))
        notification.error({ message: 'There was a problem with your input' })
      }}
      onCompleted={({ createInsight, updateInsight }) => {
        if (createInsight) {
          notification.success({ message: 'The post was created' })
        } else {
          if (get(updateInsight, 'status') === INSIGHT_STATUS_ARCHIVED) {
            notification.success({ message: 'The post was archived' })
          } else {
            notification.success({ message: 'The post was updated' })
          }
        }
      }}>
      {(mutate, { loading }) => {
        const archive = () => {
          return mutate({ variables: { params: { ...params, status: INSIGHT_STATUS_ARCHIVED } } })
        }
        const setAsPublished = () => {
          return mutate({ variables: { params: { ...params, status: INSIGHT_STATUS_PUBLISHED } } })
        }

        const isArchived = status === INSIGHT_STATUS_ARCHIVED

        const actions = [
          {
            children: !isCreate ? 'Save' : 'Create',
            loading,
            disabled: loadingProp || isArchived,
            onClick: setAsPublished,
            icon: !isCreate ? <SaveOutlined/> : <PlusOutlined/>,
          },
          {
            children: 'Preview',
            disabled: loadingProp || !path || isArchived,
            onClick: () => window.open(path),
            icon: <EyeOutlined/>,
          },
          {
            children: 'Archive',
            disabled: loadingProp || isArchived,
            onClick: archive,
          },
          {
            children: 'Unarchive',
            disabled: loadingProp || !isArchived,
            onClick: setAsPublished,
            icon: <EyeOutlined/>,
          },
          {
            children: (
              <LogViewDrawer subjectId={insight.uuid} subjectType={INSIGHT_SUBJECT_TYPE}>
                <span>Activity Logs</span>
              </LogViewDrawer>
            ),
          },
        ]

        return <ActionButtons actions={actions}/>
      }}
    </SaveInsight>
  )
}

export default Actions
