import moment from 'moment'

const DATE_FORMAT_CHART_POINT = "DD MMM"

const MIN_COUNT_SIGNUPS = 5
const MIN_COUNT_LOGINS = 5

 export const getNewUsersChartDataAndOptions = (data) => {
   const chartData = {
     labels: data?.userDailyStats?.map(item => item.date),
     plugins: {
       legend: {
         display: false,
       }
     },
     datasets: [
       {
         fill: true,
         lineTension: 0.4,
         backgroundColor: 'rgba(75,192,192,0.4)',
         borderColor: 'rgba(75,192,192,1)',
         borderDash: [],
         borderDashOffset: 0.0,
         borderJoinStyle: 'miter',
         pointBorderColor: 'rgba(75,192,192,1)',
         pointBackgroundColor: '#fff',
         pointBorderWidth: 1,
         pointHoverRadius: 5,
         pointHoverBackgroundColor: 'rgba(75,192,192,1)',
         pointHoverBorderColor: 'rgba(220,220,220,1)',
         pointHoverBorderWidth: 2,
         pointRadius: 1,
         pointHitRadius: 10,
         data: data?.userDailyStats?.map(item => item.signups + MIN_COUNT_SIGNUPS), // add 5 so that fill area has a minimum height
       },
     ],
   }

   const options = {
     plugins: {
       legend: {
         display: false,
       },
       tooltip: {
         displayColors: false,
         callbacks: {
           title: function(tooltipItem) {
             const value = tooltipItem[0].label;
             return moment(value).format(DATE_FORMAT_CHART_POINT)
           },
           label: function(tooltipItem) {
             return tooltipItem['dataset']['data'][tooltipItem.dataIndex] - MIN_COUNT_SIGNUPS + ' registrations'; // remove 5 to make up for the addition
           },
         },
         backgroundColor: 'rgba(75,192,192,1)'
       },
     },
     scales: {
       x : {
         display: false
       },
       y : {
         display: false
       }
     },
     elements: {
       line: {
         tension: 4
       }
     },
     layout: {
       padding: {
         top: 80,
       }
     },
     maintainAspectRatio: false
   }

   return [chartData, options]

 }
 export const getActiveUsersChartDataAndOptions = (data) => {
   const chartData = {
     labels: data?.userDailyStats?.map(item => item.date),
     plugins: {
       legend: {
         display: false,
       }
     },
     datasets: [
       {
         fill: true,
         lineTension: 0.4,
         backgroundColor: 'rgba(24, 144, 255, 1)',
         borderDash: [],
         borderDashOffset: 0.0,
         borderJoinStyle: 'miter',
         pointBorderColor: 'rgba(75,192,192,1)',
         pointBackgroundColor: '#fff',
         pointBorderWidth: 1,
         pointHoverRadius: 5,
         pointHoverBackgroundColor: 'rgba(75,192,192,1)',
         pointHoverBorderColor: 'rgba(220,220,220,1)',
         pointHoverBorderWidth: 2,
         pointRadius: 1,
         pointHitRadius: 10,
         data: data?.userDailyStats?.map(item => item.logins + MIN_COUNT_LOGINS), // add 20 so that fill area has a minimum height
       },
     ],
   }

   const options = {
     plugins: {
       legend: {
         display: false,
       },
       tooltip: {
         displayColors: false,
         callbacks: {
           title: function(tooltipItem) {
             const value = tooltipItem[0].label;
             return moment(value).format(DATE_FORMAT_CHART_POINT)
           },
           label: function(tooltipItem) {
             return tooltipItem['dataset']['data'][tooltipItem.dataIndex] - MIN_COUNT_LOGINS + ' logins'; // remove 20 to make up for the addition
           },
         },
         backgroundColor: 'rgba(24, 144, 255, 1)'
       },
     },
     scales: {
       x : {
         display: false
       },
       y : {
         display: false
       }
     },
     elements: {
       line: {
         tension: 4
       }
     },
     layout: {
       padding: {
         top: 45,
         left: 50
       }
     },
     maintainAspectRatio: false
   }

   return [chartData, options]

 }