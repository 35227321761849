import * as React from 'react'
import { navigate } from 'gatsby'
import { get } from 'lodash'
import { sortPeople } from 'src/utils/helpers/team'
import { getUnpublishedStatusIfHasChanges, isArchived } from 'src/utils/helpers/insight'
import PeopleQuery from 'src/apollo/query/people/PeopleList'
import LoadingOverlay from 'src/components/Admin/ui/LoadingOverlay'
import auth from 'src/helpers/auth'
import Row from '../../Row/Row'
import Col from '../../Col/Col'
import Heading from '../../Heading/Heading'
import SlickSlider from '../../SlickSlider/SlickSlider'
import { sliderImages } from '../../../data/team'
import ChangesBadge from '../../ChangesBadge/ChangesBadge'
import {
  team,
  department as departmentStyle,
  row,
  member as memberStyle,
  name,
  title,
  plus,
  plusImage,
  sliderContainer,
  slider,
  sliderImage,
  sliderWrapper,
  spacerThree,
} from './Team.module.scss'
import { filterPeopleByDepartment } from './hooks'
import Departments from './Departments/Departments'
import './Slider.scss'

export default () => {
  const [department, setDepartment] = React.useState('all')
  const isAdmin = auth().isAdmin()
  return (
    <PeopleQuery version={isAdmin} isTeamMember={true}>
      {({ data, loading }) => {
        const people = get(data, 'people.results', [])
        const items = React.useMemo(() => {
          return isAdmin ? getUnpublishedStatusIfHasChanges(people).filter(item => !isArchived(item)) : people
        }, [isAdmin, people])
        const orderedItems = sortPeople(items)
        const filteredTeam = React.useMemo(() => filterPeopleByDepartment(orderedItems, department), [items, department])
        return (
          <div className={team}>
            <Row>
              <Col>
                <Heading blue title="Our Team" content={['Emso is 100% owned', <br key={1} />, 'by our employees']} />
              </Col>
            </Row>
            <Row inlineRow>
              <div className={departmentStyle}>
                <Departments selected={department} select={setDepartment} />
              </div>
            </Row>
            <LoadingOverlay loading={loading}>
              <Row inlineRow className={row}>
                {filteredTeam.map(member => (
                  <div key={member.uuid} onClick={() => navigate(`/portal/people/${member.uuid}`)} className={memberStyle}>
                    <h2 className={name}>
                      {member.firstName}
                      <br />
                      {member.lastName}
                    </h2>
                    <h3 className={title}>{member.title}</h3>
                    <div className={plus}>
                      <div className={plusImage} />
                    </div>
                    <ChangesBadge status={member.previewStatus} />
                  </div>
                ))}
                {filteredTeam.length % 3 !== 0 && <div className={spacerThree} />}
              </Row>
            </LoadingOverlay>
            <div className={`${sliderContainer} portal-team`}>
              <SlickSlider
                className={slider}
                imageWrapperClassName={sliderWrapper}
                imageClassName={sliderImage}
                images={sliderImages}
                slickClassName={'portal-team'}
                settings={{
                  fade: false,
                  centerMode: true,
                  slidesToShow: 1,
                  variableWidth: true,
                  autoplay: false,
                  centerPadding: -15,
                  responsive: [
                    {
                      breakpoint: 755,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        variableWidth: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        )
      }}
    </PeopleQuery>
  )
}
