import { useApolloClient } from '@apollo/client'
import { getFileQuery } from './useGetFile'

const fetchFiles = async (apolloClient, params, zip, folderName, writeToZip) => {
  const response = await apolloClient.query({
    query: getFileQuery,
    variables: {
      params: { name: params.name, path: params.path },
    },
  })

  if (response?.data?.file) {
    const result = await fetch(response.data.file)
    const blob = await result.blob()
    const fileName = folderName !== '' ? `${folderName}/${params.name}` : params.name
    await writeToZip(zip, fileName, blob, params)
  }
}

export const useLazyGetFile = () => {
  const apolloClient = useApolloClient()

  return async (params, zip, folderName, writeToZip, handleError) => {
    return await fetchFiles(apolloClient, params, zip, folderName, writeToZip).catch(() => {
      handleError(params)
    })
  }
}
