import React from 'react'

let mdeInstance
const useMarkdownInstance = () => {
  const setInstance = i => {
    mdeInstance = i
  }
  return [setInstance, { codemirrorInstance: mdeInstance?.codemirror }]
}

export default useMarkdownInstance
