import React from 'react'
import styled from 'styled-components'
import { Button, Dropdown, Menu, Grid } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'

const { useBreakpoint } = Grid

const Wrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  ${({ screen }) =>
    screen.xs &&
    `
    flex-direction: column;
    margin-bottom: 0;
  `}
`

const StyledButton = styled(Button)`
  margin-left: 5px;
  ${({ screen }) =>
    screen.xs &&
    `
    margin-left: 0;
    margin-bottom: 5px;
  `}
`

const PRIMARY_BUTTON_ID = 'ActionButtons-primary'
const SECONDARY_BUTTON_ID = 'ActionButtons-secondary'

const ActionButtons = ({ actions, size, className }) => {
  const [lastButtonClicked, setLastButtonClicked] = React.useState(null)

  const handleClick = (action, id) => () => {
    setLastButtonClicked(id)
    if (action) {
      action()
    }
  }
  const [primaryAction, secondaryAction, ...dropDownActions] = actions
  const disabled = actions.some(item => item.loading)
  const screen = useBreakpoint()
  if (isEmpty(screen)) {
    return null
  }

  const baseButtonProps = {
    size: size || (screen.xs ? 'large' : 'default'),
    screen,
    block: screen.xs,
  }
  return (
    <Wrapper screen={screen} className={className}>
      {primaryAction && (
        <StyledButton
          id={PRIMARY_BUTTON_ID}
          {...baseButtonProps}
          icon={primaryAction.icon}
          onClick={handleClick(primaryAction.onClick, PRIMARY_BUTTON_ID)}
          disabled={primaryAction.disabled || disabled}
          loading={lastButtonClicked === PRIMARY_BUTTON_ID && primaryAction.loading}
          type="primary"
        >
          {primaryAction.children}
        </StyledButton>
      )}
      {secondaryAction && (
        <StyledButton
          id={SECONDARY_BUTTON_ID}
          {...baseButtonProps}
          icon={secondaryAction.icon}
          onClick={handleClick(secondaryAction.onClick, SECONDARY_BUTTON_ID)}
          disabled={secondaryAction.disabled || disabled}
          loading={lastButtonClicked === SECONDARY_BUTTON_ID && secondaryAction.loading}
        >
          {secondaryAction.children}
        </StyledButton>
      )}
      {!!dropDownActions.length && (
        <Dropdown
          disabled={false}
          overlay={
            <Menu>
              {dropDownActions.map((item, key) => (
                <Menu.Item
                  id={`ActionButtons-dropdown-${key}`}
                  key={key}
                  disabled={item.disabled || disabled}
                  onClick={item.onClick || undefined}
                >
                  {item.children}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <StyledButton {...baseButtonProps}>
            <DownOutlined />
          </StyledButton>
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default ActionButtons
