import React from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import FormItem from './FormItem'

const { TextArea } = Input

const FormInput = ({ type, name, value, label, error, onChange, ...rest }) => {
  const handleChange = ({ target: { value: rawValue } }) => {
    const value = type === 'number' ? parseFloat(rawValue) : rawValue
    onChange(name, value)
  }

  const InputType = type === 'textarea' ? TextArea : Input
  return (
    <FormItem label={label} error={error}>
      <InputType name={name} type={type} value={value} onChange={handleChange} {...rest}/>
    </FormItem>
  )
}

FormInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
}

FormInput.defaultProps = {
  type: 'text',
  name: null,
  value: null,
  label: null,
  error: null,
  onChange: () => null,
}

export default FormInput
