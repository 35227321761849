import React from 'react'
import { Radio, Input, Grid } from 'antd'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

const { useBreakpoint } = Grid

const Search = styled(Input.Search)`
  margin-right: 20px;

  .ant-input-affix-wrapper {
    height: 36px;
  }
`

const RadioGroup = styled(Radio.Group)`
  ${({ screen }) =>
    screen.xs &&
    `
    margin-top: 10px;
  `}
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  > :last-child {
    width: 100%;
  }
  ${({ screen }) =>
    screen.xs &&
    `
    flex-direction: column;
  `}
`

const Warning = styled.div`
  position: absolute;
  top: -22px;
  left: 0;
  color: red;
  font-size: 12px;
`

const Filters = ({ status, disabled, setDisabled, onSearch }) => {
  const [warning, setWarning] = React.useState(false)
  const screen = useBreakpoint()

  const handleSearch = value => {
    if (value.length > 0 && value.length < 3) {
      setWarning(true)
    } else {
      onSearch(value)
    }
  }

  const handleChange = e => {
    setWarning(false)
  }

  const handleStatusChange = e => {
    setDisabled(e.target.value)
  }

  if (isEmpty(screen)) {
    return null
  }
  return (
    <Wrapper screen={screen}>
      <Search onSearch={handleSearch} placeholder="Search..." onChange={handleChange} maxLength={5} allowClear />
      {warning && <Warning>Minimum 3 characters required</Warning>}
      <RadioGroup screen={screen} value={disabled} onChange={handleStatusChange} size="small">
        <Radio.Button value={false}>Active</Radio.Button>
        <Radio.Button value={true}>Disabled</Radio.Button>
      </RadioGroup>
    </Wrapper>
  )
}
export default Filters
