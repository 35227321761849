// extracted by mini-css-extract-plugin
export var department = "emso-Team-module__department";
export var member = "emso-Team-module__member";
export var name = "emso-Team-module__name";
export var plus = "emso-Team-module__plus";
export var plusImage = "emso-Team-module__plusImage";
export var row = "emso-Team-module__row";
export var slider = "emso-Team-module__slider";
export var sliderContainer = "emso-Team-module__sliderContainer";
export var sliderImage = "emso-Team-module__sliderImage";
export var sliderWrapper = "emso-Team-module__sliderWrapper";
export var spacerThree = "emso-Team-module__spacerThree";
export var spacerTwo = "emso-Team-module__spacerTwo";
export var team = "emso-Team-module__team";
export var teamPage = "emso-Team-module__teamPage emso-page-module__page";
export var title = "emso-Team-module__title";