import React from 'react'
import PropTypes from 'prop-types'


const TitleRenderer = ({ titleRenderer: Component, ...props }) => {
  return   <Component {...props}/>
}

TitleRenderer.propTypes = {
  titleRenderer: PropTypes.element.isRequired
}

export default TitleRenderer
