import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const deleteGroupMutation = gql`
    mutation DeleteGroups($params: DeleteInput!) {
        deleteGroup(params: $params)
    }
`

const useDeleteGroup = ({ params, ...options } = {}) => {
  return useMutation(deleteGroupMutation, {
    variables: { params }, ...options,
  })
}

export default useDeleteGroup
