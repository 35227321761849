import gql from 'graphql-tag'
import useQuery from '../useQuery'

export const getFileQuery = gql`
    query File($params: FileInput!) {
        file(params: $params) 
    }
`

const useGetFile = ({ params, ...options } = {}) => {
  return useQuery(getFileQuery, {
    variables: { params: { ...params } },
    ...options,
  })
}

export default useGetFile
