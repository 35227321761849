import React from 'react'
import styled from 'styled-components'
import { DATE_FORMAT } from 'src/utils/constants/insights'
import moment from 'moment'

const Wrapper = styled.div`
  white-space: nowrap
`

const DateRenderer = ({ createdAt }) => {
  const showDate = moment.unix(createdAt / 1000).format(DATE_FORMAT)
  return (
    <Wrapper>{showDate}</Wrapper>
  )
}
export default DateRenderer
