import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import { getRoute } from 'src/utils/routes'

const ActionsRenderer = ({ uuid }) => uuid ? (
  <Button
    block
    id="ActionsRenderer-edit"
    onClick={() => navigate(getRoute('admin.people.edit', { uuid }))}
    icon={<EditOutlined/>}> Edit </Button>
) : null

ActionsRenderer.propTypes = {
  uuid: PropTypes.string,
}

ActionsRenderer.defaultProps = {
  uuid: null,
}

export default ActionsRenderer
