import React from 'react'
import styled from 'styled-components'
import { Alert, Button } from 'antd'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { useMutation } from 'src/apollo/mutation'
import { bulkUpdateIndexMutation } from 'src/apollo/mutation/people/bulkUpdateIndex'

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`
const getIndex = items => {
  return items.map(({ uuid, index }) => ({ uuid, index }))
}
const OrderChanges = ({ original, changed }) => {
  const hasChanges = !isEqual(getIndex(original), getIndex(changed))

  const params = { changes: changed.map(({ uuid }, index) => ({ uuid, index })) }
  const [save, { loading }] = useMutation(bulkUpdateIndexMutation, {
    variables: { params },
    refetchQueries: ['PeopleList'],
    awaitRefetchQueries: true,
  })

  return hasChanges ? (
    <Alert message={
      <Wrapper>
        You have unsaved ordering changes
        <Button loading={loading} onClick={save}>Save ordering</Button>
      </Wrapper>
    }>
    </Alert>
  ) : null
}

OrderChanges.propTypes = {
  original: PropTypes.array,
  changed: PropTypes.array,
}

OrderChanges.defaultProps = {
  original: [],
  changed: [],
}

export default OrderChanges
