import React from 'react'
import {Card, Tag} from 'antd'
import styled from 'styled-components'

const StyledCard = styled(Card)`
    background: transparent;
    border: none;
`

const ListChanges = ({changes, title, accessor}) => {
    if (!changes) return null

    return (
        <StyledCard size="small" title={title}>
            {changes.added.map(item => <Tag color="green" key={item[accessor] || item}>{item[accessor] || item}</Tag>)}
            {changes.removed.map(item => <Tag color="red" key={item[accessor] || item}>{item[accessor] || item}</Tag>)}
        </StyledCard>
    )

}

export default ListChanges