import React, { useState, useEffect } from 'react'
import { differenceBy } from 'lodash'
import useGetGroups from 'src/apollo/query/groups/useGetGroups'
import GroupManager from '../../../GroupManager/GroupManager'
import useBulkToggleGroupsFund from '../../../../../apollo/mutation/funds/useBulkToggleGroupsFund'

const FundPermissions = ({ fundUuid, open, toggle, onLoadingChange }) => {
  const [toRemove, setToRemove] = useState([])
  const [toAdd, setToAdd] = useState([])
  const { data: allGroupsData, loading: allGroupsLoading } = useGetGroups({ fetchPolicy: 'network-only' })
  const { data: currentGroupsData, loading: currentGroupsLoading, refetch: refetchCurrentGroups } = useGetGroups({
    params: {
      filter: {
        funds: [fundUuid],
      },
    },
    fetchPolicy: 'network-only',
  })
  const allGroups = allGroupsData?.groups?.results || []
  const currentGroups = currentGroupsData?.groups?.results || []

  const changes = [
    ...toRemove.map(item => ({ ...item, toRemove: true })),
    ...toAdd.map(item => ({ ...item, toAdd: true })),
  ]
  const available = differenceBy(allGroups, [...currentGroups, ...changes], 'uuid')
  const current = differenceBy(currentGroups, changes, 'uuid')

  const resulting = [...current, ...toAdd]

  const [bulkToggleGroupsFund, { loading: mutationLoading }] = useBulkToggleGroupsFund({
    params: {
      uuid: fundUuid,
      add: toAdd.map(item => item.uuid),
      remove: toRemove.map(item => item.uuid),
    },
    onCompleted: () => {
      refetchCurrentGroups()
      setToAdd([])
      setToRemove([])
    },
  })

  const loading = mutationLoading || allGroupsLoading || currentGroupsLoading

  useEffect(() => {
    onLoadingChange && onLoadingChange(loading)
  }, [loading])

  const handleSelect = value => {
    setToAdd([...toAdd, ...value])
  }

  const handleRevert = value => {
    value.toRemove
      ? setToRemove(differenceBy(toRemove, [value], 'uuid'))
      : setToAdd(differenceBy(toAdd, [value], 'uuid'))
  }

  const handleRemove = value => {
    setToRemove([...toRemove, value])
  }

  return (
    <GroupManager
      open={open}
      toggle={toggle}
      available={available}
      changes={changes}
      current={current}
      onSelect={handleSelect}
      onRemove={handleRemove}
      onRevert={handleRevert}
      loading={loading}
      buttonLoading={!fundUuid}
      onSave={bulkToggleGroupsFund}
      resulting={changes.length ? resulting : []}
      onCancel={() => {
        setToAdd([])
        setToRemove([])
      }}
    />
  )
}

export default FundPermissions
