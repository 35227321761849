// extracted by mini-css-extract-plugin
export var active = "emso-MobileMenu-module__active";
export var arrow = "emso-MobileMenu-module__arrow";
export var container = "emso-MobileMenu-module__container";
export var dash = "emso-MobileMenu-module__dash";
export var expanded = "emso-MobileMenu-module__expanded";
export var expander = "emso-MobileMenu-module__expander";
export var li = "emso-MobileMenu-module__li";
export var menuContent = "emso-MobileMenu-module__menuContent";
export var name = "emso-MobileMenu-module__name";
export var section = "emso-MobileMenu-module__section";
export var title = "emso-MobileMenu-module__title";
export var topActive = "emso-MobileMenu-module__topActive";
export var upArrow = "emso-MobileMenu-module__upArrow";
export var visible = "emso-MobileMenu-module__visible";
export var wrapper = "emso-MobileMenu-module__wrapper";