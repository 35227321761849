import React from 'react'
import { getImageSource } from '../../../../../../../utils/helpers/image'

const useImages = codemirrorInstance => {
  const [showUpload, setShowUpload] = React.useState(false)

  const addImage = src => {
    const pos = codemirrorInstance.getCursor()
    codemirrorInstance.setSelection(pos, pos)
    codemirrorInstance.replaceSelection(`\n![image](${getImageSource(src)})\n`)
  }

  return {
    addImage,
    showUpload,
    setShowUpload,
  }
}

export default useImages
