import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const deleteVideoMutation = gql`
  mutation DeleteVideo($params: DeleteVideoInput!) {
    deleteVideo(params: $params)
  }
`

const useDeleteVideo = ({ params, ...options } = {}) => {
  return useMutation(deleteVideoMutation, {
    variables: { params },
    ...options,
  })
}

export default useDeleteVideo
