import React, {useMemo} from 'react'
import { xorBy } from 'lodash'

const useDownloadableChecklist = ({ files = [], directories = [], path }) => {
    const [itemsChecked, setItemsChecked] = React.useState([])

    const { filesChecked, directoriesChecked } = useMemo(()=> {
        const allFilesNames = files.map((file)=> file.name)
        const allDirectoryNames = directories.map((directory)=> directory.name)
        return itemsChecked.reduce((result, item)=> {
            if (allFilesNames.includes(item.name)) {
                result.filesChecked.push(item)
            }

            if (allDirectoryNames.includes(item.name)) {
                result.directoriesChecked.push(item)
            }

            return result;
        }, { filesChecked: [], directoriesChecked: []})
    }, [files, directories])

    React.useEffect(() => {
        setItemsChecked([])
    }, [path])

    const handleCheckBoxClicked = (name, visible, path) => {
        setItemsChecked(xorBy(itemsChecked, [{ name, visible, path }], 'name'))
    }

    const isItemChecked = name => {
        return itemsChecked.find(item => item.name === name)
    }

    const areAllItemsChecked = itemsChecked.length === [...files, ...directories].length

    const toggleAllItems = () => {
        if (!areAllItemsChecked) {
            setItemsChecked([...files, ...directories])
        } else {
            setItemsChecked([])
        }
    }

    const uncheckAllFiles = () => {
        setItemsChecked([])
    }

    return {
        itemsChecked,
        filesChecked,
        directoriesChecked,
        handleCheckBoxClicked,
        isItemChecked,
        areAllItemsChecked,
        toggleAllItems,
        uncheckAllFiles,
    }
}

export default useDownloadableChecklist
