import * as React from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import styled from 'styled-components'
import download from 'downloadjs'
import { Button, Modal } from 'antd'
import useGetFile from 'src/apollo/query/documents/useGetFile'
import auth from '../../../helpers/auth'
import Error from '../Error/Error'
import Loading from '../../Admin/ui/Loading'
import { addWatermarkToPdf } from '../../../utils/helpers/pdf'
import { page, pdf, error as errorStyle, pdfDocument } from './PreviewFile.module.scss'
import useGtag, {CUSTOM_DIMENSION, EVENT_ACTIONS, EVENT_CATEGORIES, EVENT_LABELS} from '../../../hooks/useGtag'
import useCreateLog from 'src/apollo/mutation/logs/useCreateLog'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ModalHeader = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 5vh;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const SpacedButton = styled(Button)`
  margin-right: 30px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`

function getDocWidth() {
  const body = document.body
  const html = document.documentElement
  return Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth)
}

function getPdfWidth() {
  const docWidth = getDocWidth() - 200
  if (docWidth > 990) {
    return 990
  }
  if (docWidth < 500) {
    return 350
  }
  return docWidth - 20
}

export default ({ path, fileName, open, closePopup, allowDownload }) => {
  const [fileBlob, setFileBlob] = React.useState()
  const [numOfPages, setNumOfPages] = React.useState()
  const [totalPages, setTotalPages] = React.useState()

  const { sendEvent } = useGtag()

  const isPdf = fileName.toLowerCase().endsWith('pdf')

  const { loading, error } = useGetFile({
    params: { name: fileName, path },
    skip: !fileName,
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (response?.file) {
        fetch(response.file)
          .then(res => res.blob())
          .then(setFileBlob)
      }
    },
  })

  const [createLogMutation] = useCreateLog({
    showErrorNotification: false,
  })

  const createLog = name => {
    return createLogMutation({
      variables: {
        params: {
          name,
          subjectId: `${path}/${fileName}`,
          subjectType: 'File',
          description: fileName,
          properties: {
            path,
            fileName,
          },
        },
      },
    })
  }

  async function downloadFile() {
    let blob = fileBlob
    const userProfile = await auth().getUserInfo()

    if (fileBlob.type === 'application/pdf') {
      const name = userProfile ? `${userProfile.given_name} ${userProfile.family_name}` : ''
      const pdfBuffer = await blob.arrayBuffer()
      blob = await addWatermarkToPdf(pdfBuffer, name)
    }

    download(blob, fileName, blob.type)
    closePopup()
    createLog('downloaded')
    sendEvent({
      category: EVENT_CATEGORIES.documents,
      action: EVENT_ACTIONS.downloadFile,
      label: `${fileName}`,
      customDimensions: {
        [CUSTOM_DIMENSION.Username]: userProfile.name,
        [CUSTOM_DIMENSION.Path]: path,
      },
    })
  }

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages)
    setNumOfPages(Math.min(numPages, 2))
  }

  React.useEffect(() => {
    if (!isPdf && fileBlob && fileName) {
      downloadFile()
    }
  }, [fileBlob])

  const pdfWidth = getPdfWidth()

  const handleScroll = React.useCallback(
    e => {
      let element = e.target
      const { scrollHeight, scrollTop } = element

      if (numOfPages < totalPages) {
        if (scrollHeight - scrollTop < 1200) {
          const pagesToAdd = Math.min(totalPages - numOfPages, 2)
          setNumOfPages(numOfPages + pagesToAdd)
        }
      }
    },
    [numOfPages, totalPages],
  )

  React.useEffect(() => {
    if (open) {
      createLog('viewed')
      sendEvent({
        category: EVENT_CATEGORIES.documents,
        action: EVENT_ACTIONS.viewFile,
        label: `${EVENT_LABELS.viewFile} ${fileName}`,
      })
    }
  }, [open])

  return open ? (
    <Modal
      width={pdfWidth + 100}
      bodyStyle={{ position: 'unset' }}
      visible={isPdf && open}
      onCancel={closePopup}
      footer={null}
      onScroll={handleScroll}
    >
      <ModalHeader>
        {allowDownload && (
          <SpacedButton onClick={isPdf && fileBlob ? downloadFile : undefined} type="portal">
            Download
          </SpacedButton>
        )}
      </ModalHeader>
      <div onScroll={handleScroll} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Loading loading={loading}>
          {error && <Error className={errorStyle} />}
          {isPdf && fileBlob && (
            <>
              <div className={pdf}>
                {fileBlob && (
                  <Document
                    className={pdfDocument}
                    file={fileBlob}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={e => e.preventDefault()}
                  >
                    {Array.from(new Array(numOfPages), (el, index) => (
                      <Page width={pdfWidth} height={800} className={page} key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                )}
              </div>
            </>
          )}
        </Loading>
      </div>
    </Modal>
  ) : null
}
