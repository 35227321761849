import React, { useEffect, useState } from 'react'
import { Checkbox, Modal, Tooltip } from 'antd'
import { file, folder, checkbox, header, headerRow, hr, info } from './Download.module.scss'
import fileIcon from '../Item/file.svg'
import folderIcon from '../Item/folder.svg'
import * as itemStyles from '../Item/Item.module.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'
import Row from '../../../Row/Row'
import Col from '../../../Col/Col'
import { DOWNLOAD_ERROR } from '../useMultiDownload'

const ErrorMsg = {
  [DOWNLOAD_ERROR.NO_FILES]: 'EMPTY FOLDER',
  [DOWNLOAD_ERROR.READ_ONLY]: 'READ ONLY',
}

const getFileName = document => {
  const { path, name, error } = document

  if (name === '' && error) {
    return `${path} - ${ErrorMsg[error]}`
  }
  return name
}

const FileRow = ({ onCheckboxClicked, document, checked }) => {
  const { path, name } = document

  const onChange = event => {
    onCheckboxClicked(event, document)
  }

  const hasError = name === ''
  const fileName = getFileName(document)

  return (
    <Row inlineRow className={itemStyles.row}>
      <Col large={10}>
        {!hasError && (
          <Tooltip title={path}>
            <InfoCircleOutlined className={info} style={{ color: '#8590ad' }} />
          </Tooltip>
        )}
        {hasError ? <img alt="folder-icon" className={folder} src={folderIcon} /> : <img alt="file-icon" className={file} src={fileIcon} />}
        <div>{fileName}</div>
      </Col>
      {!hasError && <Checkbox className={checkbox} onChange={onChange} checked={checked} />}
    </Row>
  )
}

const filterOutReadOnly = list => {
  return list.filter(item => item.name !== '')
}

const useCheckedList = (open, documents) => {
  const filteredDocuments = filterOutReadOnly(documents)
  const [checkedList, setCheckedList] = useState(filteredDocuments)
  const areAllItemsChecked = checkedList.length === filteredDocuments.length

  useEffect(() => {
    if (open) {
      setCheckedList(filterOutReadOnly(documents))
    }
  }, [open, documents])

  const isChecked = document => {
    return !!checkedList.find(file => isEqual(file, document))
  }

  const onCheck = (event, document) => {
    const { checked } = event.target
    if (checked) {
      setCheckedList([...checkedList, document])
    } else {
      setCheckedList(checkedList.filter(file => !isEqual(file, document)))
    }
  }

  const onToggleAllChecked = () => {
    if (!areAllItemsChecked) {
      setCheckedList([...filteredDocuments])
    } else {
      setCheckedList([])
    }
  }

  return {
    areAllItemsChecked,
    onToggleAllChecked,
    checkedList,
    isChecked,
    onCheck,
  }
}

const modelStyles = {
  maxHeight: '70vh',
  minHeight: '30vh',
  overflowY: 'auto',
  flexDirection: 'column',
  display: 'flex',
  padding: 0,
}

const DownloadError = ({ open, handleCancel, handleRetryDownload, downloading, documents = [] }) => {
  const { checkedList, areAllItemsChecked, isChecked, onCheck, onToggleAllChecked } = useCheckedList(open, documents)
  const allReadyOnly = !documents.some(item => item.name !== '')

  const onOK = () => {
    handleRetryDownload(checkedList)
  }

  return (
    <Modal
      maskClosable={false}
      selectable={false}
      title="Download Errors"
      visible={open}
      okText={'Retry'}
      onOk={onOK}
      okButtonProps={{ disabled: downloading || checkedList.length === 0 }}
      cancelText={'Close'}
      onCancel={handleCancel}
      bodyStyle={modelStyles}
      width={'unset'}
      style={{
        maxWidth: '50vw',
      }}
    >
      <Row inlineRow className={headerRow}>
        <Col large={10} className={header}>
          <span>File Name</span>
        </Col>
        <div>
          {!allReadyOnly && (
            <Checkbox
              style={{ marginTop: '-3px', top: 'unset' }}
              disabled={false}
              checked={areAllItemsChecked && checkedList.length !== 0}
              onChange={onToggleAllChecked}
            />
          )}
        </div>
      </Row>
      <hr className={hr} />
      {documents.map(document => {
        return (
          <FileRow
            key={`${document.path}/${document.name}`}
            checked={isChecked(document)}
            document={document}
            onCheckboxClicked={onCheck}
          />
        )
      })}
    </Modal>
  )
}

export default DownloadError
