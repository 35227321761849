export const trimPath = (path) => {
    if (path === '/') {
        return path
    }

    let newPath = path.slice()
    if (newPath.startsWith('/')) {
        newPath = newPath.slice(1)
    }
    if (newPath.endsWith('/')) {
        newPath = newPath.slice(0, -1)
    }
    return newPath
}
