import gql from 'graphql-tag'
import useMutation from '../useMutation'

export const addGroupsDocumentMutation = gql`
    mutation AddGroupsDocument($params: AddGroupsDocumentInput!) {
        addGroupsDocument(params: $params)
    }
`

const useAddGroupsDocument = ({ params, ...options } = {}) => {
  return useMutation(addGroupsDocumentMutation, {
    variables: { params }, ...options,
  })
}

export default useAddGroupsDocument
