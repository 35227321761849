import React, { useState } from 'react'
import { Modal } from 'antd'
import ReactCrop from 'react-image-crop'

function getCroppedImg(image, crop) {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

  // Note, not all file types are supported across all browsers, e.g. gif is only supported in safari
  return canvas.toDataURL('image/jpeg')
}

const createHtmlImageObject = (base64image, width, height) => {
  const image = new Image()
  image.src = base64image
  image.width = width
  image.height = height
  return image
}

const ImageCrop = ({ src, aspect, onChange, onCancel }) => {
  const [cropConfig, setCropConfig] = useState({ aspect })
  const [renderedFileSize, setRenderedFileSize] = useState(null)

  const onImageLoaded = image => {
    setRenderedFileSize({ width: image.width, height: image.height })
  }

  const handleCrop = async () => {
    const image = createHtmlImageObject(src, renderedFileSize.width, renderedFileSize.height)
    const file = cropConfig.width ? await getCroppedImg(image, cropConfig, 'test') : src
    onChange(file)
  }

  return (
    <>
      <Modal visible={!!src} onOk={handleCrop} okText="Crop" onCancel={onCancel} closable={false}>
        <ReactCrop src={src} crop={cropConfig} onChange={setCropConfig} onImageLoaded={onImageLoaded} />
      </Modal>
    </>
  )
}

export default ImageCrop
