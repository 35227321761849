import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'src/layouts/Layout'
import { heroData } from 'src/data/team'
import Hero from 'src/components/Hero/Hero'
import heroImage from 'src/pages/images/city-background.jpg'
import Team from './Team'
import * as style from '../../Team/team.module.scss'

const TeamPage = () => {
  return (
    <Layout className={style.teamPage}>
      <Helmet>
        <title>Team - Emso</title>
        <meta name="description" content="Emso is an independent asset manager. Ownership is shared between more than 25 employees."/>
      </Helmet>
      <Hero image={heroImage} {...heroData} />
      <Team />
    </Layout>
  )
}

export default TeamPage
