import { ActionsRenderer, DateRenderer, NameRenderer } from './renderers'

export const columns = [
  {
    title: 'Name',
    render: NameRenderer,
    key: 'name',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Created',
    render: DateRenderer,
    key: 'createdAt',
    sorter: true,
  },
  {
    title: 'Actions',
    render: ActionsRenderer,
    key: 'actions',
  },
]
