// extracted by mini-css-extract-plugin
export var active = "emso-Admin-module__active";
export var addButtonRow = "emso-Admin-module__addButtonRow";
export var admin = "emso-Admin-module__admin";
export var button = "emso-Admin-module__button";
export var buttonRow = "emso-Admin-module__buttonRow";
export var colorLightBlue = "#8590ad";
export var content = "emso-Admin-module__content";
export var deleteIcon = "emso-Admin-module__deleteIcon";
export var disabled = "emso-Admin-module__disabled";
export var formButton = "emso-Admin-module__formButton emso-Button-module__button";
export var header = "emso-Admin-module__header";
export var hero = "emso-Admin-module__hero";
export var icon = "emso-Admin-module__icon";
export var item = "emso-Admin-module__item";
export var p = "emso-Admin-module__p";
export var remove = "emso-Admin-module__remove";
export var search = "emso-Admin-module__search";