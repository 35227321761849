import React from 'react'
import { Tooltip } from 'antd'

const CauserRenderer = ({ causedBy }) => {
  const { givenName, familyName, email } = causedBy
  return (
    <Tooltip title={email}>
      {givenName} {familyName}
    </Tooltip>
  )
}

export default CauserRenderer
