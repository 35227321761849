import { degrees, PDFDocument, rgb, cmyk, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'

const FONT_URL = '/webfonts/Helvetica-Bold-Outline.ttf'

export const addWatermarkToPdf = async (arrayBuffer, name) => {
  const pdf = await PDFDocument.load(arrayBuffer)
  pdf.registerFontkit(fontkit)
  let fontBytes = StandardFonts.HelveticaBold

  try {
    const res = await fetch(FONT_URL)
      .then(res => res.arrayBuffer())
    fontBytes = res
  } catch (e) {
    console.log(e, 'custom font loading failed')
  }

  let font

  try {
    font = await pdf.embedFont(fontBytes)
  } catch (e) {
    font = await pdf.embedFont(StandardFonts.HelveticaBold)
  }

  const pages = pdf.getPages()

  pages.forEach(page => {
    const { width, height } = page.getSize()
    const text = `
      Confidential
      Emso
      ${name}
      Not for Third Party Distribution
    `
    page.drawText(text, {
      x: width / 2 - width * 0.25,
      y: height / 2 - height * 0.1,
      size: 22,
      font,
      color: cmyk(0,0,0, 0.8),
      rotate: degrees(45),
    })
  })

  return pdf.save()
}
